import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  NativeSelect,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import BootstrapInput from "../../../components/BootstrapInput";
import { useDispatch } from "react-redux";
import { editRewardSetting } from "../../../store/actions/settingAction";
import { Edit as EditIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.black,
  },
  textColor: {
    color: "white",
  },
  textFieldColor: {
    color: "#4285f4",
    backgroundColor: theme.palette.background.grey1,
  },
  mainBtn: {
    backgroundColor: theme.palette.background.mainBtn,
    color: "white",
  },
}));

const EditRewardSetting = ({ className, rewardSettingData, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const EditRewardSettingSchema = Yup.object().shape({
    title: Yup.string().max(255).required("กรุณากรอกหัวเรื่อง"),
    detail: Yup.string().max(255).required("กรุณากรอกรายละเอียด"),
    price: Yup.number()
      .min(0, "กรุณากรอกพอยท์ให้ถูกต้อง")
      .required("กรุณากรอกพอยท์"),
    amount: Yup.number()
      .min(0, "กรุณากรอกเครดิตให้ถูกต้อง")
      .required("กรุณากรอกเครดิต"),
  });

  const body = (
    <Formik
      validationSchema={EditRewardSettingSchema} //กำหนด validation
      initialValues={{
        //กำหนด initialValues
        img: rewardSettingData.img,
        title: rewardSettingData.title,
        categoryId: rewardSettingData.categoryId,
        detail: rewardSettingData.detail,
        price: rewardSettingData.price,
        amount: rewardSettingData.amount,
      }}
      onSubmit={(values) => {
        editRewardSetting(dispatch, values, rewardSettingData.id);
        handleClose();
      }}
    >
      {({ errors, touched, values, handleBlur, handleChange }) => (
        <Form>
          <Box mt={3} mb={3}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Typography className={classes.textColor} variant="h3">
                เพิ่มรางวัล
              </Typography>
            </Grid>
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              หัวเรื่อง
            </Typography>
            <TextField
              error={Boolean(touched.title && errors.title)}
              fullWidth
              size="small"
              helperText={touched.title && errors.title}
              margin="normal"
              name="title"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.title}
              variant="outlined"
              InputProps={{
                className: classes.textFieldColor,
              }}
              style={{ marginTop: 8 }}
            />
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              ประเภท
            </Typography>
            <NativeSelect
              fullWidth
              defaultValue={values.categoryId}
              id="categoryId"
              name="categoryId"
              onChange={handleChange}
              margin="dense"
              input={<BootstrapInput />}
              style={{ marginTop: 8 }}
            >
              <option value={1}>แลกเครดิต</option>
              <option value={2}>แลกของ</option>
            </NativeSelect>
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              รายละเอียด
            </Typography>
            <TextField
              multiline
              rows={4}
              error={Boolean(touched.detail && errors.detail)}
              fullWidth
              size="small"
              helperText={touched.detail && errors.detail}
              margin="normal"
              name="detail"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.detail}
              variant="outlined"
              InputProps={{
                className: classes.textFieldColor,
              }}
              style={{ marginTop: 8 }}
            />
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              พอยท์
            </Typography>
            <TextField
              error={Boolean(touched.price && errors.price)}
              fullWidth
              size="small"
              helperText={touched.price && errors.price}
              margin="normal"
              name="price"
              onBlur={handleBlur}
              onChange={handleChange}
              type="number"
              value={values.price}
              variant="outlined"
              InputProps={{
                className: classes.textFieldColor,
              }}
              style={{ marginTop: 8 }}
            />
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              เครดิต
            </Typography>
            <TextField
              error={Boolean(touched.amount && errors.amount)}
              fullWidth
              size="small"
              helperText={touched.amount && errors.amount}
              margin="normal"
              name="amount"
              onBlur={handleBlur}
              onChange={handleChange}
              type="number"
              value={values.amount}
              variant="outlined"
              InputProps={{
                className: classes.textFieldColor,
              }}
              style={{ marginTop: 8 }}
            />
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              ลิงค์รูป
            </Typography>
            <TextField
              error={Boolean(touched.img && errors.img)}
              fullWidth
              size="small"
              helperText={touched.img && errors.img}
              margin="normal"
              name="img"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.img}
              variant="outlined"
              InputProps={{
                className: classes.textFieldColor,
              }}
              style={{ marginTop: 8 }}
            />
          </Box>

          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ marginBottom: 16 }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              size="large"
              style={{ backgroundColor: "#B23CFD", color: "white" }}
            >
              ปิด
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              style={{ backgroundColor: "#4285f4", color: "white" }}
            >
              บันทึก
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );

  return (
    <div>
      <Button
        size="large"
        variant="contained"
        className={classes.mainBtn}
        onClick={handleClickOpen}
      >
        <EditIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent className={classes.paper}>{body}</DialogContent>
      </Dialog>
    </div>
  );
};

EditRewardSetting.propTypes = {
  className: PropTypes.string,
  rewardSettingData: PropTypes.object,
};

export default EditRewardSetting;
