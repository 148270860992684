import React from "react";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import "react-json-pretty/themes/monikai.css";
import JSONPretty from "react-json-pretty";
import columnName from "./columnName";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.black,
  },
  table: {
    "& .MuiTableSortLabel-root": {
      color: "white",
    },
    "& .MuiTableCell-root": {
      color: "white",
      borderWidth: 1,
      borderColor: theme.palette.background.tableLine,
      borderStyle: "solid",
      padding: 8,
    },
  },
  tableRowColor1: {
    backgroundColor: theme.palette.background.grey1,
  },
  tableRowColor2: {
    backgroundColor: theme.palette.background.grey2,
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const TableHeadCellComponent = ({ name }) => (
  <TableCell align="center">{name}</TableCell>
);

const TableBodyRowComponent = ({ logData, classNameStyle }) => (
  <TableRow className={classNameStyle}>
    <TableCell>
      {moment(logData?.createdAt).format("DD/MM/YYYY H:mm")}
    </TableCell>
    <TableCell>{logData?.action}</TableCell>
    <TableCell align="center">{logData?.note}</TableCell>
    <TableCell width="30%">
      <JSONPretty
        id="json-pretty"
        style={{ width: 250 }}
        data={logData?.before}
      ></JSONPretty>
    </TableCell>
    <TableCell width="30%">
      <JSONPretty
        id="json-pretty"
        style={{ width: "250" }}
        data={logData?.after}
      ></JSONPretty>
    </TableCell>
    <TableCell align="center">
      {logData?.admin &&
        ((
          <a
            style={{ color: "white" }}
            href={`/admin/logs/${logData?.admin?.uuid}`}
          >
            {logData?.admin?.name}
          </a>
        ) || (
          <a
            style={{ color: "white" }}
            href={`/admin/logs/${logData?.admin?.uuid}`}
          >
            {logData?.admin?.username}
          </a>
        ))}
    </TableCell>
  </TableRow>
);

const LogList = ({ className, logDataList, pageNum, perPage, ...rest }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box style={{ backgroundColor: "#000" }} minWidth={800}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRowColor1}>
                {columnName.map((column, index) =>
                  column.sort ? (
                    <TableHeadCellComponent key={index} name={column.label} />
                  ) : (
                    <TableHeadCellComponent key={index} name={column.label} />
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {logDataList
                .slice((pageNum - 1) * perPage, pageNum * perPage)
                .map((logData, index) => (
                  <TableBodyRowComponent
                    classNameStyle={
                      index % 2
                        ? `${classes.tableRowColor1}`
                        : `${classes.tableRowColor2}`
                    }
                    key={index}
                    logData={logData}
                  />
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

LogList.propTypes = {
  className: PropTypes.string,
  logDataList: PropTypes.array,
  pageNum: PropTypes.number,
  perPage: PropTypes.string,
};

export default LogList;
