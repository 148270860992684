import Axios from '../../utils/axiosConfig';

export const getBankList = (dispatch, type) => {
  Axios.get(`/secure/report/bank/${type}`).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getBankList', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const updateBankActive = (bankId) => {
  const body = { bankId }
  return Axios.patch(`/secure/report/bank/active`, body).then((res) => res.data)
};
