import Axios from "../../utils/axiosConfig";
import { showErrorSwal, showSuccessSwal } from '../../utils/SwalAlert';

export const getCreditReqList = (dispatch) => {
  Axios.get("secure/reward/request/1").then((res) => {
    if (res.data.success) {
      dispatch({ type: "clearError" });
      dispatch({ type: "getCreditReqList", res });
    } else {
      dispatch({ type: "onError", res });
    }
  });
};

export const getCreditRecList = (dispatch) => {
  Axios.get("secure/reward/record/1").then((res) => {
    if (res.data.success) {
      dispatch({ type: "clearError" });
      dispatch({ type: "getCreditRecList", res });
    } else {
      dispatch({ type: "onError", res });
    }
  });
};

export const getRewardReqList = (dispatch) => {
  Axios.get("secure/reward/request/2").then((res) => {
    if (res.data.success) {
      dispatch({ type: "clearError" });
      dispatch({ type: "getRewardReqList", res });
    } else {
      dispatch({ type: "onError", res });
    }
  });
};

export const getRewardRecList = (dispatch) => {
  Axios.get("secure/reward/record/2").then((res) => {
    if (res.data.success) {
      dispatch({ type: "clearError" });
      dispatch({ type: "getRewardRecList", res });
    } else {
      dispatch({ type: "onError", res });
    }
  });
};

export const onConfirmCredit = (dispatch, id) => {
  var body = { id: id };
  Axios.post("/secure/reward/credit-confirm", body).then((res) => {
    if (res.data.success) {
      getCreditReqList(dispatch);
      getCreditRecList(dispatch);
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};

export const onConfirmReward = (dispatch, id) => {
  var body = { id: id };
  Axios.post("/secure/reward/reward-confirm", body).then((res) => {
    if (res.data.success) {
      getRewardReqList(dispatch);
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};

export const onCancelReward = (dispatch, id) => {
  var body = { id: id };
  Axios.post("/secure/reward/cancel", body).then((res) => {
    if (res.data.success) {
      getRewardReqList(dispatch);
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};

export const onCancelCredit = (dispatch, id) => {
  var body = { id: id };
  Axios.post("/secure/reward/cancel", body).then((res) => {
    if (res.data.success) {
      getCreditReqList(dispatch);
      getCreditRecList(dispatch);
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};
