const requestColumnName = [
  {
    label: "เวลา",
    field: "date",
    sort: "asc",
    width: 200,
  },
  {
    label: "ยอดฝากล่าสุด",
    field: "lastDeposit",
    sort: "asc",
    width: 100,
  },
  {
    label: "ยอดถอน",
    field: "amount",
    sort: "asc",
    width: 150,
  },
  {
    label: "รหัส",
    field: "username",
    sort: "asc",
    width: 200,
  },
  {
    label: "ธนาคาร",
    field: "bankName",
    sort: "asc",
    width: 150,
  },
  {
    label: "เลขบัญชี",
    field: "bankId",
    sort: "asc",
    width: 100,
  },
  {
    label: "Actions",
    field: "confirm",
    sort: "asc",
    width: 100,
  },
];
export default requestColumnName;
