import React from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import depositColumnName from './depositColumnName';

const useStyles = makeStyles((theme) => ({
  root: {},
  table: {
    '& .MuiTableSortLabel-root': {
      color: 'white',
    },
    '& .MuiTableCell-root': {
      color: 'white',
      borderWidth: 1,
      borderColor: theme.palette.background.tableLine,
      borderStyle: 'solid',
      padding: 8,
    },
  },
  tableRowColor1: {
    backgroundColor: theme.palette.background.grey1,
  },
  tableRowColor2: {
    backgroundColor: theme.palette.background.grey2,
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

const isBetweenDateTime = (time1, time2, amount1, amount2) => {
  var format = 'DD/MM/YYYY H:mm';
  var timeConvert = moment(time1).format(format);
  var beforeTimeConvert = moment(time2).format(format);
  var afterTimeConvert = moment(time2).format(format);
  var time = moment(timeConvert, format).subtract(1, 'hours');
  var beforeTime = moment(beforeTimeConvert, format).subtract(2, 'minutes');
  var afterTime = moment(afterTimeConvert, format).add(5, 'minutes');
  var beforeTime2 = moment(beforeTimeConvert, format).subtract(15, 'minutes');
  var afterTime2 = moment(afterTimeConvert, format).add(15, 'minutes');
  var check1 =
    time.isBetween(beforeTime, afterTime) ||
    time.isSame(beforeTime) ||
    time.isSame(afterTime);
  var check2 =
    time.isBetween(beforeTime2, afterTime2) &&
    amount1 - amount2 >= 0 &&
    amount1 - amount2 <= amount2 * 0.05;

  return check1 || check2;
};

const TableHeadCellWithSortComponent = ({ name }) => (
  <TableCell align="center">{name}</TableCell>
);

const TableHeadCellComponent = ({ name }) => (
  <TableCell align="center">{name}</TableCell>
);

const TableBodyRowComponent = ({
  userDepositFromIMIData,
  userDepositData,
  index,
  classNameStyle,
}) => (
  <TableRow className={classNameStyle}>
    <TableCell align="center">{index}</TableCell>
    <TableCell align="center">
      {moment(userDepositFromIMIData?.CreationTime)
        .subtract(1, 'hours')
        .format('DD/MM/YYYY H:mm:ss')}
    </TableCell>
    <TableCell style={{ color: '#28a745' }} align="center">
      {userDepositFromIMIData?.Amount?.toLocaleString('en', {
        minimumFractionDigits: 2,
      })}
    </TableCell>
    <TableCell style={{ color: '#28a745' }} align="center">
      {userDepositData?.amount?.toLocaleString('en', {
        minimumFractionDigits: 2,
      })}
    </TableCell>
  </TableRow>
);

const UserDepositList = ({
  className,
  userDepositList,
  userDepositListFromIMI,
  pageNum,
  perPage,
  ...rest
}) => {
  const classes = useStyles();
  // console.log(userDepositList?.length);
  // userDepositList?.forEach((element) => {
  //   console.log(
  //     element.amount,
  //     moment(element.createdAt).format("DD/MM/YYYY H:mm")
  //   );
  // });
  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box style={{ backgroundColor: '#000' }}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRowColor1}>
                {depositColumnName.map((column, index) =>
                  column.sort ? (
                    <TableHeadCellWithSortComponent
                      key={index}
                      name={column.label}
                    />
                  ) : (
                    <TableHeadCellComponent key={index} name={column.label} />
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {userDepositListFromIMI
                ?.slice((pageNum - 1) * perPage, pageNum * perPage)
                .map((userDepositFromIMIData, index) => (
                  <TableBodyRowComponent
                    classNameStyle={
                      index % 2
                        ? `${classes.tableRowColor1}`
                        : `${classes.tableRowColor2}`
                    }
                    key={index}
                    index={(pageNum - 1) * perPage + index + 1}
                    userDepositFromIMIData={userDepositFromIMIData}
                    userDepositData={
                      userDepositList?.filter((depositData) =>
                        isBetweenDateTime(
                          userDepositFromIMIData.CreationTime,
                          depositData.createdAt,
                          userDepositFromIMIData.Amount,
                          depositData.amount
                        )
                      )[0]
                    }
                  />
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

UserDepositList.propTypes = {
  className: PropTypes.string,
  userDepositList: PropTypes.array,
  userDepositListFromIMI: PropTypes.array,
  pageNum: PropTypes.number,
  perPage: PropTypes.number,
};

export default UserDepositList;
