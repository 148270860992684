import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Grid,
  NativeSelect,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import Page from "../../../components/Page";
import LoadingCircle from "../../../utils/LoadingCircle";
import { Navigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getLogListWithAdmin } from "../../../store/actions/logAction";
import BootstrapInput from "../../../components/BootstrapInput";
import LogsWithAdminList from "./LogsWithAdminList";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.black,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  pagination: {
    "& .MuiPaginationItem-root": {
      color: "white",
    },
  },
  textColor: {
    color: "white",
  },
  tableRowColor: {
    backgroundColor: theme.palette.background.grey1,
  },
}));

function LogsWithAdmin() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const logLoading = useSelector((state) => state.log.loading);
  const logListWithAdmin = useSelector((state) => state.log.logListWithAdmin);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const authLoading = useSelector((state) => state.auth.loading);
  const errorMessage = useSelector((state) => state.other.errorMessage);
  const [pageNum, setPageNum] = useState(1);
  const [perPage, setPerPage] = useState("10");

  useEffect(() => {
    getLogListWithAdmin(dispatch, uuid);
  }, [dispatch, uuid]);

  const handleChangePerPage = (event) => {
    setPerPage(event.target.value);
  };

  const handleChangePage = (e, value) => {
    setPageNum(value);
  };

  if (!authLoading && !currentUser) return <Navigate to="/login" />;
  else
    return (
      <Page className={classes.root} title="LogsWithAdmin">
        <Container>
          {!errorMessage ? (
            !logLoading ? (
              <>
                <Typography
                  className={classes.textColor}
                  style={{ marginBottom: 16 }}
                  variant="h2"
                >
                  Logs :
                </Typography>
                {logListWithAdmin?.length >= 1 ? (
                  <Box p={2} className={classes.tableRowColor}>
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                      style={{ marginBottom: 16 }}
                    >
                      <NativeSelect
                        id="demo-customized-select-native"
                        value={perPage}
                        margin="dense"
                        onChange={handleChangePerPage}
                        input={<BootstrapInput />}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </NativeSelect>
                    </Grid>
                    <Box>
                      <LogsWithAdminList
                        logWithAdminDataList={logListWithAdmin}
                        pageNum={pageNum}
                        perPage={perPage}
                      />
                    </Box>
                    <Box mt={3} display="flex" justifyContent="center">
                      <Pagination
                        color="secondary"
                        className={classes.pagination}
                        count={Math.ceil(logListWithAdmin.length / perPage)}
                        size="large"
                        onChange={handleChangePage}
                        variant="outlined"
                        shape="rounded"
                      />
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Box p={4} className={classes.tableRowColor}>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                      >
                        <Typography className={classes.textColor} variant="h2">
                          ไม่พบข้อมูล Logs
                        </Typography>
                      </Grid>
                    </Box>
                  </>
                )}
              </>
            ) : (
              <LoadingCircle />
            )
          ) : (
            <>
              <Box p={4} className={classes.tableRowColor}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Typography className={classes.textColor} variant="h2">
                    {errorMessage}
                  </Typography>
                </Grid>
              </Box>
            </>
          )}
        </Container>
      </Page>
    );
}

export default LogsWithAdmin;
