import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export const MySwal = withReactContent(Swal);

export const showErrorSwal = (message) => {
  MySwal.fire({
    icon: 'error',
    title: message,
    confirmButtonText: 'ตกลง',
  });
};

export const showSuccessSwal = (message) => {
  MySwal.fire({
    icon: 'success',
    title: message,
    confirmButtonText: 'ตกลง',
  });
};
