import React from "react";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Card,
  Table,
  Button,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Grid,
} from "@material-ui/core";
import columnName from "./columnName";
// import EditBank from "./EditBank";
import { useSelector, useDispatch } from "react-redux";
import { Edit as EditIcon } from "@material-ui/icons";
import { MySwal, showErrorSwal, showSuccessSwal } from "../../../utils/SwalAlert";
import { getBankList, updateBankActive } from '../../../store/actions/bankAction';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.black,
  },
  table: {
    "& .MuiTableSortLabel-root": {
      color: "white",
    },
    "& .MuiTableCell-root": {
      color: "white",
      borderWidth: 1,
      borderColor: theme.palette.background.tableLine,
      borderStyle: "solid",
      padding: 8,
    },
  },
  tableRowColor1: {
    backgroundColor: theme.palette.background.grey1,
  },
  tableRowColor2: {
    backgroundColor: theme.palette.background.grey2,
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const TableHeadCellWithSortComponent = ({ name }) => (
  <TableCell align="center">{name}</TableCell>
);

const TableHeadCellComponent = ({ name }) => (
  <TableCell align="center">{name}</TableCell>
);


export const onUpdateBankActive = async (dispatch, bankId, status) => {
  let title = 'ปิดการแสดงบัญชีให้ลูกค้าเห็นใช่หรือไม่?'
  if (!status) title = 'เปิดการแสดงบัญชีให้ลูกค้าเห็นใช่หรือไม่?'

  MySwal.fire({
    icon: 'question',
    text: title,
    showCancelButton: true,
    showLoaderOnConfirm: true,
    cancelButtonColor: '#d33',
    confirmButtonText: 'ยืนยัน',
    cancelButtonText: 'ยกเลิก',
    preConfirm: () => {
      return updateBankActive(bankId)
    },
  }).then((result) => {
    if (result.isConfirmed) {
      getBankList(dispatch, 'all')
      if (result.value.success) {
        showSuccessSwal(result.value.message)
      } else {
        showErrorSwal(result.value.message)
      }
    }
  })
}

const TableBodyRowComponent = ({
  bankData,
  currentUser,
  index,
  classNameStyle,
  dispatch,
}) => (
  <TableRow className={classNameStyle}>
    <TableCell align="center">{index}</TableCell>
    <TableCell align="center">{bankData.bankTitle}</TableCell>
    <TableCell align="center">{bankData.bank}</TableCell>
    <TableCell align="center">{bankData.bankId}</TableCell>
    <TableCell align="center">
      {bankData.bankType === 'deposit' ? 'ฝาก' : 'ถอน'}
    </TableCell>
    <TableCell style={{
      color: bankData.active ? 'green' : 'red'
    }} align="center">
      {bankData.active ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}
    </TableCell>
    <TableCell align="center">
      {moment(bankData.createdAt).format("DD/MM/YYYY H:mm")}
    </TableCell>
    <TableCell align="center">
      {currentUser.wAdmin && (
        <Grid
          spacing={1}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={12}>
            {/* <EditBank bankData={bankData} /> */}
            <Button
              size="small"
              variant="contained"
              style={{ backgroundColor: bankData.bankType === 'withdraw' ? "grey" : "#4384f3", color: "white" }}
              onClick={() => onUpdateBankActive(dispatch, bankData.bankId, bankData.active)}
              disabled={bankData.bankType === 'withdraw'}
            >
              <EditIcon />
            </Button>
          </Grid>
        </Grid>
      )}
    </TableCell>
  </TableRow>
);

const BankList = ({
  className,
  bankDataList,
  search,
  pageNum,
  perPage,
  ...rest
}) => {
  const classes = useStyles();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const dispatch = useDispatch();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box style={{ backgroundColor: "#000" }} minWidth={800}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRowColor1}>
                {columnName.map((column, index) =>
                  column.sort ? (
                    <TableHeadCellWithSortComponent
                      key={index}
                      name={column.label}
                    />
                  ) : (
                    <TableHeadCellComponent key={index} name={column.label} />
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {bankDataList
                .slice((pageNum - 1) * perPage, pageNum * perPage)
                .map((bankData, index) => (
                  <TableBodyRowComponent
                    classNameStyle={
                      index % 2
                        ? `${classes.tableRowColor1}`
                        : `${classes.tableRowColor2}`
                    }
                    key={index}
                    index={(pageNum - 1) * perPage + index + 1}
                    bankData={bankData}
                    currentUser={currentUser}
                    dispatch={dispatch}
                  />
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

BankList.propTypes = {
  className: PropTypes.string,
  bankDataList: PropTypes.array,
  search: PropTypes.string,
  pageNum: PropTypes.number,
  perPage: PropTypes.string,
};

export default BankList;
