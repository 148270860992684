import React from "react";
import {
  Typography,
  Container,
  makeStyles,
  Box,
  Grid,
} from "@material-ui/core";
import Page from "../../../components/Page";
import { Navigate } from "react-router";
import { useSelector } from "react-redux";
// import LoadingCircle from "../../../utils/LoadingCircle";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.black,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  textColor: {
    color: "white",
  },
  tableRowColor1: {
    backgroundColor: theme.palette.background.grey1,
  },
}));

function WithdrawBank() {
  const classes = useStyles();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const authLoading = useSelector((state) => state.auth.loading);

  if (!authLoading && !currentUser) return <Navigate to="/login" />;
  else
    return (
      <Page className={classes.root} title="ถอน (หน้าธนาคาร)">
        <Container>
          <Typography
            className={classes.textColor}
            style={{ marginBottom: 16 }}
            variant="h2"
          >
            รายการถอนเงินหน้าธนาคาร
          </Typography>
          <Box
            borderRadius="borderRadius"
            boxShadow={3}
            p={4}
            className={classes.tableRowColor1}
          >
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Typography className={classes.textColor} variant="h2">
                ปิดปรับปรุงชั่วคราว
              </Typography>
            </Grid>
          </Box>
        </Container>
      </Page>
    );
}

export default WithdrawBank;
