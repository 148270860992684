import React from 'react';
import PropTypes from 'prop-types';
import EditGeneralSetting from './EditGeneralSetting';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.black,
  },
  table: {
    '& .MuiTableSortLabel-root': {
      color: 'white',
    },
    '& .MuiTableCell-root': {
      color: 'white',
      borderWidth: 1,
      borderColor: theme.palette.background.tableLine,
      borderStyle: 'solid',
      padding: 8,
    },
  },
  tableRowColor1: {
    backgroundColor: theme.palette.background.grey1,
  },
  tableRowColor2: {
    backgroundColor: theme.palette.background.grey2,
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

const GeneralSetting = ({ settingDetail }) => {
  const classes = useStyles();

  return (
    <Table className={classes.table}>
      <TableHead>
        <TableRow className={classes.tableRowColor1}>
          <TableCell align="left">
            <Typography
              style={{ padding: 5 }}
              className={classes.textColor}
              variant="h4"
            >
              ตั้งค่าทั่วไป
            </Typography>
          </TableCell>
          <TableCell align="right">
            <EditGeneralSetting settingDetailData={settingDetail} />
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow className={classes.tableRowColor2}>
          <TableCell width="30%">ประกาศหน้าเว็บไซต์</TableCell>
          <TableCell>
            <Typography variant="h6" style={{ whiteSpace: 'pre-line' }}>
              {settingDetail?.news}
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

GeneralSetting.propTypes = {
  className: PropTypes.string,
  settingDetail: PropTypes.object,
};

export default GeneralSetting;
