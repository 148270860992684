import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { editWheelSetting } from "../../../store/actions/settingAction";
import { Edit as EditIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.black,
  },
  textColor: {
    color: "white",
  },
  textFieldColor: {
    color: "#4285f4",
    backgroundColor: theme.palette.background.grey1,
  },
  mainBtn: {
    backgroundColor: theme.palette.background.mainBtn,
    color: "white",
  },
}));

const EditWheelSetting = ({ className, wheelSettingData, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const EditWheelSettingSchema = Yup.object().shape({
    value: Yup.string()
      .min(0, "กรุณากรอกเครดิตให้ถูกต้อง")
      .required("กรุณากรอกเครดิต"),
    rate: Yup.number()
      .min(-1, "กรุณากรอกพอยท์ให้ถูกต้อง")
      .required("กรุณากรอกพอยท์"),
  });

  const body = (
    <Formik
      validationSchema={EditWheelSettingSchema} //กำหนด validation
      initialValues={{
        //กำหนด initialValues
        value: wheelSettingData.value,
        rate: wheelSettingData.rate,
      }}
      onSubmit={(values) => {
        editWheelSetting(dispatch, values, wheelSettingData.id);
        handleClose();
      }}
    >
      {({ errors, touched, values, handleBlur, handleChange }) => (
        <Form>
          <Box mt={3} mb={3}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Typography className={classes.textColor} variant="h3">
                แก้วงล้อหลายเลข {wheelSettingData.id}
              </Typography>
            </Grid>
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              Point
            </Typography>
            <TextField
              error={Boolean(touched.value && errors.value)}
              fullWidth
              size="small"
              helperText={touched.value && errors.value}
              margin="normal"
              name="value"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.value}
              variant="outlined"
              InputProps={{
                className: classes.textFieldColor,
              }}
              style={{ marginTop: 8 }}
            />
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              เปอร์เซ็นออก (0-100) ไม่มีโอกาศออก คือ -1
            </Typography>
            <TextField
              error={Boolean(touched.rate && errors.rate)}
              fullWidth
              size="small"
              helperText={touched.rate && errors.rate}
              margin="normal"
              name="rate"
              onBlur={handleBlur}
              onChange={handleChange}
              type="number"
              value={values.rate}
              variant="outlined"
              InputProps={{
                className: classes.textFieldColor,
              }}
              style={{ marginTop: 8 }}
            />
          </Box>

          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ marginBottom: 16 }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              size="large"
              style={{ backgroundColor: "#B23CFD", color: "white" }}
            >
              ปิด
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              style={{ backgroundColor: "#4285f4", color: "white" }}
            >
              บันทึก
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );

  return (
    <div>
      <Button
        size="large"
        variant="contained"
        className={classes.mainBtn}
        onClick={handleClickOpen}
      >
        <EditIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent className={classes.paper}>{body}</DialogContent>
      </Dialog>
    </div>
  );
};

EditWheelSetting.propTypes = {
  className: PropTypes.string,
  wheelSettingData: PropTypes.object,
};

export default EditWheelSetting;
