import Axios from "../../utils/axiosConfig";
import { showErrorSwal, showSuccessSwal } from '../../utils/SwalAlert';

export const getAdminList = (dispatch) => {
  Axios.get("/secure/admin").then((res) => {
    if (res.data.success) {
      dispatch({ type: "clearError" });
      dispatch({ type: "getAdminList", res });
    } else {
      dispatch({ type: "onError", res });
    }
  });
};

export const addAdmin = (dispatch, values) => {
  var body = { ...values, password: values.password };
  Axios.post("/secure/admin", body).then((res) => {
    if (res.data.success) {
      getAdminList(dispatch);
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};

export const editAdmin = (dispatch, values, adminData) => {
  var body = { ...values, adminid: adminData.uuid };
  Axios.patch("/secure/admin", body).then((res) => {
    if (res.data.success) {
      getAdminList(dispatch);
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};

export const changeAdminPassword = (dispatch, values, adminid) => {
  var body = { ...values, adminid: adminid };
  Axios.patch("/secure/admin/chwd", body).then((res) => {
    if (res.data.success) {
      getAdminList(dispatch);
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};

export const get2FAGenerator = (dispatch, adminid) => {
  var body = { adminid: adminid };
  return Axios.patch("/secure/admin/set2fa", body).then((res) => {
    if (res.data.success) {
      getAdminList(dispatch);
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
    return res.data;
  });
};

export const disable2FA = (dispatch, adminid) => {
  var body = { adminid: adminid };
  return Axios.delete(`/secure/admin/2fa/${adminid}`, body).then((res) => {
    if (res.data.success) {
      getAdminList(dispatch);
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
    return res.data;
  });
};
