const navBarRoutes = [
  {
    href: "/admin/report",
    title: "รายงาน",
    permission: "rReport",
  },
  {
    href: "/admin/user",
    title: "สมาชิก",
    permission: "rUser",
  },
  {
    href: "/admin/deposit",
    title: "ฝาก",
    permission: "rDeposit",
  },
  {
    href: "/admin/withdraw",
    title: "ถอน",
    permission: "rWithdraw",
  },
  {
    href: "/admin/reward",
    title: "แลกของ",
    permission: "rxReward",
  },
  {
    href: "/admin/credit",
    title: "แลกเครดิต",
    permission: "rxReward",
  },
  // {
  //   href: "/admin/deposit_bank",
  //   title: "ฝาก (หน้าธนาคาร)",
  // },
  // {
  //   href: "/admin/withdraw_bank",
  //   title: "ถอน (หน้าธนาคาร)",
  // },
  {
    href: "/admin/admin_list",
    title: "แอดมิน",
    permission: "rAdmin",
  },
  {
    href: "/admin/bank",
    title: "บัญชีธนาคาร",
    permission: "rAdmin",
  },
  {
    href: "/admin/logs",
    title: "Logs",
    permission: "",
  },
];

export default navBarRoutes;
