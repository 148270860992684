const requestColumnName = [
  {
    label: "เวลา",
    field: "date",
    sort: "asc",
    width: 200,
  },
  {
    label: "จำนวน",
    field: "amount",
    sort: "asc",
    width: 100,
  },
  {
    label: "รหัส",
    field: "username",
    sort: "asc",
    width: 150,
  },
  {
    label: "โอนเข้าบัญชี",
    field: "userBank",
    sort: "asc",
    width: 150,
  },
  {
    label: "โอนจากบัญชี",
    field: "bankId",
    sort: "asc",
    width: 150,
  },
  {
    label: "Actions",
    field: "confirm",
    sort: "asc",
    width: 100,
  },
  {
    label: "Admin",
    field: "admin",
    sort: "asc",
    width: 100,
  },
];
export default requestColumnName;
