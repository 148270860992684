import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  NativeSelect,
  Divider,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import BootstrapInput from "../../../components/BootstrapInput";
import { useDispatch } from "react-redux";
import { editAdmin } from "../../../store/actions/adminAction";
import { Edit as EditIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.black,
  },
  textColor: {
    color: "white",
  },
  textFieldColor: {
    color: "#4285f4",
    backgroundColor: theme.palette.background.grey1,
  },
}));

const EditAdmin = ({ className, adminData, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const EditAdminSchema = Yup.object().shape({
    name: Yup.string().max(255).required("กรุณากรอกชื่อ"),
  });

  const body = (
    <Formik
      validationSchema={EditAdminSchema} //กำหนด validation
      initialValues={{
        //กำหนด initialValues
        team: adminData.team === null ? "" : adminData.team,
        name: adminData.name,
        phone: adminData.phone === null ? "" : adminData.phone,
        active: adminData.active,
        lv: /^[4,2]/.test(adminData.lv) ? 10 : adminData.lv,
        rUser: adminData.rUser,
        wUser: adminData.wUser,
        rAdmin: adminData.rAdmin,
        wUserBankId: adminData.wUserBankId,
        rDeposit: adminData.rDeposit,
        isDeposit: adminData.isDeposit,
        rWithdraw: adminData.rWithdraw,
        isWithdraw: adminData.isWithdraw,
        rReport: adminData.rReport,
        rxReward: adminData.rxReward,
        wAdmin: adminData.wAdmin,
      }}
      onSubmit={(values) => {
        // console.log(values);
        editAdmin(dispatch, values, adminData);
        handleClose();
      }}
    >
      {({ errors, touched, values, handleBlur, handleChange }) => (
        <Form>
          <Box mb={3}>
            <Typography className={classes.textColor} variant="h2">
              แก้ไขแอดมิน
            </Typography>
          </Box>
          <Divider style={{ backgroundColor: "white" }} />
          <Box mt={3} mb={3}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Typography className={classes.textColor} variant="h3">
                Username : {adminData?.username}
              </Typography>
            </Grid>
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              ชื่อ : {adminData?.name}
            </Typography>
            <TextField
              error={Boolean(touched.name && errors.name)}
              fullWidth
              size="small"
              helperText={touched.name && errors.name}
              margin="normal"
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.name}
              variant="outlined"
              InputProps={{
                className: classes.textFieldColor,
              }}
              style={{ marginTop: 8 }}
            />
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              เบอร์โทร : {adminData?.phone}
            </Typography>
            <TextField
              error={Boolean(touched.phone && errors.phone)}
              fullWidth
              size="small"
              helperText={touched.phone && errors.phone}
              margin="normal"
              name="phone"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.phone}
              variant="outlined"
              InputProps={{
                className: classes.textFieldColor,
              }}
              style={{ marginTop: 8 }}
            />
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              ทีม : {adminData?.team}
            </Typography>
            <TextField
              error={Boolean(touched.team && errors.team)}
              fullWidth
              size="small"
              helperText={touched.team && errors.team}
              margin="normal"
              name="team"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.team}
              variant="outlined"
              InputProps={{
                className: classes.textFieldColor,
              }}
              style={{ marginTop: 8 }}
            />
          </Box>

          {adminData.lv !== 0 && adminData.lv !== 1 && (
            <Box mb={2}>
              <Typography className={classes.textColor} variant="h5">
                ตำแหน่ง
              </Typography>
              <NativeSelect
                fullWidth
                defaultValue={values.lv}
                id="lv"
                name="lv"
                onChange={handleChange}
                margin="dense"
                input={<BootstrapInput />}
                style={{ marginTop: 8 }}
              >
                <option value={10}>แอดมินทั่วไป</option>
                <option value={3}>การตลาด</option>
                {/* <option value={1}>แอดมินระดับสูง</option>
              <option value={0}>แอดมินสูงสุด</option> */}
              </NativeSelect>
            </Box>
          )}

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              สถานะ
            </Typography>
            <NativeSelect
              fullWidth
              defaultValue={values.active}
              id="active"
              name="active"
              onChange={handleChange}
              margin="dense"
              input={<BootstrapInput />}
              style={{ marginTop: 8 }}
            >
              <option value={false}>ปิดการใช้งาน</option>
              <option value={true}>เปิดการใช้งาน</option>
            </NativeSelect>
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h4">
              กำหนดสิทธิ
            </Typography>
            <Grid
              spacing={2}
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              style={{ marginBottom: 16 }}
            >
              <Grid item md={6} xs={12}>
                <FormControl component="fieldset" className={classes.textColor}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          checked={values.rUser}
                          name="rUser"
                        />
                      }
                      label="ดูยูส"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          checked={values.wUser}
                          name="wUser"
                        />
                      }
                      label="แก้ไขยูส"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          checked={values.wUserBankId}
                          name="wUserBankId"
                        />
                      }
                      label="แก้ไขเลขบัญชี"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl component="fieldset" className={classes.textColor}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          checked={values.rAdmin}
                          name="rAdmin"
                        />
                      }
                      label="ดูยูสแอดมิน"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          checked={values.wAdmin}
                          name="wAdmin"
                        />
                      }
                      label="จัดการยูสแอดมิน"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl component="fieldset" className={classes.textColor}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          checked={values.rDeposit}
                          name="rDeposit"
                        />
                      }
                      label="ดูรายการฝาก"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          checked={values.isDeposit}
                          name="isDeposit"
                        />
                      }
                      label="เติมมือ"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl component="fieldset" className={classes.textColor}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          checked={values.rWithdraw}
                          name="rWithdraw"
                        />
                      }
                      label="ดูรายการถอน"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          checked={values.isWithdraw}
                          name="isWithdraw"
                        />
                      }
                      label="ทำรายการถอน"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl component="fieldset" className={classes.textColor}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          checked={values.rxReward}
                          name="rxReward"
                        />
                      }
                      label="แลกของ"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl component="fieldset" className={classes.textColor}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          checked={values.rReport}
                          name="rReport"
                        />
                      }
                      label="ดูรีพอร์ท"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ marginBottom: 16 }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              size="large"
              style={{ backgroundColor: "#B23CFD", color: "white" }}
            >
              ปิด
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              style={{ backgroundColor: "#4285f4", color: "white" }}
            >
              บันทึก
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );

  return (
    <div>
      <Button
        size="small"
        variant="contained"
        style={{ backgroundColor: "#4384f3", color: "white" }}
        onClick={handleClickOpen}
      >
        <EditIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent className={classes.paper}>{body}</DialogContent>
      </Dialog>
    </div>
  );
};

EditAdmin.propTypes = {
  className: PropTypes.string,
  adminData: PropTypes.object,
};

export default EditAdmin;
