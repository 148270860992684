const wheelColumnName = [
  {
    label: "#",
    field: "id",
    sort: "asc",
    width: 200,
  },
  {
    label: "วันเวลา",
    field: "createAt",
    sort: "asc",
    width: 100,
  },
  {
    label: "รายละเอียด",
    field: "detail",
    sort: "asc",
    width: 150,
  },
];
export default wheelColumnName;
