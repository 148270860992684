const initState = {
  rewardReqList: null,
  rewardRecList: null,
  creditReqList: null,
  creditRecList: null,
  loading: true,
};

const rewardReducer = (state = initState, action) => {
  switch (action.type) {
    case "getRewardReqList":
      var rewardReq = action.res.data.data;
      // var rewardData = [];

      // rewards?.forEach((reward) => {
      //   rewardData.push(reward);
      // });

      return {
        ...state,
        rewardReqList: rewardReq,
        loading: false,
      };
    case "getRewardRecList":
      var rewardRec = action.res.data.data;
      // var rewardData = [];

      // rewards?.forEach((reward) => {
      //   rewardData.push(reward);
      // });

      return {
        ...state,
        rewardRecList: rewardRec,
        loading: false,
      };
    case "getCreditReqList":
      var creditReq = action.res.data.data;
      // var creditData = [];

      // credits?.forEach((credit) => {
      //   creditData.push(credit);
      // });

      return {
        ...state,
        creditReqList: creditReq,
        loading: false,
      };
      case "getCreditRecList":
        var creditRec = action.res.data.data;
        // var creditData = [];
  
        // credits?.forEach((credit) => {
        //   creditData.push(credit);
        // });
  
        return {
          ...state,
          creditRecList: creditRec,
          loading: false,
        };
    default:
      return state;
  }
};
export default rewardReducer;
