import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles,
  Paper,
} from "@material-ui/core";
import Page from "../../components/Page";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "../../store/actions/authActions";
import { Navigate } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.black,
    height: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    backgroundColor: theme.palette.background.grey1,
    color: "white",
  },
  textFieldColor: {
    color: "white",
  },
}));

const LoginView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const authLoading = useSelector((state) => state.auth.loading);
  const authError = useSelector((state) => state.auth.error);

  if (!authLoading && currentUser) return <Navigate to="/admin/dashboard" />;
  else
    return (
      <Page className={classes.root} title="Login">
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="center"
        >
          <Container maxWidth="sm">
            <Formik
              initialValues={{
                username: "",
                password: "",
                token: "",
              }}
              validationSchema={Yup.object().shape({
                username: Yup.string()
                  .max(255)
                  .required("Username is required"),
                password: Yup.string()
                  .max(255)
                  .required("Password is required"),
                token: Yup.string().min(6).max(6),
              })}
              onSubmit={(values, { setSubmitting }) => {
                signIn(
                  dispatch,
                  values.username,
                  values.password,
                  values.token
                );
                setSubmitting(false);
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <Paper className={classes.paper} elevation={3}>
                  <form onSubmit={handleSubmit}>
                    <Box mb={3}>
                      <Typography variant="h2">Sign in</Typography>
                    </Box>
                    <TextField
                      error={Boolean(touched.username && errors.username)}
                      fullWidth
                      helperText={touched.username && errors.username}
                      label="Username"
                      margin="normal"
                      name="username"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.username}
                      variant="outlined"
                      InputProps={{
                        className: classes.textFieldColor,
                      }}
                    />
                    <TextField
                      error={Boolean(touched.password && errors.password)}
                      fullWidth
                      helperText={touched.password && errors.password}
                      label="Password"
                      margin="normal"
                      name="password"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="password"
                      value={values.password}
                      variant="outlined"
                      InputProps={{
                        className: classes.textFieldColor,
                      }}
                    />
                    <TextField
                      error={Boolean(touched.token && errors.token)}
                      fullWidth
                      helperText={touched.token && errors.token}
                      label="Pin"
                      margin="normal"
                      name="token"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="text"
                      value={values.token}
                      variant="outlined"
                      InputProps={{
                        className: classes.textFieldColor,
                      }}
                    />
                    <Box my={2}>
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        SUBMIT
                      </Button>
                    </Box>

                    <Typography style={{ color: "red" }} variant="body1">
                      {authError}
                    </Typography>
                  </form>
                </Paper>
              )}
            </Formik>
          </Container>
        </Box>
      </Page>
    );
};

export default LoginView;
