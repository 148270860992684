import React from "react";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Grid,
  IconButton,
} from "@material-ui/core";
import { Person as UserIcon } from "@material-ui/icons";
import historyColumnName from "./historyColumnName";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.black,
  },
  table: {
    "& .MuiTableSortLabel-root": {
      color: "white",
    },
    "& .MuiTableCell-root": {
      color: "white",
      borderWidth: 1,
      borderColor: theme.palette.background.tableLine,
      borderStyle: "solid",
      padding: 8,
    },
  },
  tableRowColor1: {
    backgroundColor: theme.palette.background.grey1,
  },
  tableRowColor2: {
    backgroundColor: theme.palette.background.grey2,
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const TextStatus = (bankName, status) => {
  switch (status) {
    case 0:
      return <span style={{ color: "#F93154" }}>ล้มเหลว</span>;

    case 1:
      return <span style={{ color: "#00B74A" }}>สำเร็จ {bankName}</span>;

    case 2:
      return <span style={{ color: "#FFA900" }}>ยกเลิก</span>;

    case 3:
      return <span style={{ color: "#00B74A" }}>สำเร็จ (ถอนมือ)</span>;

    case 4:
      return <span style={{ color: "#00B74A" }}>สำเร็จ {bankName}</span>;

    case 5:
      return <span style={{ color: "#00B74A" }}>สำเร็จ {bankName}</span>;

    default:
      break;
  }
};

const TableHeadCellComponent = ({ name }) => (
  <TableCell align="center">{name}</TableCell>
);

const TableBodyRowComponent = ({ withdrawData, classNameStyle }) => (
  <TableRow className={classNameStyle}>
    <TableCell align="center">
      {moment(withdrawData?.createdAt).format("DD/MM/YYYY H:mm")}
    </TableCell>
    <TableCell align="center">{withdrawData?.amount?.toLocaleString("en")}</TableCell>
    <TableCell align="center">
      <Grid container direction="row" justify="center" alignItems="center">
        {withdrawData?.user?.user_pass?.username}
        <IconButton
          onClick={() =>
            window.open(
              `/admin/userdetail/${withdrawData?.user?.user_pass?.username}`
            )
          }
          size="small"
          style={{ marginLeft: 10, color: "#33b5e5" }}
        >
          <UserIcon />
        </IconButton>
      </Grid>
    </TableCell>
    <TableCell align="center">{`${withdrawData?.user?.user_bank?.bankName} ${withdrawData?.user?.user_bank?.bankId}`}</TableCell>
    <TableCell align="center">{withdrawData?.bank ? `${withdrawData?.bank?.bankName} ${withdrawData?.bank?.bankId}` : '-'}</TableCell>
    <TableCell align="center">
      <Grid container direction="row" justify="center" alignItems="center">
        {TextStatus(withdrawData?.bank?.bankName, withdrawData?.status)}
      </Grid>
    </TableCell>
    <TableCell align="center">{withdrawData?.admin?.name}</TableCell>
  </TableRow>
);

const WithdrawHistoryList = ({
  className,
  withdrawDataList,
  search,
  pageNum,
  perPage,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box style={{ backgroundColor: "#000" }} minWidth={800}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRowColor1}>
                {historyColumnName.map((column, index) =>
                  column.sort ? (
                    <TableHeadCellComponent
                      key={index}
                      name={column.label}
                      sort={column.sort}
                    />
                  ) : (
                    <TableHeadCellComponent key={index} name={column.label} />
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {withdrawDataList
                .slice((pageNum - 1) * perPage, pageNum * perPage)
                .map((withdrawData, index) => (
                  <TableBodyRowComponent
                    classNameStyle={
                      index % 2
                        ? `${classes.tableRowColor1}`
                        : `${classes.tableRowColor2}`
                    }
                    key={index}
                    withdrawData={withdrawData}
                  />
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

WithdrawHistoryList.propTypes = {
  className: PropTypes.string,
  withdrawDataList: PropTypes.array,
  search: PropTypes.string,
  pageNum: PropTypes.number,
  perPage: PropTypes.string,
};

export default WithdrawHistoryList;
