import Axios from '../../utils/axiosConfig';
import { showErrorSwal, showSuccessSwal } from '../../utils/SwalAlert';

export const getUserList = (dispatch) => {
  Axios.get('/secure/user/').then((res) => {
    // console.log(res);
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getUserList', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getUserDetail = (dispatch, username) => {
  Axios.get(`/secure/user/username/${username}`).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getUserDetail', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getUserRewardList = (dispatch, uuid) => {
  Axios.get(`/secure/user/reward/${uuid}`).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'getUserRewardList', res });
    }
  });
};

export const getSumUserDeposit = (dispatch, uuid) => {
  Axios.post(`/secure/user/deposit/${uuid}`).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'getSumUserDeposit', res });
    }
  });
};

export const getSumUserWithdraw = (dispatch, uuid) => {
  Axios.post(`/secure/user/withdraw/${uuid}`).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'getSumUserWithdraw', res });
    }
  });
};

export const getUserListByOther = async (dispatch, other) => {
  var allQuery = [];

  let resByUserDetail = await Axios.get(`/secure/user/search/${other}`);
  // let resByName = await Axios.get(`/secure/user/name/${other}`);
  // let resByPhone = await Axios.get(`/secure/user/phone/${other}`);
  let resByByBank = await Axios.get(`/secure/user/bankId/${other}`);
  let resByUsername = await Axios.get(`/secure/user/username/${other}`);
  let resByAuthUser = await Axios.get(`/secure/user/authuser/${other}`);
  
  allQuery = [
    ...resByUserDetail?.data?.data,
    // ...resByName?.data?.data,
    // ...resByPhone?.data?.data,
    ...resByUsername?.data?.data,
    ...resByByBank?.data?.data,
    ...resByAuthUser?.data?.data,
  ];
  dispatch({ type: 'getUserListByOther', allQuery });
};

// export const getUserListByUsername = (dispatch, username) => {
//   Axios.get(`/secure/user/username/${username}`).then((res) => {
//     if (res.data.success) {
//       dispatch({ type: "getUserList", res });
//     }
//   });
// };

// export const getUserListByName = (dispatch, name) => {
//   Axios.get(`/secure/user/name/${name}`).then((res) => {
//     if (res.data.success) {
//       dispatch({ type: "getUserList", res });
//     }
//   });
// };

// export const getUserListByBank = (dispatch, bankId) => {
//   Axios.get(`/secure/user/bankId/${bankId}`).then((res) => {
//     if (res.data.success) {
//       dispatch({ type: "getUserList", res });
//     }
//   });
// };

export const getUserDiagram = (dispatch, username) => {
  Axios.get(`/secure/user/diagram/${username}`).then((res) => {
    // console.log(res);
    // if (res.data.success) {
    //   dispatch({ type: "getUserDepositList", res });
    // }
  });
};

export const getUserTicket = (dispatch, username) => {
  Axios.get(`/secure/user/tickets/${username}`).then((res) => {
    // if (res.data.success) {
    //   dispatch({ type: "getUserDepositList", res });
    // }
  });
};

export const getUserWinLose = (dispatch, username) => {
  Axios.get(`/secure/user/winlose/${username}`).then((res) => {
    // console.log(res);
    // if (res.data.success) {
    //   dispatch({ type: "getUserDepositList", res });
    // }
  });
};

export const getUserDepositListFromIMI = (dispatch, username) => {
  Axios.get(`/secure/deposit/imi/${username}`).then((res) => {
    // console.log(res);
    if (res.data.success) {
      dispatch({ type: 'getUserDepositListFromIMI', res });
    }
  });
};

export const getUserDepositList = (dispatch, uuid) => {
  Axios.get(`/secure/deposit/history/${uuid}`).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'getUserDepositList', res });
    }
  });
};

export const getUserWithdrawList = (dispatch, username) => {
  Axios.get(`/secure/withdraw/imi/${username}`).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'getUserWithdrawList', res });
    }
  });
};

export const getUserWheelList = (dispatch, username) => {
  Axios.get(`/secure/wheel/${username}`).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'getUserWheelList', res });
    }
  });
};

export const updateUserDetail = (dispatch, values, userid) => {
  var body = { ...values, userid: userid };
  Axios.patch(`/secure/user`, body).then((res) => {
    if (res.data.success) {
      getUserList(dispatch);
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};

export const changeUserPassword = (dispatch, values, userid) => {
  var body = { ...values, userid: userid };
  Axios.patch('/secure/user/chwd', body).then((res) => {
    if (res.data.success) {
      getUserList(dispatch);
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};

export const editUserBank = (dispatch, values, userid, username) => {
  var body = { ...values, userid: userid, username: username };
  Axios.patch('/secure/user/bank', body).then((res) => {
    if (res.data.success) {
      getUserList(dispatch);
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};

export const deleteUser = (dispatch, uuid) => {
  var body = { userid: uuid };
  Axios.delete(`/secure/user/${uuid}`, body).then((res) => {
    if (res.data.success) {
      getUserList(dispatch);
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};
