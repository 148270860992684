const initState = {
  withdrawHistoryList: null,
  withdrawRequestList: null,
  withdrawHistoryListLoading: true,
  withdrawRequestListLoading: true,
  scbWithdrawBalance1: 0,
  scbWithdrawBalance2: 0,
  sumWithdrawBank1: 0,
  sumWithdrawBank2: 0,
  withdrawBankList: [],
  withdrawBankSum: [],
  loading: true,
};

const withdrawReducer = (state = initState, action) => {
  switch (action.type) {
    case "getWithdrawHistoryList":
      var withdrawHistory = action.res.data.data;
      var withdrawHistoryData = [];

      withdrawHistory?.forEach((withdraw) => {
        withdrawHistoryData.push(withdraw);
      });

      return {
        ...state,
        withdrawHistoryList: withdrawHistoryData,
        withdrawHistoryListLoading: false,
      };
    case "getWithdrawRequestList":
      var withdrawRequest = action.res.data.data;
      var withdrawRequestData = [];

      withdrawRequest?.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      withdrawRequest
        // ?.filter((withdraw) => withdraw.status !== 10)
        .forEach((withdraw) => {
          withdrawRequestData.push(withdraw);
        });

      return {
        ...state,
        withdrawRequestList: withdrawRequestData,
        withdrawRequestListLoading: false,
      };
    case "getScbWithdrawBalance1":
      var scbWithdrawBalance1 = action.res.data.data.balance;

      return {
        ...state,
        scbWithdrawBalance1: scbWithdrawBalance1,
        loading: false,
      };
    case "getScbWithdrawBalance2":
      var scbWithdrawBalance2 = action.res.data.data.balance;

      return {
        ...state,
        scbWithdrawBalance2: scbWithdrawBalance2,
        loading: false,
      };
    case "getSumWithdrawBank1":
      let sumWithdrawBank1 = action.res.data.data;
      return {
        ...state,
        sumWithdrawBank1: sumWithdrawBank1 ? sumWithdrawBank1 : 0,
        loading: false,
      };
    case "getSumWithdrawBank2":
      let sumWithdrawBank2 = action.res.data.data;
      return {
        ...state,
        sumWithdrawBank2: sumWithdrawBank2 ? sumWithdrawBank2 : 0,
        loading: false,
      };
    case "getWithdrawBankList":
      let withdrawBankListData = action.res.data.data;
      return {
        ...state,
        withdrawBankList: withdrawBankListData,
        loading: false,
      };
    case "getWithdrawBankSumList":
      let withdrawBankSumData = action.res.data.data;
      return {
        ...state,
        withdrawBankSum: withdrawBankSumData,
        loading: false,
      };
    default:
      return state;
  }
};
export default withdrawReducer;
