const initState = {
  adminList: null,
  loading: true,
};

const adminReducer = (state = initState, action) => {
  switch (action.type) {
    case "getAdminList":
      var admins = action.res.data.data;
      var adminData = [];

      admins?.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      admins?.forEach((admin) => {
        adminData.push(admin);
      });

      return {
        ...state,
        adminList: adminData,
        loading: false,
      };
    default:
      return state;
  }
};
export default adminReducer;
