import React from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core';
import rewardColumnName from './rewardColumnName';

const useStyles = makeStyles((theme) => ({
  root: {},
  table: {
    '& .MuiTableSortLabel-root': {
      color: 'white',
    },
    '& .MuiTableCell-root': {
      color: 'white',
      borderWidth: 1,
      borderColor: theme.palette.background.tableLine,
      borderStyle: 'solid',
      padding: 8,
    },
  },
  tableRowColor1: {
    backgroundColor: theme.palette.background.grey1,
  },
  tableRowColor2: {
    backgroundColor: theme.palette.background.grey2,
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

const TableHeadCellWithSortComponent = ({ name }) => (
  <TableCell align="center">{name}</TableCell>
);

const TableHeadCellComponent = ({ name }) => (
  <TableCell align="center">{name}</TableCell>
);

const TableBodyRowComponent = ({ userRewardData, index, classNameStyle }) => (
  <TableRow className={classNameStyle}>
    <TableCell align="center">{index}</TableCell>
    <TableCell align="center">
      {moment(userRewardData.createdAt).format('DD/MM/YYYY H:mm:ss')}
    </TableCell>
    <TableCell align="center">{userRewardData.title}</TableCell>
    <TableCell align="center">{userRewardData.price}</TableCell>
    <TableCell align="center">
      {userRewardData.status !== 0 ? (
        userRewardData.status === 1 ? (
          <span style={{ color: '#00c851' }}>สำเร็จ</span>
        ) : (
          <span style={{ color: '#ff3547' }}>ยกเลิก</span>
        )
      ) : (
        <span style={{ color: '#33b5e5' }}>รอยืนยัน</span>
      )}
    </TableCell>
  </TableRow>
);

const UserRewardList = ({
  className,
  userRewardList,
  pageNum,
  perPage,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box style={{ backgroundColor: '#000' }}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRowColor1}>
                {rewardColumnName.map((column, index) =>
                  column.sort ? (
                    <TableHeadCellWithSortComponent
                      key={index}
                      name={column.label}
                    />
                  ) : (
                    <TableHeadCellComponent key={index} name={column.label} />
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {userRewardList
                ?.slice((pageNum - 1) * perPage, pageNum * perPage)
                .map((userRewardData, index) => (
                  <TableBodyRowComponent
                    classNameStyle={
                      index % 2
                        ? `${classes.tableRowColor1}`
                        : `${classes.tableRowColor2}`
                    }
                    key={index}
                    index={(pageNum - 1) * perPage + index + 1}
                    userRewardData={userRewardData}
                  />
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

UserRewardList.propTypes = {
  className: PropTypes.string,
  userRewardList: PropTypes.array,
  pageNum: PropTypes.number,
  perPage: PropTypes.number,
};

export default UserRewardList;
