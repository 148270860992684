import React from "react";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Grid,
  Button,
  IconButton,
} from "@material-ui/core";
import columnName from "./columnName";
import { useDispatch } from "react-redux";
import {
  onCancelCredit,
  onConfirmCredit,
} from "../../../store/actions/rewardAction";
import { Person as UserIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.black,
  },
  table: {
    "& .MuiTableSortLabel-root": {
      color: "white",
    },
    "& .MuiTableCell-root": {
      color: "white",
      borderWidth: 1,
      borderColor: theme.palette.background.tableLine,
      borderStyle: "solid",
      padding: 8,
    },
  },
  tableRowColor1: {
    backgroundColor: theme.palette.background.grey1,
  },
  tableRowColor2: {
    backgroundColor: theme.palette.background.grey2,
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const TableHeadCellComponent = ({ name }) => (
  <TableCell align="center">{name}</TableCell>
);

const TableBodyRowComponent = ({
  rewardData,
  classNameStyle,
  cancelBtn,
  confirmBtn,
}) => (
  <TableRow className={classNameStyle}>
    <TableCell align="center">
      {moment(rewardData.createdAt).format("DD/MM/YYYY H:mm:ss")}
    </TableCell>
    <TableCell align="center">
      {rewardData.status !== 0 ? (<span style={{ color: "#00c851" }}>{moment(rewardData.updatedAt).format("DD/MM/YYYY H:mm:ss")}</span>) : (<span style={{ color: "#ff3547" }}>รอยืนยัน</span>)}
    </TableCell>
    <TableCell align="center">{rewardData.title}</TableCell>
    <TableCell align="center">
      {rewardData?.user?.user_pass?.username && (
        <Grid container direction="row" justify="center" alignItems="center">
          {rewardData?.user?.user_pass?.username}
          <IconButton
            onClick={() =>
              window.open(
                `/admin/userdetail/${rewardData?.user?.user_pass?.username}`
              )
            }
            size="small"
            style={{ marginLeft: 10, color: "#33b5e5" }}
          >
            <UserIcon />
          </IconButton>
        </Grid>
      )}
    </TableCell>
    {/* <TableCell align="center">แลกเครดิต</TableCell> */}
    <TableCell align="center">{rewardData.price}</TableCell>
    {/* <TableCell width="20%">{rewardData.address}</TableCell> */}
    <TableCell align="center">
      {rewardData.status !== 0 ? (
        rewardData.status === 1 ? (
          <span style={{ color: "#00c851" }}>สำเร็จ</span>
        ) : (
          <span style={{ color: "#ff3547" }}>ยกเลิก</span>
        )
      ) : (
        <Grid
          spacing={1}
          container
          direction="row"
          justify="space-around"
          alignItems="center"
        >
          <Grid item>
            <Button
              variant="contained"
              size="large"
              style={{ backgroundColor: "#00c851", color: "white" }}
              onClick={confirmBtn}
            >
              ยืนยัน
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="large"
              style={{ backgroundColor: "#ff3547", color: "white" }}
              onClick={cancelBtn}
            >
              ยกเลิก
            </Button>
          </Grid>
        </Grid>
      )}
    </TableCell>
  </TableRow>
);

const CreditList = ({
  className,
  rewardDataList,
  search,
  pageNum,
  perPage,
  ...rest
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const confirmCredit = (id) => () => {
    if (window.confirm("คุณต้องการยืนยันหรือไม่")) {
      onConfirmCredit(dispatch, id);
    }
  };

  const onCancel = (id) => () => {
    if (window.confirm("คุณต้องการคืนพอยท์หรือไม่")) {
      onCancelCredit(dispatch, id);
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box style={{ backgroundColor: "#000" }} minWidth={800}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRowColor1}>
                {columnName.map((column, index) =>
                  column.sort ? (
                    <TableHeadCellComponent key={index} name={column.label} />
                  ) : (
                    <TableHeadCellComponent key={index} name={column.label} />
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rewardDataList
                .slice((pageNum - 1) * perPage, pageNum * perPage)
                .map((rewardData, index) => (
                  <TableBodyRowComponent
                    classNameStyle={
                      index % 2
                        ? `${classes.tableRowColor1}`
                        : `${classes.tableRowColor2}`
                    }
                    key={index}
                    rewardData={rewardData}
                    confirmBtn={confirmCredit(rewardData.id)}
                    cancelBtn={onCancel(rewardData.id)}
                  />
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

CreditList.propTypes = {
  className: PropTypes.string,
  rewardDataList: PropTypes.array,
  search: PropTypes.string,
  pageNum: PropTypes.number,
  perPage: PropTypes.string,
};

export default CreditList;
