const columnName = [
  {
    label: "วันที่แลก",
    field: "date",
    sort: "asc",
    width: 200,
  },
  {
    label: "วันที่ยืนยัน",
    field: "date",
    sort: "asc",
    width: 200,
  },
  {
    label: "ของที่แลก",
    field: "title",
    sort: "asc",
    width: 100,
  },
  {
    label: "รหัส",
    field: "username",
    sort: "asc",
    width: 150,
  },
  // {
  //   label: "ประเภท",
  //   field: "category",
  //   sort: "asc",
  //   width: 200,
  // },
  {
    label: "พอยท์ที่ใช้",
    field: "amount",
    sort: "asc",
    width: 150,
  },
  {
    label: "ที่อยู่",
    field: "address",
    sort: "asc",
    width: 100,
  },
  {
    label: "สถานะ",
    field: "action",
    sort: "asc",
    width: 200,
  },
];
export default columnName;
