import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogContent,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Divider,
  NativeSelect,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { AccountBalanceWallet as BankIcon } from '@material-ui/icons';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import BootstrapInput from '../../../components/BootstrapInput';
import { useDispatch } from 'react-redux';
import { editUserBank } from '../../../store/actions/userAction';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.black,
  },
  textColor: {
    color: 'white',
  },
  textFieldColor: {
    color: '#4285f4',
    backgroundColor: theme.palette.background.grey1,
  },
}));

const EditUserBank = ({ className, userData, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const EditUserBankSchema = Yup.object().shape({
    bankName: Yup.string().required('กรุณากรอกชื่อธนาคาร'),
  });

  const body = (
    <Formik
      validationSchema={EditUserBankSchema} //กำหนด validation
      initialValues={{
        //กำหนด initialValues
        bankName: userData.user_bank.bankName,
        bankId: userData.user_bank.bankId,
      }}
      onSubmit={(values) => {
        editUserBank(
          dispatch,
          values,
          userData.uuid,
          userData.user_pass.username
        );
        handleClose();
      }}
    >
      {({ errors, touched, values, handleBlur, handleChange }) => (
        <Form>
          <Box mb={3}>
            <Typography className={classes.textColor} variant="h2">
              แก้ไขข้อมูลธนาคารผู้ใช้
            </Typography>
          </Box>
          <Divider style={{ backgroundColor: 'white' }} />
          <Box mt={3} mb={3}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Typography className={classes.textColor} variant="h3">
                {userData.name}
              </Typography>
            </Grid>
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              ธนาคาร : {userData.user_bank.bankName}
            </Typography>
            <NativeSelect
              fullWidth
              id="bankName"
              name="bankName"
              defaultValue={userData.user_bank && userData.user_bank.bankName}
              onChange={handleChange}
              margin="dense"
              input={<BootstrapInput />}
            >
              <option value="KBANK">ธนาคารกสิกรไทย</option>
              <option value="SCB">ธนาคารไทยพาณิชย์</option>
              <option value="BBL">ธนาคารกรุงเทพ</option>
              <option value="GSB">ธนาคารออมสิน</option>
              <option value="TTB">ธนาคารทหารไทยธนชาต (ttb)</option>
              <option value="TMB">ธนาคารทหารไทย</option>
              <option value="KTB">ธนาคารกรุงไทย</option>
              <option value="BAY">ธนาคารกรุงศรีอยุธยา</option>
              <option value="SCIB">ธนาคารนครหลวงไทย</option>
              <option value="UOB">ธนาคารยูโอบี</option>
              <option value="TISCO">ธนาคารทิสโก้</option>
              <option value="ICBC">ธนาคารไอซีบีซี (ไทย)</option>
              <option value="TBANK">ธนาคารธนชาต</option>
              <option value="GHB">ธนาคารอาคารสงเคราะห์</option>
              <option value="LHB">ธนาคารแลนด์ แอนด์ เฮาส์</option>
              <option value="BAC">
                ธนาคารเพือการเกษตรและสหกรณ์การเกษตร (ธกส)
              </option>
              <option value="Islamic">ธนาคารอิสลามแห่ง ประเทศไทย</option>
              <option value="CIMB">ธนาคารซีไอเอม็ บีไทย</option>
              <option value="KK">ธนาคารเกียรตินาคินภัทร</option>
              <option value="TRUE">ทรูวอลเล็ต</option>
            </NativeSelect>
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              เลขบัญชีธนาคาร : {userData.user_bank.bankId}
            </Typography>
            <TextField
              error={Boolean(touched.bankId && errors.bankId)}
              fullWidth
              size="small"
              helperText={touched.bankId && errors.bankId}
              margin="normal"
              name="bankId"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.bankId}
              variant="outlined"
              InputProps={{
                className: classes.textFieldColor,
              }}
              style={{ marginTop: 8 }}
            />
          </Box>

          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ marginBottom: 16 }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              size="large"
              style={{ backgroundColor: '#B23CFD', color: 'white' }}
            >
              ปิด
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              style={{ backgroundColor: '#4285f4', color: 'white' }}
            >
              บันทึก
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );

  return (
    <div>
      <Button
        variant="contained"
        style={{ backgroundColor: '#00B74A', color: 'white' }}
        onClick={handleClickOpen}
      >
        <BankIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent className={classes.paper}>{body}</DialogContent>
      </Dialog>
    </div>
  );
};

EditUserBank.propTypes = {
  className: PropTypes.string,
  userData: PropTypes.object,
};

export default EditUserBank;
