import React from "react";
import { Navigate } from "react-router-dom";
import DashboardLayout from "./layouts/DashboardLayout";
import MainLayout from "./layouts/MainLayout";
import LoginView from "./views/auth/LoginView";
import NotFoundView from "./views/errors/NotFoundView";
//=============================================================
import DashboardView from "./views/dashboard/DashboardView";
import UserView from "./views/user/UserView";
import ReportView from "./views/report/ReportView";
import DepositView from "./views/deposit/DepositView";
import DepositBankView from "./views/deposit/DepositBankView";
import WithdrawView from "./views/withdraw/WithdrawView";
import WithdrawBankView from "./views/withdraw/WithdrawBankView";
import UserDetailView from "./views/user/UserDetailView";
import RewardView from "./views/reward/RewardView";
import CreditView from "./views/reward/CreditView";
import AdminView from "./views/admin/AdminView";
import LogsView from "./views/logs/LogsView";
import LogsWithAdminView from "./views/logs/LogsWithAdminView";
import SettingView from "./views/setting/SettingView";
import RewardSettingView from "./views/setting/RewardSettingView";
import WheelSettingView from "./views/setting/WheelSettingView";
import BankView from "./views/bank/BankView";
//=============================================================

const routes = [
  {
    path: "admin",
    element: <DashboardLayout />,
    children: [
      { path: "user", element: <UserView /> },
      { path: "userdetail/:username", element: <UserDetailView /> },
      { path: "dashboard", element: <DashboardView /> },
      { path: "report", element: <ReportView /> },
      { path: "deposit", element: <DepositView /> },
      { path: "withdraw", element: <WithdrawView /> },
      { path: "reward", element: <RewardView /> },
      { path: "credit", element: <CreditView /> },
      { path: "deposit_bank", element: <DepositBankView /> },
      { path: "withdraw_bank", element: <WithdrawBankView /> },
      { path: "admin_list", element: <AdminView /> },
      { path: "bank", element: <BankView /> },
      { path: "logs", element: <LogsView /> },
      { path: "logs/:uuid", element: <LogsWithAdminView /> },
      { path: "setting", element: <SettingView /> },
      { path: "setting/reward", element: <RewardSettingView /> },
      { path: "setting/wheel", element: <WheelSettingView /> },
      { path: "/", element: <Navigate to="/admin/dashboard" /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "login", element: <LoginView /> },
      { path: "404", element: <NotFoundView /> },
      { path: "/", element: <Navigate to="/admin/dashboard" /> },
      { path: "*", element: <Navigate to="/404" /> },
    ],
  },
];

export default routes;
