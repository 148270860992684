const columnName = [
  {
    label: 'รหัส',
    field: 'imiId',
    sort: 'asc',
    width: 200,
  },
  {
    label: 'ผู้แนะนำ',
    field: 'inviter',
    sort: 'asc',
    width: 100,
  },
  {
    label: 'ชื่อ-สกุล',
    field: 'name',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'ยูสเซอร์ไอดี',
    field: 'username',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'ธนาคาร',
    field: 'bankName',
    sort: 'asc',
    width: 200,
  },
  {
    label: 'เลขบัญชีธนาคาร',
    field: 'bankId',
    sort: 'asc',
    width: 100,
  },
  {
    label: 'สมัครเมื่อ',
    field: 'date',
    sort: 'asc',
    width: 200,
  },
  {
    label: '',
    width: 200,
  },
];
export default columnName;
