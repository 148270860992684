import Axios from "./axiosConfig";

export default function setAuthorizationToken(token, user) {
  if (token) {
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));
    Axios.defaults.headers.Authorization = `Bearer ${token}`;
  } else {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    delete Axios.defaults.headers.Authorization;
  }
}
