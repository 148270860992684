import React, { useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Grid,
} from '@material-ui/core';
import Page from '../../../components/Page';
import LoadingCircle from '../../../utils/LoadingCircle';
import { Navigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getSettingDetail } from '../../../store/actions/settingAction';
import SettingDetail from './SettingDetail';
// import EditSettingDetail from "./EditSettingDetail";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.black,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  textColor: {
    color: 'white',
  },
  tableRowColor: {
    backgroundColor: theme.palette.background.grey1,
  },
}));

function Setting() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const settingLoading = useSelector((state) => state.setting.loading);
  const settingDetail = useSelector((state) => state.setting.settingDetail);
  const errorMessage = useSelector((state) => state.other.errorMessage);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const authLoading = useSelector((state) => state.auth.loading);

  useEffect(() => {
    getSettingDetail(dispatch);
  }, [dispatch]);

  if (!authLoading && !currentUser) return <Navigate to="/login" />;
  else
    return (
      <Page className={classes.root} title="ตั้งค่าทั่วไป">
        <Container>
          {!errorMessage ? (
            !settingLoading && settingDetail ? (
              <>
                <Typography
                  className={classes.textColor}
                  style={{ marginBottom: 16 }}
                  variant="h2"
                >
                  Setting
                </Typography>
                <Box
                  borderRadius="borderRadius"
                  boxShadow={3}
                  p={2}
                  className={classes.tableRowColor}
                >
                  {/* <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    style={{ marginBottom: 16 }}
                  >
                    <EditSettingDetail settingDetailData={settingDetail} />
                  </Grid> */}
                  <Box>
                    <SettingDetail settingDetail={settingDetail} />
                  </Box>
                </Box>
              </>
            ) : (
              <LoadingCircle />
            )
          ) : (
            <>
              <Box p={4} className={classes.tableRowColor}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Typography className={classes.textColor} variant="h2">
                    {errorMessage}
                  </Typography>
                </Grid>
              </Box>
            </>
          )}
        </Container>
      </Page>
    );
}

export default Setting;
