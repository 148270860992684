import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Box,
  Container,
  makeStyles,
  TextField,
  Typography,
  Grid,
  NativeSelect,
  Button,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import Page from "../../../components/Page";
import LoadingCircle from "../../../utils/LoadingCircle";
import UserList from "./UserList";
import {
  getUserList,
  getUserListByOther,
} from "../../../store/actions/userAction";
import { Navigate } from "react-router";
import BootstrapInput from "../../../components/BootstrapInput";
import { Clear as ClearIcon, Search as SearchIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.black,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  pagination: {
    "& .MuiPaginationItem-root": {
      color: "white",
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
    "& label.Mui-focused": {
      color: theme.palette.background.tableLine,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: theme.palette.background.tableLine,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: theme.palette.background.tableLine,
      },
      "&:hover fieldset": {
        borderColor: theme.palette.background.tableLine,
      },
      "&.Mui-focused fieldset": {
        borderColor: theme.palette.background.tableLine,
      },
    },
  },
  textColor: {
    color: "white",
  },
  tableRowColor: {
    backgroundColor: theme.palette.background.grey1,
  },
}));

function User() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userLoading = useSelector((state) => state.user.loading);
  const userList = useSelector((state) => state.user.userList);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const authLoading = useSelector((state) => state.auth.loading);
  const errorMessage = useSelector((state) => state.other.errorMessage);
  const [pageNum, setPageNum] = useState(1);
  const [perPage, setPerPage] = useState("10");
  const [search, setSearch] = useState("");
  const [searchByOther, setSearchByOther] = useState("");

  useEffect(() => {
    getUserList(dispatch);
  }, [dispatch]);

  const handleChangePerPage = (event) => {
    setPerPage(event.target.value);
  };

  const handleChangePage = (e, value) => {
    setPageNum(value);
  };

  const onSearch = (e) => {
    setSearch(e.target.value);
    setPageNum(1)
  };

  const handleClickSearchByOther = () => {
    if (searchByOther !== "" && searchByOther !== null) {
      dispatch({ type: "onUserLoading" });
      getUserListByOther(dispatch, searchByOther);
    }
  };

  const handleClickClear = () => {
    if (userList.length <= 10) {
      dispatch({ type: "onUserLoading" });
      getUserList(dispatch);
    }
  };

  const onSearchByUsername = (e) => {
    setSearchByOther(e.target.value);
  };

  const searchFilter = (searchStr) => {
    if (searchStr) {
      return (
        searchStr
          .toString()
          .toLowerCase()
          .indexOf(search.toString().toLowerCase()) !== -1
      );
    }
  };

  var userDataListFilter = userList?.filter((userData) => {
    return (
      searchFilter(userData?.user_pass?.username) ||
      searchFilter(userData?.inviter) ||
      searchFilter(userData?.phone) ||
      searchFilter(userData?.user_bank?.bankName) ||
      searchFilter(userData?.name) ||
      searchFilter(userData?.user_bank?.bankId) ||
      searchFilter(moment(userData?.createdAt).format("DD/MM/YYYY H:mm"))
    );
  });

  if (!authLoading && !currentUser) return <Navigate to="/login" />;
  else
    return (
      <Page className={classes.root} title="สมาชิก">
        <Container>
          {!errorMessage ? (
            !userLoading ? (
              <>
                <Typography
                  className={classes.textColor}
                  style={{ marginBottom: 16 }}
                  variant="h2"
                >
                  สมาชิก
                </Typography>
                {userList?.length >= 1 ? (
                  <Box
                    borderRadius="borderRadius"
                    boxShadow={3}
                    p={2}
                    className={classes.tableRowColor}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                      style={{ marginBottom: 16 }}
                    >
                      <TextField
                        label="รหัส/ชื่อ/เบอร์/เลขบัญชี"
                        onChange={(e) => onSearchByUsername(e)}
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        InputProps={{
                          className: classes.textColor,
                        }}
                        style={{
                          margin: 0,
                        }}
                      />
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#33b5e5",
                          color: "white",
                          marginLeft: 10,
                        }}
                        onClick={handleClickSearchByOther}
                      >
                        <SearchIcon />
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#ff3547",
                          color: "white",
                          marginLeft: 10,
                        }}
                        onClick={handleClickClear}
                      >
                        <ClearIcon />
                      </Button>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      style={{ marginBottom: 16 }}
                    >
                      <TextField
                        label="ค้นหาในตาราง"
                        onChange={(e) => onSearch(e)}
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        InputProps={{
                          className: classes.textColor,
                        }}
                        style={{
                          margin: 0,
                        }}
                      />
                      <NativeSelect
                        id="demo-customized-select-native"
                        value={perPage}
                        margin="dense"
                        onChange={handleChangePerPage}
                        input={<BootstrapInput />}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </NativeSelect>
                    </Grid>
                    <Box>
                      <UserList
                        userDataList={userDataListFilter}
                        search={search}
                        pageNum={pageNum}
                        perPage={perPage}
                      />
                    </Box>
                    <Box mt={3} display="flex" justifyContent="center">
                      <Pagination
                        color="secondary"
                        className={classes.pagination}
                        count={Math.ceil(userDataListFilter?.length / perPage)}
                        size="large"
                        onChange={handleChangePage}
                        variant="outlined"
                        shape="rounded"
                      />
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Grid
                      container
                      direction="row"
                      justify="flex-start"
                      alignItems="center"
                      style={{ marginBottom: 16 }}
                    >
                      <TextField
                        label="รหัส/ชื่อ/เบอร์/เลขบัญชี"
                        onChange={(e) => onSearchByUsername(e)}
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        InputProps={{
                          className: classes.textColor,
                        }}
                        style={{
                          margin: 0,
                        }}
                      />
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#33b5e5",
                          color: "white",
                          marginLeft: 10,
                        }}
                        onClick={handleClickSearchByOther}
                      >
                        <SearchIcon />
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: "#ff3547",
                          color: "white",
                          marginLeft: 10,
                        }}
                        onClick={handleClickClear}
                      >
                        <ClearIcon />
                      </Button>
                    </Grid>
                    <Box p={4} className={classes.tableRowColor}>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                      >
                        <Typography className={classes.textColor} variant="h2">
                          ไม่พบข้อมูลรายชื่อผู้ใช้
                        </Typography>
                      </Grid>
                    </Box>
                  </>
                )}
              </>
            ) : (
              <LoadingCircle />
            )
          ) : (
            <>
              <Box p={4} className={classes.tableRowColor}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Typography className={classes.textColor} variant="h2">
                    {errorMessage}
                  </Typography>
                </Grid>
              </Box>
            </>
          )}
        </Container>
      </Page>
    );
}

export default User;
