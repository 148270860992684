const initState = {
  bankList: [],
  loading: false,
};

const bankReducer = (state = initState, action) => {
  switch (action.type) {
    case 'getBankList':
      var bankListData = action.res.data.data;

      return {
        ...state,
        bankList: bankListData,
        loading: false,
      };
    case 'onBankLoading':
      return {
        ...state,
        loading: true,
      };
    case 'onBankFinish':
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export default bankReducer;
