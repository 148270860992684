import React, { useEffect } from 'react';
import moment from 'moment';
import {
  Typography,
  Container,
  makeStyles,
  Grid,
  Box,
} from '@material-ui/core';
import Page from '../../../components/Page';
import { Navigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import {
  getSumManualWithdraw_Shift1,
  getSumManualWithdraw_Shift2,
} from '../../../store/actions/dashboardAction';
import Graph1 from './Graph1';
import Graph2 from './Graph2';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.black,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  textColor: {
    color: 'white',
  },
}));

function Dashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const authLoading = useSelector((state) => state.auth.loading);
  const errorMessage = useSelector((state) => state.other.errorMessage);
  var dateNow = moment().format('MM/DD/YYYY');
  var dateYesterday = moment().subtract(1, 'days').format('MM/DD/YYYY');
  var dateTomorrow = moment().add(1, 'days').format('MM/DD/YYYY');

  var timeStart1 = moment(`${dateNow} 08:01`);
  var timeEnd1 = moment(`${dateNow} 20:01`);

  if (moment().get('hour') < 8) {
    timeStart1 = moment(`${dateYesterday} 08:01`);
    timeEnd1 = moment(`${dateYesterday} 20:01`);
  }

  var timeStart2 = moment(`${dateNow} 20:01`);
  var timeEnd2 = moment(`${dateTomorrow} 08:01`);

  if (moment().get('hour') < 20) {
    timeStart2 = moment(`${dateYesterday} 20:01`);
    timeEnd2 = moment(`${dateNow} 08:01`);
  }

  useEffect(() => {
    getSumManualWithdraw_Shift1(dispatch, timeStart1, timeEnd1);
    getSumManualWithdraw_Shift2(dispatch, timeStart2, timeEnd2);
    // eslint-disable-next-line
  }, [dispatch]);

  if (!authLoading && !currentUser) return <Navigate to="/login" />;
  else
    return (
      <Page className={classes.root} title="ภาพรวม">
        <Container>
          <Typography
            className={classes.textColor}
            style={{ marginBottom: 16 }}
            variant="h2"
          >
            ภาพรวม
          </Typography>
          {!errorMessage ? (
            <Grid
              spacing={3}
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item md={8} xs={12}>
                <Graph1 timeStart={timeStart1} timeEnd={timeEnd1} />
              </Grid>
              <Grid item md={8} xs={12}>
                <Graph2 timeStart={timeStart2} timeEnd={timeEnd2} />
              </Grid>
            </Grid>
          ) : (
            <>
              <Box p={4} className={classes.tableRowColor}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Typography className={classes.textColor} variant="h2">
                    {errorMessage}
                  </Typography>
                </Grid>
              </Box>
            </>
          )}
        </Container>
      </Page>
    );
}

export default Dashboard;
