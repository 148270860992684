import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Grid,
  NativeSelect,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Page from '../../../components/Page';
import LoadingCircle from '../../../utils/LoadingCircle';
import { useDispatch, useSelector } from 'react-redux';
import { getBankList } from '../../../store/actions/bankAction';
import BootstrapInput from '../../../components/BootstrapInput';
import { Navigate } from 'react-router';
import BankList from './BankList';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.black,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
    '& label.Mui-focused': {
      color: theme.palette.background.tableLine,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.background.tableLine,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.background.tableLine,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.background.tableLine,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.background.tableLine,
      },
    },
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      color: 'white',
    },
  },
  textColor: {
    color: 'white',
  },
  tableRowColor: {
    backgroundColor: theme.palette.background.grey1,
  },
}));

function Bank() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const bankLoading = useSelector((state) => state.bank.loading);
  const bankList = useSelector((state) => state.bank.bankList);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const authLoading = useSelector((state) => state.auth.loading);
  const errorMessage = useSelector((state) => state.other.errorMessage);
  const [pageNum, setPageNum] = useState(1);
  const [perPage, setPerPage] = useState('10');

  useEffect(() => {
    getBankList(dispatch, 'all')
  }, [dispatch]);

  const handleChangePerPage = (event) => {
    setPerPage(event.target.value);
  };

  const handleChangePage = (e, value) => {
    setPageNum(value);
  };

  if (!authLoading && !currentUser) return <Navigate to="/login" />;
  else
    return (
      <Page className={classes.root} title="Banks">
        <Container>
          {!errorMessage ? (
            !bankLoading ? (
              <>
                <Typography
                  className={classes.textColor}
                  style={{ marginBottom: 16 }}
                  variant="h2"
                >
                  บัญชีธนาคาร
                </Typography>
                <Box
                  borderRadius="borderRadius"
                  boxShadow={3}
                  p={2}
                  className={classes.tableRowColor}
                >
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    style={{ marginBottom: 20 }}
                  >
                    {/* <AddAdmin /> */}
                  </Grid>
                  {bankList?.length >= 1 ? (
                    <>
                      <Grid
                        container
                        direction="row"
                        justify="flex-end"
                        alignItems="center"
                        style={{ marginBottom: 16 }}
                      >
                        <NativeSelect
                          id="demo-customized-select-native"
                          value={perPage}
                          margin="dense"
                          onChange={handleChangePerPage}
                          input={<BootstrapInput />}
                        >
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </NativeSelect>
                      </Grid>
                      <Box>
                        <BankList
                          bankDataList={bankList}
                          // search={search}
                          pageNum={pageNum}
                          perPage={perPage}
                        />
                      </Box>
                      <Box mt={3} display="flex" justifyContent="center">
                        <Pagination
                          color="secondary"
                          className={classes.pagination}
                          count={Math.ceil(
                            bankList?.length / perPage
                          )}
                          size="large"
                          onChange={handleChangePage}
                          variant="outlined"
                          shape="rounded"
                        />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box p={4} className={classes.tableRowColor}>
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          <Typography
                            className={classes.textColor}
                            variant="h2"
                          >
                            ไม่พบข้อมูล Bank
                          </Typography>
                        </Grid>
                      </Box>
                    </>
                  )}
                </Box>
              </>
            ) : (
              <LoadingCircle />
            )
          ) : (
            <>
              <Box p={4} className={classes.tableRowColor}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Typography className={classes.textColor} variant="h2">
                    {errorMessage}
                  </Typography>
                </Grid>
              </Box>
            </>
          )}
        </Container>
      </Page>
    );
}

export default Bank;
