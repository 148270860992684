import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Grid,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';
import { useReactToPrint } from 'react-to-print';
import { Person as UserIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {},
  table: {
    '& .MuiTableSortLabel-root': {
      color: 'white',
    },
    '& .MuiTableCell-root': {
      color: 'white',
      borderWidth: 1,
      borderColor: theme.palette.background.tableLine,
      borderStyle: 'solid',
      padding: 1,
    },
  },
  tableRowColor1: {
    backgroundColor: theme.palette.background.grey1,
  },
  tableRowColor2: {
    backgroundColor: theme.palette.background.grey2,
  },
}));

const TableHeadCellComponent = ({ name }) => (
  <TableCell align="center">{name}</TableCell>
);

const TableBodyRowComponent = ({ reportWithdrawData, classNameStyle }) => (
  <TableRow className={classNameStyle}>
    <TableCell align="center">{reportWithdrawData.date}</TableCell>
    <TableCell align="center">
      <Grid container direction="row" justify="center" alignItems="center">
        {reportWithdrawData?.user?.user_pass?.username}
        <div className='player-icon'>
          <IconButton
            onClick={() =>
              window.open(
                `/admin/userdetail/${reportWithdrawData?.user?.user_pass?.username}`
              )
            }
            size="small"
            style={{ marginLeft: 10, color: "#33b5e5" }}
          >
            <UserIcon />
          </IconButton>
        </div>
      </Grid>
    </TableCell>
    <TableCell align="center">
      {reportWithdrawData.amount?.toLocaleString('en', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}
    </TableCell>
    <TableCell align="center">
      {reportWithdrawData?.bank ? `${reportWithdrawData?.bank?.bankName} ${reportWithdrawData?.bank?.bankId}` : "-"}
    </TableCell>
    <TableCell align="center">{reportWithdrawData.statusWord}</TableCell>
    <TableCell align="center">{reportWithdrawData?.admin?.name}</TableCell>
  </TableRow>
);

const ReportDepositList = ({
  className,
  reportWithdrawList,
  pageNum,
  perPage,
  ...rest
}) => {
  const classes = useStyles();
  const withdrawRef = React.useRef(null);

  const pageStyle = `
  @media all {
    .pagebreak {
      display: none;
    }
    .player-icon {
      display: none;
    }
  }

  @media print {
    th, td, h4 {
      border-width: 1px;
      border-style: "solid";
      border-color: #000 !important;
      background: #fff !important;
      color: #000 !important;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }`;

  const handlePrintWithdraw = useReactToPrint({
    content: () => withdrawRef.current,
    pageStyle: pageStyle,
  });

  const withdrawColumnName = [
    {
      label: 'เวลา',
    },
    {
      label: 'ยูสเซอร์เนม',
    },
    {
      label: 'จำนวน',
    },
    {
      label: 'โอนจากบัญชี',
    },
    {
      label: 'สถานะ',
    },
    {
      label: 'แอดมิน',
    },
  ];

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar className={classes.tableRowColor1}>
        <Box style={{ marginBottom: 20 }}>
          <Grid
            spacing={3}
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item>
              <Button
                variant="contained"
                style={{ backgroundColor: '#ff3547', color: 'white' }}
                onClick={handlePrintWithdraw}
              >
                พิมพ์รายการถอน
              </Button>
            </Grid>
          </Grid>
        </Box>
        <Box ref={withdrawRef}>
          <Typography
            className={classes.textColor}
            style={{ marginBottom: 10, color: 'white' }}
            variant="h4"
          >
            รายการถอน
          </Typography>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRowColor1}>
                {withdrawColumnName.map((column, index) => (
                  <TableHeadCellComponent key={index} name={column.label} />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {reportWithdrawList.map((reportWithdrawData, index) => (
                <TableBodyRowComponent
                  classNameStyle={
                    index % 2
                      ? `${classes.tableRowColor1}`
                      : `${classes.tableRowColor2}`
                  }
                  key={index}
                  reportWithdrawData={reportWithdrawData}
                />
              ))}
              <TableRow>
                <TableCell colSpan={2} align="center">
                  ยอดรวม
                </TableCell>
                <TableCell align="center">
                  {reportWithdrawList
                    ?.reduce((sum, number) => sum + number.amount, 0)
                    ?.toLocaleString('en', {
                      minimumFractionDigits: 2,
                    })}
                </TableCell>
                <TableCell colSpan={2} align="center"></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

ReportDepositList.propTypes = {
  className: PropTypes.string,
  reportWithdrawList: PropTypes.array,
};

export default ReportDepositList;
