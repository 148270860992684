import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Box,
  Container,
  makeStyles,
  TextField,
  Typography,
  Grid,
  NativeSelect,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../../components/Page';
import LoadingCircle from '../../../utils/LoadingCircle';
import { Navigate } from 'react-router';
import {
  getRewardReqList,
  getRewardRecList,
} from '../../../store/actions/rewardAction';
import BootstrapInput from '../../../components/BootstrapInput';
import RewardList from './RewardList';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.black,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      color: 'white',
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
    '& label.Mui-focused': {
      color: theme.palette.background.tableLine,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.background.tableLine,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.background.tableLine,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.background.tableLine,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.background.tableLine,
      },
    },
  },
  textColor: {
    color: 'white',
  },
  tableRowColor: {
    backgroundColor: theme.palette.background.grey1,
  },
}));

function Reward() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const rewardLoading = useSelector((state) => state.reward.loading);
  const rewardReqList = useSelector((state) => state.reward.rewardReqList);
  const rewardRecList = useSelector((state) => state.reward.rewardRecList);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const errorMessage = useSelector((state) => state.other.errorMessage);
  const authLoading = useSelector((state) => state.auth.loading);

  const [requestPageNum, setRequestPageNum] = useState(1);
  const [requestPerPage, setRequestPerPage] = useState('10');
  const [requestSearch, setRequestSearch] = useState('');

  const [historyPageNum, setHistoryPageNum] = useState(1);
  const [historyPerPage, setHistoryPerPage] = useState('10');
  const [historySearch, setHistorySearch] = useState('');

  useEffect(() => {
    getRewardReqList(dispatch);
    getRewardRecList(dispatch);
  }, [dispatch]);

  const onRequestSearch = (e) => {
    setRequestSearch(e.target.value);
    setRequestPageNum(1);
  };
  const handleChangeRequestPerPage = (event) => {
    setRequestPerPage(event.target.value);
  };
  const handleRequestChangePage = (e, value) => {
    setRequestPageNum(value);
  };

  const requestSearchFilter = (searchStr) => {
    if (searchStr) {
      return (
        searchStr
          .toString()
          .toLowerCase()
          .indexOf(requestSearch.toString().toLowerCase()) !== -1
      );
    }
  };

  var rewardRequestListFilter = rewardReqList?.filter((rewardRequestData) => {
    return (
      (requestSearchFilter(
        moment(rewardRequestData?.createdAt).format('DD/MM/YYYY H:mm')
      ) ||
        requestSearchFilter(rewardRequestData?.title) ||
        requestSearchFilter(rewardRequestData?.user?.user_pass?.username) ||
        requestSearchFilter(
          rewardRequestData?.categoryId === 1 ? 'แลกเครดิต' : 'แลกของ'
        ) ||
        requestSearchFilter(rewardRequestData?.price) ||
        requestSearchFilter(rewardRequestData?.address)) &&
      rewardRequestData.status === 0
    );
  });

  const onHistorySearch = (e) => {
    setHistorySearch(e.target.value);
    setHistoryPageNum(1);
  };
  const handleHistoryChangePerPage = (event) => {
    setHistoryPerPage(event.target.value);
  };
  const handleHistoryChangePage = (e, value) => {
    setHistoryPageNum(value);
  };

  const historySearchFilter = (searchStr) => {
    if (searchStr) {
      return (
        searchStr
          .toString()
          .toLowerCase()
          .indexOf(historySearch.toString().toLowerCase()) !== -1
      );
    }
  };

  var rewardHistoryListFilter = rewardRecList?.filter((rewardHistoryData) => {
    return (
      (historySearchFilter(
        moment(rewardHistoryData?.createdAt).format('DD/MM/YYYY H:mm')
      ) ||
        historySearchFilter(rewardHistoryData?.title) ||
        historySearchFilter(rewardHistoryData?.user?.user_pass?.username) ||
        historySearchFilter(
          rewardHistoryData?.categoryId === 1 ? 'แลกเครดิต' : 'แลกของ'
        ) ||
        historySearchFilter(rewardHistoryData?.price) ||
        historySearchFilter(rewardHistoryData?.address)) &&
      rewardHistoryData.status !== 0
    );
  });

  if (!authLoading && !currentUser) return <Navigate to="/login" />;
  else
    return (
      <Page className={classes.root} title="แลกของ">
        <Container>
          {!errorMessage ? (
            !rewardLoading ? (
              <>
                <Typography
                  className={classes.textColor}
                  style={{ marginBottom: 16 }}
                  variant="h2"
                >
                  แลกของ
                </Typography>
                {rewardRequestListFilter?.length > 0 ? (
                  <Box
                    borderRadius="borderRadius"
                    boxShadow={3}
                    p={2}
                    className={classes.tableRowColor}
                    style={{ marginBottom: 16 }}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      style={{ marginBottom: 16 }}
                    >
                      <TextField
                        label="ค้นหาในตาราง"
                        onChange={(e) => onRequestSearch(e)}
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        InputProps={{
                          className: classes.textColor,
                        }}
                        style={{
                          margin: 0,
                        }}
                      />
                      <NativeSelect
                        value={requestPerPage}
                        margin="dense"
                        onChange={handleChangeRequestPerPage}
                        input={<BootstrapInput />}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </NativeSelect>
                    </Grid>
                    <Box>
                      <RewardList
                        rewardDataList={rewardRequestListFilter}
                        search={requestSearch}
                        pageNum={requestPageNum}
                        perPage={requestPerPage}
                      />
                    </Box>
                    <Box mt={3} display="flex" justifyContent="center">
                      <Pagination
                        color="secondary"
                        className={classes.pagination}
                        count={Math.ceil(
                          rewardRequestListFilter?.length / requestPerPage
                        )}
                        size="large"
                        onChange={handleRequestChangePage}
                        variant="outlined"
                        shape="rounded"
                      />
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Box p={4} mb={3} className={classes.tableRowColor}>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                      >
                        <Typography className={classes.textColor} variant="h2">
                          ไม่พบข้อมูลรายการแลกของ
                        </Typography>
                      </Grid>
                    </Box>
                  </>
                )}
                {rewardHistoryListFilter?.length > 0 ? (
                  <Box
                    borderRadius="borderRadius"
                    boxShadow={3}
                    p={2}
                    className={classes.tableRowColor}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      style={{ marginBottom: 16 }}
                    >
                      <TextField
                        label="ค้นหาในตาราง"
                        onChange={(e) => onHistorySearch(e)}
                        className={classes.textField}
                        margin="dense"
                        variant="outlined"
                        InputProps={{
                          className: classes.textColor,
                        }}
                        style={{
                          margin: 0,
                        }}
                      />
                      <NativeSelect
                        value={historyPerPage}
                        margin="dense"
                        onChange={handleHistoryChangePerPage}
                        input={<BootstrapInput />}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </NativeSelect>
                    </Grid>
                    <Box>
                      <RewardList
                        rewardDataList={rewardHistoryListFilter}
                        search={historySearch}
                        pageNum={historyPageNum}
                        perPage={historyPerPage}
                      />
                    </Box>
                    <Box mt={3} display="flex" justifyContent="center">
                      <Pagination
                        color="secondary"
                        className={classes.pagination}
                        count={Math.ceil(
                          rewardHistoryListFilter?.length / historyPerPage
                        )}
                        size="large"
                        onChange={handleHistoryChangePage}
                        variant="outlined"
                        shape="rounded"
                      />
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Box p={4} className={classes.tableRowColor}>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                      >
                        <Typography className={classes.textColor} variant="h2">
                          ไม่พบข้อมูลประวัติแลกของ
                        </Typography>
                      </Grid>
                    </Box>
                  </>
                )}
              </>
            ) : (
              <LoadingCircle />
            )
          ) : (
            <>
              <Box p={4} className={classes.tableRowColor}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Typography className={classes.textColor} variant="h2">
                    {errorMessage}
                  </Typography>
                </Grid>
              </Box>
            </>
          )}
        </Container>
      </Page>
    );
}

export default Reward;
