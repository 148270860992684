import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import {
  Typography,
  Container,
  makeStyles,
  Box,
  Grid,
  TextField,
  NativeSelect,
  Button,
} from '@material-ui/core';
import Page from '../../../components/Page';
import { Navigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import BootstrapInput from '../../../components/BootstrapInput';
import {
  getUserReportList,
  getAllDepositReportList,
  getDepositReportList,
  getAllWithdrawReportList,
  getWithdrawReportList,
  getReportBankList,
} from '../../../store/actions/reportAction';
import {
  GetApp as DownloadIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import LoadingCircle from '../../../utils/LoadingCircle';
import ReportDepositList from './ReportDepositList';
import ReportWithdrawList from './ReportWithdrawList';
import config from '../../../configs';

import ReactExport from 'react-export-excel';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.black,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
    '& label.Mui-focused': {
      color: theme.palette.background.tableLine,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.background.tableLine,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.background.tableLine,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.background.tableLine,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.background.tableLine,
      },
    },
  },
  textColor: {
    color: 'white',
  },
  tableRowColor: {
    backgroundColor: theme.palette.background.grey1,
  },
}));

function Report() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const exportRef = useRef(null);
  const [time, setTime] = useState('1');
  const [timeStart, setTimeStart] = useState('08:01');
  const [timeEnd, setTimeEnd] = useState('20:00');
  const [reportType, setReportType] = useState('deposit');
  const [bankSelected, setBankSelected] = useState('all');
  const [date1, setDate1] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const [date2, setDate2] = useState(moment(new Date()).format('YYYY-MM-DD'));
  const errorMessage = useSelector((state) => state.other.errorMessage);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const authLoading = useSelector((state) => state.auth.loading);
  const reportLoading = useSelector((state) => state.report.loading);
  const reportUserList = useSelector((state) => state.report.reportUserList);
  const reportDepositList = useSelector(
    (state) => state.report.reportDepositList
  );
  const reportWithdrawList = useSelector(
    (state) => state.report.reportWithdrawList
  );
  const reportBankList = useSelector((state) => state.report.reportBankList);

  const onSetDate = (e) => {
    if (time === '1') {
      setDate1(e.target.value);
      setDate2(e.target.value);
    } else {
      setDate1(e.target.value);
      setDate2(moment(e.target.value).add(1, 'day').format('YYYY-MM-DD'));
    }
  };

  const handleChangeTime = (event) => {
    setTime(event.target.value);
    if (event.target.value === '1') {
      setDate2(moment(date1).format('YYYY-MM-DD'));
      setTimeStart('08:01');
      setTimeEnd('20:00');
    } else {
      setDate2(moment(date1).add(1, 'day').format('YYYY-MM-DD'));
      setTimeStart('20:01');
      setTimeEnd('08:00');
    }
  };

  const handleChangeType = (event) => {
    setReportType(event.target.value);
  };

  const handleChangeBank = (event) => {
    setBankSelected(event.target.value);
  };

  let dateTimeStart = moment(`${date1} ${timeStart}`);
  let dateTimeEnd = moment(`${date2} ${timeEnd}`);

  const handleClickExport = async () => {
    dispatch({ type: 'onReportLoading' });
    await getUserReportList(dispatch, dateTimeStart, dateTimeEnd);
    await getAllDepositReportList(dispatch, dateTimeStart, dateTimeEnd);
    await getAllWithdrawReportList(dispatch, dateTimeStart, dateTimeEnd);
    exportRef.current.click();
    dispatch({ type: 'onReportFinish' });
  };

  const handleClickSearch = async () => {
    dispatch({ type: 'onReportLoading' });
    if (reportType === 'deposit') {
      if (bankSelected === 'all') {
        await getAllDepositReportList(dispatch, dateTimeStart, dateTimeEnd);
      } else {
        await getDepositReportList(dispatch, bankSelected, dateTimeStart, dateTimeEnd);
      }
    }
    if (reportType === 'withdraw') {
      if (bankSelected === 'all') {
        await getAllWithdrawReportList(dispatch, dateTimeStart, dateTimeEnd);
      } else {
        getWithdrawReportList(dispatch, bankSelected, dateTimeStart, dateTimeEnd);
      }
    }
    dispatch({ type: 'onReportFinish' });
  };

  useEffect(() => {
    getReportBankList(dispatch, reportType);
  }, [dispatch, reportType]);

  if (!authLoading && !currentUser) return <Navigate to="/login" />;
  else
    return (
      <Page className={classes.root} title="รายงาน">
        <Container>
          <Typography
            className={classes.textColor}
            style={{ marginBottom: 16 }}
            variant="h2"
          >
            รายงานย้อนหลัง
          </Typography>
          {!errorMessage ? (
            !reportLoading ? (
              <Box
                borderRadius="borderRadius"
                boxShadow={3}
                p={4}
                className={classes.tableRowColor}
              >
                <Grid
                  style={{ marginBottom: 20 }}
                  spacing={3}
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    <TextField
                      label="วันที่"
                      defaultValue={date1}
                      onChange={(e) => onSetDate(e)}
                      className={classes.textField}
                      margin="dense"
                      variant="outlined"
                      InputProps={{
                        className: classes.textColor,
                      }}
                      style={{
                        margin: 0,
                      }}
                      type="date"
                    />
                  </Grid>
                  <Grid item>
                    <NativeSelect
                      id="demo-customized-select-native"
                      value={time}
                      margin="dense"
                      onChange={handleChangeTime}
                      input={<BootstrapInput />}
                    >
                      <option value={'1'}>08:01 - 20:00</option>
                      <option value={'2'}>20:01 - 08:00</option>
                    </NativeSelect>
                  </Grid>
                  <Grid item>
                    <NativeSelect
                      id="demo-customized-select-native"
                      value={reportType}
                      margin="dense"
                      onChange={handleChangeType}
                      input={<BootstrapInput />}
                    >
                      <option value="deposit">ฝาก</option>
                      <option value="withdraw">ถอน</option>
                    </NativeSelect>
                  </Grid>
                  <Grid item>
                    <NativeSelect
                      id="demo-customized-select-native"
                      value={bankSelected}
                      margin="dense"
                      onChange={handleChangeBank}
                      input={<BootstrapInput />}
                    >
                      <option value="all">ธนาคารทั้งหมด</option>
                      {reportBankList.map((bankData) => <option key={bankData.id} value={bankData.id}>{`${bankData.bankName} ${bankData.bankId}`}</option>)}
                    </NativeSelect>
                  </Grid>
                  <Grid item>
                    <Button
                      startIcon={<SearchIcon />}
                      variant="contained"
                      style={{ backgroundColor: '#4384f3', color: 'white' }}
                      onClick={handleClickSearch}
                    >
                      ค้นหา
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      startIcon={<DownloadIcon />}
                      variant="contained"
                      style={{ backgroundColor: '#28B463 ', color: 'white' }}
                      onClick={handleClickExport}
                    >
                      Download
                    </Button>
                  </Grid>
                </Grid>
                <Grid
                  spacing={3}
                  container
                  direction="row"
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  <Grid xs={12} item>
                    {reportType === 'deposit' ? reportDepositList && (
                      <Box>
                        <ReportDepositList
                          reportDepositList={reportDepositList}
                        />
                      </Box>
                    ) : reportWithdrawList && (
                      <Box>
                        <ReportWithdrawList
                          reportWithdrawList={reportWithdrawList}
                        />
                      </Box>
                    )}
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <LoadingCircle />
            )
          ) : (
            <>
              <Box p={4} className={classes.tableRowColor}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="flex-start"
                >
                  <Typography className={classes.textColor} variant="h2">
                    {errorMessage}
                  </Typography>
                </Grid>
              </Box>
            </>
          )}

          <Grid container direction="row" justify="center" alignItems="center">
            <ExcelFile
              filename={`${config.agentId}_report_${moment().format(
                'YYYY-MM-DD'
              )}_update_${new Date().getTime()}`}
              element={
                <button ref={exportRef} style={{ display: 'none' }}>
                  Export excel
                </button>
              }
            >
              <ExcelSheet data={reportUserList} name={'User'}>
                <ExcelColumn label="รหัส" value="imiId" font />
                <ExcelColumn label="ผู้แนะนำ" value="inviter" />
                <ExcelColumn label="ยูสเซอร์ไอดี" value="username" />
                {/* <ExcelColumn label="ธนาคาร" value="bankName" /> */}
                <ExcelColumn label="ชื่อ - สกุล" value="name" />
                {/* <ExcelColumn label="เลขบัญชีธนาคาร" value="bankId" /> */}
                <ExcelColumn label="สร้างเมื่อ" value="date" />
                <ExcelColumn label="จำนวนฝาก" value="depositsLen" />
              </ExcelSheet>
              <ExcelSheet data={reportDepositList} name={'Deposit'}>
                <ExcelColumn label="เวลา" value="transactionDate" />
                <ExcelColumn label="ผู้แนะนำ" value="inviter" />
                <ExcelColumn label="รหัส" value="username" />
                <ExcelColumn label="ธนาคาร" value="bankName" />
                <ExcelColumn label="เลขบัญชี" value="bankId" />
                <ExcelColumn label="จำนวน" value="amount" />
                <ExcelColumn label="เข้าบัญชี" value="desBank" />
                <ExcelColumn label="Status" value="statusWord" />
                {/* <ExcelColumn label="จำนวนฝาก" value="depositsLen" />
                  <ExcelColumn label="ฝาก" value="depositsHistories" /> */}
              </ExcelSheet>
              <ExcelSheet data={reportWithdrawList} name={'Withdrew'}>
                <ExcelColumn label="เวลา" value="date" />
                <ExcelColumn label="จำนวน" value="amount" />
                <ExcelColumn label="รหัส" value="username" />
                <ExcelColumn label="ธนาคาร" value="bankName" />
                <ExcelColumn label="เลขบัญชี" value="bankId" />
                <ExcelColumn label="Admin" value="name" />
                <ExcelColumn label="Status" value="statusWord" />
                {/* <ExcelColumn label="จำนวนถอน" value="withdrawLen" />
                  <ExcelColumn label="ถอน" value="withdrawHistories" /> */}
              </ExcelSheet>
            </ExcelFile>
          </Grid>
        </Container>
      </Page>
    );
}

export default Report;
