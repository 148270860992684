const columnName = [
  {
    label: "ID",
    field: "id",
    sort: "asc",
    width: 200,
  },
  {
    label: "Point",
    field: "value",
    sort: "asc",
    width: 100,
  },
  {
    label: "เปอร์เซ็นออก (%)",
    field: "rate",
    sort: "asc",
    width: 150,
  },
  {
    label: "สร้างเมื่อ",
    field: "createAt",
    sort: "asc",
    width: 150,
  },
  {
    label: "Action",
    field: "action",
    sort: "asc",
    width: 150,
  },
];
export default columnName;
