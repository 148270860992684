import React, { useEffect, useState, useCallback } from 'react';
import moment from 'moment';
import {
  Box,
  Container,
  makeStyles,
  TextField,
  Typography,
  Grid,
  NativeSelect,
  Button,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../../components/Page';
import LoadingCircle from '../../../utils/LoadingCircle';
import WithdrawHistoryList from './WithdrawHistoryList';
import WithdrawRequestList from './WithdrawRequestList';
import {
  getWithdrawHistoryList,
  getWithdrawRequestList,
  transferToWithdrawBank,
  getWithdrawBankList,
  getWithdrawBankSumList
} from '../../../store/actions/widthdrawAction';
import { Navigate } from 'react-router';
import BootstrapInput from '../../../components/BootstrapInput';
import socketio from 'socket.io-client';
import configs from '../../../configs';
import ReactNotification, { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { showErrorSwal } from '../../../utils/SwalAlert';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.black,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      color: 'white',
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
    '& label.Mui-focused': {
      color: theme.palette.background.tableLine,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.background.tableLine,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.background.tableLine,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.background.tableLine,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.background.tableLine,
      },
    },
  },
  textColor: {
    color: 'white',
  },
  tableRowColor: {
    backgroundColor: theme.palette.background.grey1,
  },
}));

// const transferDeposit = () => {
//   return configs.transferToWithdrawBank.toUpperCase() === "TRUE" ? true : false;
// };

function Withdraw() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const socket = socketio(configs.actionUrl);
  const withdrawHistoryListLoading = useSelector(
    (state) => state.withdraw.withdrawHistoryListLoading
  );
  const withdrawHistoryList = useSelector(
    (state) => state.withdraw.withdrawHistoryList
  );
  const withdrawRequestListLoading = useSelector(
    (state) => state.withdraw.withdrawRequestListLoading
  );
  const withdrawRequestList = useSelector(
    (state) => state.withdraw.withdrawRequestList
  );
  const withdrawBankList = useSelector(
    (state) => state.withdraw.withdrawBankList
  );
  const withdrawBankSum = useSelector(
    (state) => state.withdraw.withdrawBankSum
  );

  const currentUser = useSelector((state) => state.auth.currentUser);
  const authLoading = useSelector((state) => state.auth.loading);
  const errorMessage = useSelector((state) => state.other.errorMessage);

  const [lastRefreshTime, setLastRefreshTime] = useState('');

  const [requestPageNum, setRequestPageNum] = useState(1);
  const [requestPerPage, setRequestPerPage] = useState('10');
  const [requestSearch, setRequestSearch] = useState('');

  const [historyPageNum, setHistoryPageNum] = useState(1);
  const [historyPerPage, setHistoryPerPage] = useState('10');
  const [historySearch, setHistorySearch] = useState('');

  const showNotification = (messageNew) => {
    store.addNotification({
      title: 'แจ้งถอน',
      message: messageNew,
      type: 'default',
      insert: 'top',
      container: 'top-right',
      animationIn: ['animate__animated', 'animate__fadeIn'],
      animationOut: ['animate__animated', 'animate__fadeOut'],
      dismiss: {
        duration: 5000,
        onScreen: true,
      },
    });
  };

  const newMessage = useCallback(
    (messageNew) => {
      const audio = new Audio('/static/sound/note.mp3');
      getWithdrawRequestList(dispatch);
      audio.play();
      showNotification(messageNew);
    },
    [dispatch]
  );

  useEffect(() => {
    socket.on('new-message', newMessage);

    return () => {
      socket.disconnect();
    };
  }, [socket, newMessage]);

  useEffect(() => {
    getWithdrawHistoryList(dispatch);
    getWithdrawRequestList(dispatch);
    getWithdrawBankList(dispatch);
    getWithdrawBankSumList(dispatch);

    setLastRefreshTime(moment(Date.now()).format('H:mm:ss'));

    const interval = setInterval(() => {
      getWithdrawHistoryList(dispatch);
      getWithdrawRequestList(dispatch);
      getWithdrawBankList(dispatch);
      getWithdrawBankSumList(dispatch);

      setLastRefreshTime(moment(Date.now()).format('H:mm:ss'));
    }, 60000);

    return () => clearInterval(interval);
  }, [dispatch]);

  const onRequestSearch = (e) => {
    setRequestSearch(e.target.value);
    setRequestPageNum(1);
  };
  const handleChangeRequestPerPage = (event) => {
    setRequestPerPage(event.target.value);
  };
  const handleRequestChangePage = (e, value) => {
    setRequestPageNum(value);
  };
  const requestSearchFilter = (searchStr) => {
    if (searchStr) {
      return (
        searchStr
          .toString()
          .toLowerCase()
          .indexOf(requestSearch.toString().toLowerCase()) !== -1
      );
    }
  };
  var withdrawRequestListFilter = withdrawRequestList?.filter(
    (withdrawRequestData) => {
      return (
        requestSearchFilter(
          moment(withdrawRequestData.createdAt).format('DD/MM/YYYY H:mm')
        ) ||
        requestSearchFilter(withdrawRequestData?.amount) ||
        requestSearchFilter(withdrawRequestData?.user?.user_pass?.username) ||
        requestSearchFilter(withdrawRequestData?.user?.user_bank?.bankName) ||
        requestSearchFilter(withdrawRequestData?.user?.user_bank?.bankId)
      );
    }
  );

  const onHistorySearch = (e) => {
    setHistorySearch(e.target.value);
    setHistoryPageNum(1);
  };
  const handleHistoryChangePerPage = (event) => {
    setHistoryPerPage(event.target.value);
  };
  const handleHistoryChangePage = (e, value) => {
    setHistoryPageNum(value);
  };
  const historySearchFilter = (searchStr) => {
    if (searchStr) {
      return (
        searchStr
          .toString()
          .toLowerCase()
          .indexOf(historySearch.toString().toLowerCase()) !== -1
      );
    }
  };
  var withdrawHistoryListFilter = withdrawHistoryList?.filter(
    (withdrawHistoryData) => {
      return (
        historySearchFilter(
          moment(withdrawHistoryData?.createdAt).format('DD/MM/YYYY H:mm')
        ) ||
        historySearchFilter(withdrawHistoryData?.amount) ||
        historySearchFilter(withdrawHistoryData?.user?.user_pass?.username) ||
        historySearchFilter(withdrawHistoryData?.user?.user_bank?.bankName) ||
        historySearchFilter(withdrawHistoryData?.user?.user_bank?.bankId)
      );
    }
  );

  const onTransfer = () => {
    var amount = parseInt(window.prompt('กรอกจำนวนที่ต้องการโยกเงิน', 0));
    if (
      amount === null ||
      amount === '' ||
      amount === 0 ||
      !(typeof amount === 'number' && Math.floor(amount) === amount)
    ) {
      showErrorSwal('กรุณากรอกจำนวนเงินให้ถูกต้อง');
    } else {
      transferToWithdrawBank(dispatch, amount);
    }
  };

  if (!authLoading && !currentUser) return <Navigate to="/login" />;
  else
    return (
      <Page className={classes.root} title="ถอน">
        <ReactNotification />
        <Container>
          {!errorMessage ? (
            <>
              {!withdrawRequestListLoading ? (
                <>
                  <Typography
                    className={classes.textColor}
                    style={{ marginBottom: 16 }}
                    variant="h2"
                  >
                    รายการแจ้งถอน
                  </Typography>
                  <Box
                    borderRadius="borderRadius"
                    boxShadow={3}
                    p={2}
                    className={classes.tableRowColor}
                  >
                    <Grid
                      container
                      spacing={2}
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      style={{ marginBottom: 16 }}
                    >
                      <Grid md={6} item>
                        <Typography className={classes.textColor} variant="h4">
                          เวลาอัพเดทล่าสุด :{' '}
                          <span style={{ color: 'blueviolet' }}>
                            {lastRefreshTime}
                          </span>
                        </Typography>
                      </Grid>
                      {currentUser.lv === 0 && configs.transferToWithdrawBank && (
                        <Grid container justify="flex-end" md={6} item>
                          <Button
                            size="large"
                            variant="contained"
                            onClick={onTransfer}
                          >
                            โยกเงิน
                          </Button>
                        </Grid>
                      )}
                    </Grid>

                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      style={{ marginBottom: 30 }}
                    >
                      <Grid
                        container
                        spacing={2}
                        direction="column"
                        justify="flex-center"
                        alignItems="flex-start"
                        md={6}
                        style={{ marginBottom: 16 }}
                      >
                        <Grid md={6} item>
                          <Typography
                            className={classes.textColor}
                            variant="h4"
                          >
                            ยอดเงินคงเหลือ
                          </Typography>
                        </Grid>
                        {withdrawBankList.map((withdrawBank) =>
                          <Grid md={6} item>
                            <Typography
                              className={classes.textColor}
                              variant="h4"
                            >
                              {`${withdrawBank?.bankName} ${withdrawBank?.bankId}`} :{' '}
                              <span style={{ color: 'red' }}>
                                {(+withdrawBank?.balance)?.toLocaleString('en')}
                              </span>
                            </Typography>
                          </Grid>)}
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        direction="column"
                        justify="flex-center"
                        alignItems="flex-end"
                        md={6}
                        style={{ marginBottom: 16 }}
                      >
                        <Grid md={6} item>
                          <Typography
                            className={classes.textColor}
                            variant="h4"
                          >
                            ยอดถอนวันนี้
                          </Typography>
                        </Grid>
                        {withdrawBankSum.map((withdrawBank) =>
                          <Grid md={6} item>
                            <Typography
                              className={classes.textColor}
                              variant="h4"
                            >
                              {`${withdrawBank?.bank?.bankName} ${withdrawBank?.bank?.bankId}`} :{' '}
                              <span style={{ color: 'red' }}>
                                {(+withdrawBank?.sum_amount)?.toLocaleString('en')}
                              </span>
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                    {withdrawRequestList?.length >= 1 ? (
                      <>
                        <Grid
                          container
                          direction="row"
                          justify="space-between"
                          alignItems="center"
                          style={{ marginBottom: 16 }}
                        >
                          <TextField
                            label="ค้นหาในตาราง"
                            onChange={(e) => onRequestSearch(e)}
                            className={classes.textField}
                            margin="dense"
                            variant="outlined"
                            InputProps={{
                              className: classes.textColor,
                            }}
                            style={{
                              margin: 0,
                            }}
                          />
                          <NativeSelect
                            id="demo-customized-select-native"
                            value={requestPerPage}
                            margin="dense"
                            onChange={handleChangeRequestPerPage}
                            input={<BootstrapInput />}
                          >
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                          </NativeSelect>
                        </Grid>
                        <Box>
                          <WithdrawRequestList
                            withdrawRequestDataList={withdrawRequestListFilter}
                            withdrawBankList={withdrawBankList}
                            search={requestSearch}
                            perPage={requestPerPage}
                            pageNum={requestPageNum}
                          />
                        </Box>
                        <Box mt={3} display="flex" justifyContent="center">
                          <Pagination
                            color="secondary"
                            className={classes.pagination}
                            count={Math.ceil(
                              withdrawRequestListFilter?.length / historyPerPage
                            )}
                            size="large"
                            onChange={handleRequestChangePage}
                            variant="outlined"
                            shape="rounded"
                          />
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box p={4} className={classes.tableRowColor}>
                          <Grid
                            container
                            direction="row"
                            justify="center"
                            alignItems="center"
                          >
                            <Typography
                              className={classes.textColor}
                              variant="h2"
                            >
                              ไม่พบข้อมูลรายการแจ้งถอน
                            </Typography>
                          </Grid>
                        </Box>
                      </>
                    )}
                  </Box>
                </>
              ) : (
                <LoadingCircle />
              )}

              {!withdrawHistoryListLoading ? (
                <>
                  <Typography
                    className={classes.textColor}
                    style={{ marginBottom: 16 }}
                    variant="h2"
                  ></Typography>
                  {withdrawHistoryList?.length >= 1 ? (
                    <Box
                      borderRadius="borderRadius"
                      boxShadow={3}
                      p={2}
                      className={classes.tableRowColor}
                    >
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        style={{ marginBottom: 16 }}
                      >
                        <TextField
                          label="ค้นหาในตาราง"
                          onChange={(e) => onHistorySearch(e)}
                          className={classes.textField}
                          margin="dense"
                          variant="outlined"
                          InputProps={{
                            className: classes.textColor,
                          }}
                          style={{
                            margin: 0,
                          }}
                        />
                        <NativeSelect
                          id="demo-customized-select-native"
                          value={historyPerPage}
                          margin="dense"
                          onChange={handleHistoryChangePerPage}
                          input={<BootstrapInput />}
                        >
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </NativeSelect>
                      </Grid>
                      <Box>
                        <WithdrawHistoryList
                          withdrawDataList={withdrawHistoryListFilter}
                          search={historySearch}
                          perPage={historyPerPage}
                          pageNum={historyPageNum}
                        />
                      </Box>
                      <Box mt={3} display="flex" justifyContent="center">
                        <Pagination
                          color="secondary"
                          className={classes.pagination}
                          count={Math.ceil(
                            withdrawHistoryListFilter?.length / historyPerPage
                          )}
                          size="large"
                          onChange={handleHistoryChangePage}
                          variant="outlined"
                          shape="rounded"
                        />
                      </Box>
                    </Box>
                  ) : (
                    <>
                      <Box p={4} className={classes.tableRowColor}>
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          <Typography
                            className={classes.textColor}
                            variant="h2"
                          >
                            ไม่พบข้อมูลรายการประวัติการถอน
                          </Typography>
                        </Grid>
                      </Box>
                    </>
                  )}
                </>
              ) : (
                <LoadingCircle />
              )}
            </>
          ) : (
            <>
              <Box p={4} className={classes.tableRowColor}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Typography className={classes.textColor} variant="h2">
                    {errorMessage}
                  </Typography>
                </Grid>
              </Box>
            </>
          )}
        </Container>
      </Page>
    );
}

export default Withdraw;
