const columnName = [
  {
    label: "หัวเรื่อง",
    field: "title",
    sort: "asc",
    width: 200,
  },
  {
    label: "ปรเภท",
    field: "category",
    sort: "asc",
    width: 100,
  },
  {
    label: "รายละเอียด",
    field: "detail",
    sort: "asc",
    width: 150,
  },
  {
    label: "พอยท์",
    field: "price",
    sort: "asc",
    width: 200,
  },
  {
    label: "เครดิต",
    field: "amount",
    sort: "asc",
    width: 150,
  },
  {
    label: "สร้างเมื่อ",
    field: "createAt",
    sort: "asc",
    width: 150,
  },
  {
    label: "Actions",
    field: "actions",
    sort: "asc",
    width: 150,
  },
];
export default columnName;
