import { combineReducers } from "redux";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import depositReducer from "./depositReducer";
import withdrawReducer from "./withdrawReducer";
import adminReducer from "./adminReducer";
import logReducer from "./logReducer";
import rewardReducer from "./rewardReducer";
import settingReducer from "./settingReducer";
import dashboardReducer from "./dashboardReducer";
import reportReducer from "./reportReducer";
import bankReducer from "./bankReducer";
import otherReducer from "./otherReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  deposit: depositReducer,
  withdraw: withdrawReducer,
  admin: adminReducer,
  log: logReducer,
  reward: rewardReducer,
  setting: settingReducer,
  dashboard: dashboardReducer,
  report: reportReducer,
  bank: bankReducer,
  other: otherReducer,
});

export default rootReducer;
