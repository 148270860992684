const columnName = [
  {
    label: "เวลา",
    field: "date",
    sort: "asc",
    width: 200,
  },
  {
    label: "action",
    field: "action",
    sort: "asc",
    width: 100,
  },
  {
    label: "note",
    field: "note",
    sort: "asc",
    width: 150,
  },
  {
    label: "ก่อน",
    field: "before",
    sort: "asc",
    width: 200,
  },
  {
    label: "หลัง",
    field: "after",
    sort: "asc",
    width: 150,
  },
  {
    label: "ชื่อแก้ไข",
    field: "name",
    sort: "asc",
    width: 100,
  },
];
export default columnName;
