import Axios from "../../utils/axiosConfig";
import { showErrorSwal, showSuccessSwal } from '../../utils/SwalAlert';

export const getSettingDetail = (dispatch) => {
  Axios.get("/secure/setting").then((res) => {
    if (res.data.success) {
      dispatch({ type: "clearError" });
      dispatch({ type: "getSettingDetail", res });
    } else {
      dispatch({ type: "onError", res });
    }
  });
};

export const getRewardSettingList = (dispatch) => {
  Axios.get("/secure/reward").then((res) => {
    if (res.data.success) {
      dispatch({ type: "clearError" });
      dispatch({ type: "getRewardSettingList", res });
    } else {
      dispatch({ type: "onError", res });
    }
  });
};

export const addRewardSetting = (dispatch, values) => {
  var body;
  if (values.categoryId === "1")
    body = {
      ...values,
      img:
        "https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcSKYJsS1Y9qSS7xrdcMJDFQb6JFPEjbnvZ9XnvoTzI4xGVc8gBj&usqp=CAU",
    };
  else if (values.categoryId === "2")
    body = {
      ...values,
      amount: 0,
    };

  Axios.post("/secure/reward", body).then((res) => {
    if (res.data.success) {
      getRewardSettingList(dispatch);
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};

export const editSettingDetail = (dispatch, values, id) => {
  var body = { ...values, id: id, bonusRate: values.bonusRate };
  Axios.patch("/secure/setting", body).then((res) => {
    if (res.data.success) {
      getSettingDetail(dispatch);
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};

export const editGeneralSetting = (dispatch, values, id) => {
  var body = { ...values, id: id };
  Axios.patch("/secure/setting/general", body).then((res) => {
    if (res.data.success) {
      getSettingDetail(dispatch);
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};

export const editDepositBonusSetting = (dispatch, values, id) => {
  var body = { ...values, id: id };
  Axios.patch("/secure/setting/deposit-bonus", body).then((res) => {
    if (res.data.success) {
      getSettingDetail(dispatch);
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};

export const editBonusSetting = (dispatch, values, id) => {
  var body = { ...values, id: id };
  Axios.patch("/secure/setting/bonus", body).then((res) => {
    if (res.data.success) {
      getSettingDetail(dispatch);
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};

export const editBonusDetailSetting1 = (dispatch, values, id) => {
  var body = { ...values, id: id };
  Axios.patch("/secure/setting/bonus-detail-1", body).then((res) => {
    if (res.data.success) {
      getSettingDetail(dispatch);
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};

export const editBonusDetailSetting2 = (dispatch, values, id) => {
  var body = { ...values, id: id };
  Axios.patch("/secure/setting/bonus-detail-2", body).then((res) => {
    if (res.data.success) {
      getSettingDetail(dispatch);
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};

export const editRewardSetting = (dispatch, values, id) => {
  var body = { ...values, id: id };
  Axios.patch("/secure/reward", body).then((res) => {
    if (res.data.success) {
      getRewardSettingList(dispatch);
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};

export const deleteRewardSetting = (dispatch, id) => {
  Axios.delete(`/secure/reward/${id}`).then((res) => {
    // alert(res.data.message);
    if (res.data.success) {
      getRewardSettingList(dispatch);
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};

export const getWheelSettingList = (dispatch) => {
  Axios.get("/secure/wheel").then((res) => {
    if (res.data.success) {
      dispatch({ type: "clearError" });
      dispatch({ type: "getWheelSettingList", res });
    } else {
      dispatch({ type: "onError", res });
    }
  });
};

export const addWheelSetting = (dispatch, values) => {
  var body = { ...values };
  Axios.post("/secure/wheel", body).then((res) => {
    if (res.data.success) {
      getWheelSettingList(dispatch);
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};

export const editWheelSetting = (dispatch, values, id) => {
  var body = { ...values, id: id };
  Axios.patch("/secure/wheel", body).then((res) => {
    if (res.data.success) {
      getWheelSettingList(dispatch);
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};
