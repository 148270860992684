const depositColumnName = [
  {
    label: "#",
    field: "id",
    sort: "asc",
    width: 200,
  },
  {
    label: "วันเวลา",
    field: "createAt",
    sort: "asc",
    width: 100,
  },
  {
    label: "จำนวน (หน้าขาว)",
    field: "amount",
    sort: "asc",
    width: 150,
  },
  {
    label: "จำนวน (หน้าม่วง)",
    field: "amount",
    sort: "asc",
    width: 150,
  },
];
export default depositColumnName;
