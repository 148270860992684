const columnName = [
  {
    label: "ลำดับ",
    field: "key",
    sort: "asc",
    width: 200,
  },
  {
    label: "ธนาคาร",
    field: "bankTitle",
    sort: "asc",
    width: 100,
  },
  {
    label: "ชื่อบัญชี",
    field: "bank",
    sort: "asc",
    width: 150,
  },
  {
    label: "เลขบัญชี",
    field: "bankId",
    sort: "asc",
    width: 200,
  },
  {
    label: "ประเภท",
    field: "bankType",
    sort: "asc",
    width: 200,
  },
  {
    label: "สถานะ",
    field: "active",
    sort: "asc",
    width: 200,
  },
  {
    label: "สร้างเมื่อ",
    field: "createdAt",
    sort: "asc",
    width: 200,
  },
  {
    label: "Actions",
    field: "actions",
    sort: "asc",
    width: 200,
  },
];
export default columnName;
