import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { addNotFoundDeposit } from "../../../store/actions/depositAction";
import config from "../../../configs";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.black,
  },
  textColor: {
    color: "white",
  },
  textFieldColor: {
    color: "#4285f4",
    backgroundColor: theme.palette.background.grey1,
  },
}));

const AddNotFoundDeposit = ({ className, depositId, endData, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const AddNotFoundDepositSchema = Yup.object().shape({
    username: Yup.string()
      .min(9, "กรุณากรอกข้อมูลรหัสให้ถูกต้อง")
      .max(20)
      .required("กรุณากรอกรหัส"),
  });

  const body = (
    <Formik
      validationSchema={AddNotFoundDepositSchema} //กำหนด validation
      initialValues={{
        //กำหนด initialValues
        username: config.agentId,
      }}
      onSubmit={(values) => {
        addNotFoundDeposit(dispatch, values, depositId, endData);
        handleClose();
      }}
    >
      {({ errors, touched, values, handleBlur, handleChange }) => (
        <Form>
          <Box mt={3} mb={3}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Typography className={classes.textColor} variant="h3">
                เติม Not Found
              </Typography>
            </Grid>
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              รหัส
            </Typography>
            <TextField
              error={Boolean(touched.username && errors.username)}
              fullWidth
              size="small"
              helperText={touched.username && errors.username}
              margin="normal"
              name="username"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.username}
              variant="outlined"
              InputProps={{
                className: classes.textFieldColor,
              }}
              style={{ marginTop: 8 }}
            />
          </Box>

          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ marginBottom: 16 }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              size="large"
              style={{ backgroundColor: "#B23CFD", color: "white" }}
            >
              ปิด
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              style={{ backgroundColor: "#4285f4", color: "white" }}
            >
              บันทึก
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );

  return (
    <div>
      <Button
        size="large"
        variant="contained"
        style={{ backgroundColor: "#ffa100", color: "white" }}
        onClick={handleClickOpen}
      >
        Not found
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent className={classes.paper}>{body}</DialogContent>
      </Dialog>
    </div>
  );
};

AddNotFoundDeposit.propTypes = {
  className: PropTypes.string,
  depositId: PropTypes.number,
  endData: PropTypes.number,
};

export default AddNotFoundDeposit;
