import React from "react";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Grid,
  Button,
  IconButton,
} from "@material-ui/core";
import {
  Person as UserIcon,
  Edit as EditIcon,
  AccountBalanceWallet as BankIcon,
  VpnKey as ChangePasswordIcon,
} from "@material-ui/icons";
import columnName from "./columnName";
import EditUserDetail from "./EditUserDetail";
import EditUserBank from "./EditUserBank";
import ChangeUserPassword from "./ChangeUserPassword";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.black,
  },
  table: {
    "& .MuiTableSortLabel-root": {
      color: "white",
    },
    "& .MuiTableCell-root": {
      color: "white",
      borderWidth: 1,
      borderColor: theme.palette.background.tableLine,
      borderStyle: "solid",
      padding: 8,
    },
  },
  tableRowColor1: {
    backgroundColor: theme.palette.background.grey1,
  },
  tableRowColor2: {
    backgroundColor: theme.palette.background.grey2,
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const TableHeadCellComponent = ({ name }) => (
  <TableCell align="center">{name}</TableCell>
);

const TableBodyRowComponent = ({ userData, currentUser, classNameStyle }) => (
  <TableRow className={classNameStyle}>
    <TableCell align="center">
      <Grid container direction="row" justify="center" alignItems="center">
        {userData?.user_pass?.username}
        {currentUser.rUser ? (
          <IconButton
            onClick={() =>
              window.open(`/admin/userdetail/${userData?.user_pass?.username}`)
            }
            size="small"
            style={{ marginLeft: 10, color: "#33b5e5" }}
          >
            <UserIcon />
          </IconButton>
        ) : (
          <IconButton style={{ marginLeft: 10, color: "#33b5e5" }} disabled>
            <UserIcon />
          </IconButton>
        )}
      </Grid>
    </TableCell>
    <TableCell align="center">{userData?.inviter}</TableCell>
    <TableCell align="center">{userData?.name}</TableCell>
    <TableCell align="center">{userData?.user_auth?.username}</TableCell>
    <TableCell align="center">{userData?.user_bank?.bankName}</TableCell>
    <TableCell align="center">{userData?.user_bank?.bankId}</TableCell>
    <TableCell align="center">
      {moment(userData?.createdAt).format("DD/MM/YYYY H:mm")}
    </TableCell>
    <TableCell align="center">
      <Grid
        spacing={1}
        container
        direction="row"
        justify="space-around"
        alignItems="center"
      >
        <Grid item>
          {currentUser.wUser ? (
            <EditUserDetail userData={userData} />
          ) : (
            <Button variant="contained" style={{ color: "white" }} disabled>
              <EditIcon />
            </Button>
          )}
        </Grid>
        <Grid item>
          {currentUser.wUserBankId ? (
            <EditUserBank userData={userData} />
          ) : (
            <Button variant="contained" style={{ color: "white" }} disabled>
              <BankIcon />
            </Button>
          )}
        </Grid>
        <Grid item>
          {currentUser.wUser ? (
            <ChangeUserPassword userData={userData} />
          ) : (
            <Button variant="contained" style={{ color: "white" }} disabled>
              <ChangePasswordIcon />
            </Button>
          )}
        </Grid>
      </Grid>
    </TableCell>
  </TableRow>
);

const UserList = ({
  className,
  userDataList,
  search,
  pageNum,
  perPage,
  ...rest
}) => {
  const classes = useStyles();
  const currentUser = useSelector((state) => state.auth.currentUser);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box style={{ backgroundColor: "#000" }} minWidth={800}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRowColor1}>
                {columnName.map((column, index) =>
                  column.sort ? (
                    <TableHeadCellComponent key={index} name={column.label} />
                  ) : (
                    <TableHeadCellComponent key={index} name={column.label} />
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {userDataList
                .slice((pageNum - 1) * perPage, pageNum * perPage)
                .map((userData, index) => (
                  <TableBodyRowComponent
                    classNameStyle={
                      index % 2
                        ? `${classes.tableRowColor1}`
                        : `${classes.tableRowColor2}`
                    }
                    currentUser={currentUser}
                    key={index}
                    userData={userData}
                  />
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

UserList.propTypes = {
  className: PropTypes.string,
  userDataList: PropTypes.array,
  search: PropTypes.string,
  pageNum: PropTypes.number,
  perPage: PropTypes.string,
};

export default UserList;
