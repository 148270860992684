import React from "react";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Grid,
  Button,
  IconButton,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import requestColumnName from "./requestColumnName";
import {
  cancelWithRefundWithdraw,
  cancelWithNoRefundWithdraw,
  confirmTransactionWithdraw,
  addManualWithdraw,
  withdrawAutoBank,
} from "../../../store/actions/widthdrawAction";
import { Person as UserIcon } from "@material-ui/icons";
import LoadingCircle from "../../../utils/LoadingCircle";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.black,
  },
  table: {
    "& .MuiTableSortLabel-root": {
      color: "white",
    },
    "& .MuiTableCell-root": {
      color: "white",
      borderWidth: 1,
      borderColor: theme.palette.background.tableLine,
      borderStyle: "solid",
      padding: 8,
    },
  },
  tableRowColor1: {
    backgroundColor: theme.palette.background.grey1,
  },
  tableRowColor2: {
    backgroundColor: theme.palette.background.grey2,
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const onCancelWithRefundWithdraw = (dispatch, uuid) => () => {
  if (window.confirm("คุณต้องการยกเลิกหรือไม่")) {
    dispatch({ type: "onLoading" });
    cancelWithRefundWithdraw(dispatch, uuid);
  }
};

const onCancelWithNoRefundWithdraw = (dispatch, uuid) => () => {
  if (window.confirm("คุณต้องการยกเลิกหรือไม่")) {
    dispatch({ type: "onLoading" });
    cancelWithNoRefundWithdraw(dispatch, uuid);
  }
};

const onConfirmTransactionWithdraw = (dispatch, uuid) => () => {
  if (window.confirm("ยืนยันเงินลดหรือไม่")) {
    dispatch({ type: "onLoading" });
    confirmTransactionWithdraw(dispatch, uuid);
  }
};

const onAddManualWithdraw = (dispatch, uuid) => () => {
  if (window.confirm("ยืนยันถอนมือหรือไม่")) {
    dispatch({ type: "onLoading" });
    addManualWithdraw(dispatch, uuid);
  }
};

const onWithdrawAutoBank = (dispatch, withdrawId, bankid) => () => {
  if (window.confirm("ยืนยันการถอน โปรดเช็คยอดเงินก่อนและหลังถอนทุกครั้ง")) {
    dispatch({ type: "onLoading" });
    withdrawAutoBank(dispatch, withdrawId, bankid);
  }
};

const BtnWithdrawStatus = (
  dispatch,
  status,
  withdrawId,
  withdrawBankList
) => {

  switch (status) {
    case 0:
      return (
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
        >
          <Grid item>
            <span className="text-danger">แจ้งถอนล้มเหลว</span>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              style={{ backgroundColor: "#ff3547", color: "white" }}
              onClick={onCancelWithNoRefundWithdraw(dispatch, withdrawId)}
            >
              ยกเลิก(ไม่คืนเงิน)
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              style={{ backgroundColor: "#ff3547", color: "white" }}
              onClick={onCancelWithRefundWithdraw(dispatch, withdrawId)}
            >
              ยกเลิก(คืนเงิน)
            </Button>
          </Grid>
        </Grid>
      );
    case 1:
      return (
        <Grid
          spacing={1}
          container
          direction="row"
          justify="space-around"
          alignItems="center"
        >
          {withdrawBankList.map((withdrawBank) =>
            <Grid item>
              <Button
                variant="contained"
                style={{ backgroundColor: withdrawBank.bankName === 'SCB' ? "#A6C" : "#00a950", color: "white" }}
                onClick={onWithdrawAutoBank(dispatch, withdrawId, withdrawBank.id)}
              >
                {`${withdrawBank?.bankName} ${withdrawBank?.bankId}`}
              </Button>
            </Grid>)}
          <Grid item>
            <Button
              variant="contained"
              style={{ backgroundColor: "#4285f4", color: "white" }}
              onClick={onAddManualWithdraw(dispatch, withdrawId)}
            >
              ถอนมือ
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              style={{ backgroundColor: "#ff3547", color: "white" }}
              onClick={onCancelWithRefundWithdraw(dispatch, withdrawId)}
            >
              ยกเลิก
            </Button>
          </Grid>
        </Grid>
      );
    case 3:
    case 4:
    case 5:
      return (
        <Grid
          container
          direction="row"
          justify="space-around"
          alignItems="center"
        >
          <Grid item>
            <Button
              variant="contained"
              style={{ backgroundColor: "#000", color: "white" }}
              onClick={onConfirmTransactionWithdraw(dispatch, withdrawId)}
            >
              ยืนยันเงินลด
            </Button>
          </Grid>
          {withdrawBankList.map((withdrawBank) =>
            <Grid item>
              <Button
                variant="contained"
                style={{ backgroundColor: withdrawBank.bankName === 'SCB' ? "#A6C" : "#00a950", color: "white" }}
                onClick={onWithdrawAutoBank(dispatch, withdrawId, withdrawBank.id)}
              >
                {`${withdrawBank?.bankName} ${withdrawBank?.bankId}`}
              </Button>
            </Grid>)}
          <Grid item>
            <Button
              variant="contained"
              style={{ backgroundColor: "#4285f4", color: "white" }}
              onClick={onAddManualWithdraw(dispatch, withdrawId)}
            >
              ถอนมือ
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              style={{ backgroundColor: "#ff3547", color: "white" }}
              onClick={onCancelWithRefundWithdraw(dispatch, withdrawId)}
            >
              ยกเลิก
            </Button>
          </Grid>
        </Grid>
      );
    case 10:
      return <span className="text-warnig">กำลังทำรายการ</span>;

    default:
      return <span className="text-danger">แจ้งถอนล้มเหลว</span>;
  }
};

const TableHeadCellComponent = ({ name }) => (
  <TableCell align="center">{name}</TableCell>
);

const TableBodyRowComponent = ({
  dispatch,
  withdrawData,
  withdrawBankList,
  currentUser,
  classNameStyle
}) => (
  <TableRow className={classNameStyle}>
    <TableCell align="center">
      {moment(withdrawData?.createdAt)?.format("DD/MM/YYYY H:mm")}
    </TableCell>
    <TableCell align="center">
      {withdrawData?.user?.deposits[
        withdrawData?.user?.deposits?.length - 1
      ]?.amount?.toLocaleString("en")}
    </TableCell>
    <TableCell align="center">
      {withdrawData?.amount?.toLocaleString("en")}
    </TableCell>
    <TableCell align="center">
      <Grid container direction="row" justify="center" alignItems="center">
        {withdrawData?.user?.user_pass?.username}
        <IconButton
          onClick={() =>
            window.open(
              `/admin/userdetail/${withdrawData?.user?.user_pass?.username}`
            )
          }
          size="small"
          style={{ marginLeft: 10, color: "#33b5e5" }}
        >
          <UserIcon />
        </IconButton>
      </Grid>
    </TableCell>
    <TableCell align="center">
      {withdrawData?.user?.user_bank?.bankName}
    </TableCell>
    <TableCell align="center">
      {withdrawData?.user?.user_bank?.bankId}
    </TableCell>
    <TableCell align="center">
      {currentUser.isWithdraw &&
        BtnWithdrawStatus(
          dispatch,
          withdrawData.status,
          withdrawData.uuid,
          withdrawBankList
        )}
    </TableCell>
  </TableRow>
);

const WithdrawRequestList = ({
  className,
  withdrawRequestDataList,
  withdrawBankList,
  search,
  pageNum,
  perPage,
  ...rest
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const onLoading = useSelector((state) => state.other.loading);
  const currentUser = useSelector((state) => state.auth.currentUser);

  if (onLoading) return <LoadingCircle />;
  else
    return (
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box style={{ backgroundColor: "#000" }} minWidth={800}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.tableRowColor1}>
                  {requestColumnName.map((column, index) =>
                    column.sort ? (
                      <TableHeadCellComponent key={index} name={column.label} />
                    ) : (
                      <TableHeadCellComponent key={index} name={column.label} />
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {withdrawRequestDataList
                  .slice((pageNum - 1) * perPage, pageNum * perPage)
                  .map((withdrawData, index) => (
                    <TableBodyRowComponent
                      classNameStyle={
                        index % 2
                          ? `${classes.tableRowColor1}`
                          : `${classes.tableRowColor2}`
                      }
                      dispatch={dispatch}
                      key={index}
                      withdrawData={withdrawData}
                      currentUser={currentUser}
                      withdrawBankList={withdrawBankList}
                    />
                  ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    );
};

WithdrawRequestList.propTypes = {
  className: PropTypes.string,
  withdrawRequestDataList: PropTypes.array,
  search: PropTypes.string,
  pageNum: PropTypes.number,
  perPage: PropTypes.string,
};

export default WithdrawRequestList;
