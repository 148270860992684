import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import NavItem from "./NavItem";
import config from "../../../configs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { signOut } from "../../../store/actions/authActions";

const user = {
  avatar: "/static/images/avatars/avatar_6.png",
};

const settingMenu = [
  {
    href: "/admin/setting",
    title: "ตั้งค่าทั่วไป",
  },
  {
    href: "/admin/setting/wheel",
    title: "ตั้งค่าวงล้อ",
  },
  {
    href: "/admin/setting/reward",
    title: "ตั้งค่าของรางวัล",
  },
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const onSignOut = () => {
    signOut(dispatch);
    navigate("/login");
  };

  const routeLink = config.navBarRoutes.filter(
    (route) =>
      (currentUser?.rReport && route.permission === "rReport") ||
      (currentUser?.rUser && route.permission === "rUser") ||
      (currentUser?.rDeposit && route.permission === "rDeposit") ||
      (currentUser?.rWithdraw && route.permission === "rWithdraw") ||
      (currentUser?.rxReward && route.permission === "rxReward") ||
      (currentUser?.rAdmin && route.permission === "rAdmin") ||
      (currentUser?.lv === 0 && route.permission === "")
  );

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          // component={RouterLink}
          src={user.avatar}
          // to="/app/account"
        />
        <Typography style={{ marginTop: 5 }} color="textPrimary" variant="h4">
          {currentUser?.name}
        </Typography>
        <Typography
          component={Button}
          onClick={onSignOut}
          style={{ marginTop: 5, color: "red" }}
          variant="h5"
        >
          ออกจากระบบ
        </Typography>
      </Box>
      <Divider />
      {currentUser?.lv === 0 && (
        <>
          <Box p={2}>
            <List>
              {settingMenu.map((route) => (
                <NavItem
                  href={route.href}
                  key={route.title}
                  title={route.title}
                  // icon={item.icon}
                />
              ))}
            </List>
          </Box>
          <Divider />
        </>
      )}
      <Box p={2}>
        <List>
          {routeLink.map((route) => (
            <NavItem
              href={route.href}
              key={route.title}
              title={route.title}
              // icon={item.icon}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  return (
    <>
      <Hidden mdUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      {/* <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden> */}
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
