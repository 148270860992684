const initState = {
  errorMessage: null,
  loading: false,
};

const otherReducer = (state = initState, action) => {
  switch (action.type) {
    case "clearError":
      return {
        ...state,
        errorMessage: null,
      };
    case "onError":
      return {
        ...state,
        errorMessage: action.res.data.message,
      };
    case "onLoading":
      return {
        ...state,
        loading: true,
        errorMessage: null,
      };
    case "onFinish":
      return {
        ...state,
        loading: false,
        errorMessage: null,
      };
    default:
      return state;
  }
};
export default otherReducer;
