import React from "react";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Grid,
  Button,
  IconButton,
} from "@material-ui/core";
import columnName from "./columnName";
import { useDispatch, useSelector } from "react-redux";
import {
  pullDeposit,
  addFailDeposit,
  addTimeoutDeposit,
} from "../../../store/actions/depositAction";
import AddNotFoundDeposit from "./AddNotFoundDeposit";
import { Person as UserIcon } from "@material-ui/icons";
import LoadingCircle from "../../../utils/LoadingCircle";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.black,
  },
  table: {
    "& .MuiTableSortLabel-root": {
      color: "white",
    },
    "& .MuiTableCell-root": {
      color: "white",
      borderWidth: 1,
      borderColor: theme.palette.background.tableLine,
      borderStyle: "solid",
      padding: 8,
    },
  },
  tableRowColor1: {
    backgroundColor: theme.palette.background.grey1,
  },
  tableRowColor2: {
    backgroundColor: theme.palette.background.grey2,
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const TableHeadCellComponent = ({ name }) => (
  <TableCell align="center">{name}</TableCell>
);

const onAddFailDeposit = (dispatch, id, endData) => () => {
  if (window.confirm("คุณต้องการเติมเงินหรือไม่")) {
    dispatch({ type: "onLoading" });
    addFailDeposit(dispatch, id, endData);
  }
};

const onAddTimeoutDeposit = (dispatch, id, endData) => () => {
  if (window.confirm("คุณต้องการเติมเงินหรือไม่")) {
    dispatch({ type: "onLoading" });
    addTimeoutDeposit(dispatch, id, endData);
  }
};

const BtnStatus = (dispatch, status, id, isDeposit, endData) => {
  switch (status) {
    case 0:
      if (isDeposit)
        return (
          <Button
            style={{ backgroundColor: "#ff3547", color: "white" }}
            size="large"
            variant="contained"
            onClick={onAddFailDeposit(dispatch, id, endData)}
          >
            ล้มเหลว
          </Button>
        );
      else
        return (
          <Button
            style={{ color: "white" }}
            size="large"
            variant="contained"
            disabled
          >
            ล้มเหลว
          </Button>
        );

    case 1:
      return (
        <Button
          style={{ backgroundColor: "#00B74A", color: "white" }}
          size="large"
          variant="contained"
        >
          สำเร็จ
        </Button>
      );

    case 3:
      if (isDeposit)
        return <AddNotFoundDeposit depositId={id} endData={endData} />;
      else
        return (
          <Button
            style={{ color: "white" }}
            size="large"
            variant="contained"
            disabled
          >
            Not found
          </Button>
        );

    case 4:
      if (isDeposit)
        return (
          <Button
            style={{ backgroundColor: "#ffbc33", color: "white" }}
            size="large"
            variant="contained"
            onClick={onAddTimeoutDeposit(dispatch, id, endData)}
          >
            Timeout
          </Button>
        );
      else
        return (
          <Button
            style={{ color: "white" }}
            size="large"
            variant="contained"
            disabled
          >
            Timeout
          </Button>
        );

    case 5:
      return (
        <Button
          style={{ backgroundColor: "#35b4e4", color: "white" }}
          size="large"
          variant="contained"
        >
          สำเร็จ
        </Button>
      );

    case 6:
      return (
        <Button
          style={{ backgroundColor: "#378d3c", color: "white" }}
          size="large"
          variant="contained"
        >
          สำเร็จ
        </Button>
      );

    case 7:
      return (
        <Button
          style={{ backgroundColor: "#8cc34a", color: "white" }}
          size="large"
          variant="contained"
        >
          สำเร็จ
        </Button>
      );

    case 8:
      return (
        <Button
          style={{ backgroundColor: "#B23CFD", color: "white" }}
          size="large"
          variant="contained"
        >
          สำเร็จ
        </Button>
      );

    case 300:
      return (
        <Button
          style={{ backgroundColor: "#512da8", color: "white" }}
          size="large"
          variant="contained"
        >
          ดึงกลับ
        </Button>
      );

    case 500:
      return (
        <Button
          style={{ backgroundColor: "#ff7143", color: "white" }}
          size="large"
          variant="contained"
        >
          ธนาคารแจ้งยกเลิก
        </Button>
      );

    default:
      return (
        <Button
          style={{ backgroundColor: "#B23CFD", color: "white" }}
          size="large"
          variant="contained"
        >
          ล้มเหลว
        </Button>
      );
  }
};

const TableBodyRowComponent = ({
  dispatch,
  depositData,
  pullDepositBtn,
  classNameStyle,
  currentUser,
  endData,
}) => (
  <TableRow className={classNameStyle}>
    <TableCell align="center">
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
      >
        {BtnStatus(
          dispatch,
          depositData?.status,
          depositData?.id,
          currentUser.isDeposit,
          endData
        )}
      </Grid>
    </TableCell>
    <TableCell align="center">
      {moment(depositData?.transactionDate).format("DD/MM/YYYY H:mm")}
    </TableCell>
    <TableCell align="center" width="10%">
      {depositData?.user?.inviter}
    </TableCell>
    <TableCell align="center">
      {depositData?.user?.user_pass?.username && (
        <Grid container direction="row" justify="center" alignItems="center">
          {depositData?.user?.user_pass?.username}
          <IconButton
            onClick={() =>
              window.open(
                `/admin/userdetail/${depositData?.user?.user_pass?.username}`
              )
            }
            size="small"
            style={{ marginLeft: 10, color: "#33b5e5" }}
          >
            <UserIcon />
          </IconButton>
        </Grid>
      )}
    </TableCell>
    <TableCell align="center">{depositData?.formBankId}</TableCell>
    <TableCell align="center">
      {depositData?.amount.toLocaleString("en")}
    </TableCell>
    <TableCell align="center">{`${depositData?.bank?.bankName} ${depositData?.bank?.bankId}`}</TableCell>
    <TableCell align="center" width="20%">
      {depositData?.note}
    </TableCell>
    <TableCell align="center">
      {/^[1,5,6,7,8]/.test(depositData?.status) && currentUser.isDeposit && (
        <Button
          variant="contained"
          style={{ backgroundColor: "#2bbbad", color: "white" }}
          onClick={pullDepositBtn}
        >
          ดึงเครดิต
        </Button>
      )}
    </TableCell>
  </TableRow>
);

const DepositList = ({
  className,
  depositDataList,
  search,
  pageNum,
  perPage,
  endData,
  ...rest
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const onLoading = useSelector((state) => state.other.loading);
  const currentUser = useSelector((state) => state.auth.currentUser);

  const onPullDeposit = (id, endData) => () => {
    if (window.confirm("คุณต้องการดึงเครดิตกลับหรือไม่")) {
      dispatch({ type: "onLoading" });
      pullDeposit(dispatch, id, endData);
    }
  };

  if (onLoading) return <LoadingCircle />;
  else
    return (
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box style={{ backgroundColor: "#000" }} minWidth={800}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.tableRowColor1}>
                  {columnName.map((column, index) =>
                    column.sort ? (
                      <TableHeadCellComponent
                        key={index}
                        name={column.label}
                        sort={column.sort}
                      />
                    ) : (
                      <TableHeadCellComponent key={index} name={column.label} />
                    )
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {depositDataList
                  .slice((pageNum - 1) * perPage, pageNum * perPage)
                  .map((depositData, index) => (
                    <TableBodyRowComponent
                      classNameStyle={
                        index % 2
                          ? `${classes.tableRowColor1}`
                          : `${classes.tableRowColor2}`
                      }
                      dispatch={dispatch}
                      key={index}
                      depositData={depositData}
                      pullDepositBtn={onPullDeposit(depositData.id, endData)}
                      currentUser={currentUser}
                      endData={endData}
                    />
                  ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    );
};

DepositList.propTypes = {
  className: PropTypes.string,
  depositDataList: PropTypes.array,
  search: PropTypes.string,
  pageNum: PropTypes.number,
  perPage: PropTypes.string,
  endData: PropTypes.number,
};

export default DepositList;
