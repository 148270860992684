const columnName = [
  {
    label: 'สถานะ',
    field: 'status',
    sort: "asc",
    width: 200,
  },
  {
    label: 'เวลา',
    field: 'transactionDate',
    sort: "asc",
    width: 100,
  },
  {
    label: 'ผู้แนะนำ',
    field: 'inviter',
    sort: "asc",
    width: 150,
  },
  {
    label: 'ชื่อผู้ใช้',
    field: 'username',
    sort: "asc",
    width: 200,
  },
  {
    label: 'โอนจาก',
    field: 'bankId',
    sort: "asc",
    width: 150,
  },
  {
    label: 'จำนวน',
    field: 'amount',
    sort: "asc",
    width: 100,
  },
  {
    label: 'เข้าบัญชี',
    field: 'desBank',
    sort: "asc",
    width: 200,
  },
  {
    label: 'หมายเหตุ',
    field: 'note',
    sort: "asc",
    width: 200,
  },
  {
    label: 'Action',
    field: 'action',
    width: 200,
  },
];
export default columnName;
