import Axios from "../../utils/axiosConfig";

export const getLogList = (dispatch) => {
  Axios.get("secure/logs").then((res) => {
    if (res.data.success) {
      dispatch({ type: "clearError" });
      dispatch({ type: "getLogList", res });
    } else {
      dispatch({ type: "onError", res });
    }
  });
};

export const getLogListWithAction = (dispatch) => {
  Axios.get("secure/logs/action/Setting").then((res) => {
    if (res.data.success) {
      dispatch({ type: "clearError" });
      dispatch({ type: "getLogListWithAction", res });
    } else {
      dispatch({ type: "onError", res });
    }
  });
};

export const getLogListWithAdmin = (dispatch, uuid) => {
  Axios.get(`secure/logs/admin/${uuid}`).then((res) => {
    if (res.data.success) {
      dispatch({ type: "clearError" });
      dispatch({ type: "getLogListWithAdmin", res });
    } else {
      dispatch({ type: "onError", res });
    }
  });
};
