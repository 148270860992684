import React from "react";
import { Link as RouterLink } from "react-router-dom";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  Typography,
  Button,
  Container,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  Menu as MenuIcon,
  Person as PersonIcon,
  SettingsApplications as SettingIcon,
} from "@material-ui/icons";
// import Logo from "../../components/Logo";
import { useDispatch, useSelector } from "react-redux";
import config from "../../configs";
import { useNavigate } from "react-router-dom";
import { signOut } from "../../store/actions/authActions";

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    width: 60,
    height: 60,
  },
  textColor: {
    color: "white",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [openSetting, setOpenSetting] = React.useState(false);
  const [openUser, setOpenUser] = React.useState(false);
  const anchorRefSetting = React.useRef(null);
  const anchorRefUser = React.useRef(null);

  const handleToggleSetting = () => {
    setOpenSetting((prevOpen) => !prevOpen);
  };

  const handleCloseSetting = (event) => {
    if (
      anchorRefSetting.current &&
      anchorRefSetting.current.contains(event.target)
    ) {
      return;
    }

    setOpenSetting(false);
  };

  function handleListSettingKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenSetting(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpenSetting = React.useRef(openSetting);
  React.useEffect(() => {
    if (prevOpenSetting.current === true && openSetting === false) {
      anchorRefSetting.current.focus();
    }

    prevOpenSetting.current = openSetting;
  }, [openSetting]);

  const handleToggleUser = () => {
    setOpenUser((prevOpen) => !prevOpen);
  };

  const handleCloseUser = (event) => {
    if (anchorRefUser.current && anchorRefUser.current.contains(event.target)) {
      return;
    }

    setOpenUser(false);
  };

  function handleListUserKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenUser(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpenUser = React.useRef(openUser);
  React.useEffect(() => {
    if (prevOpenUser.current === true && openUser === false) {
      anchorRefUser.current.focus();
    }

    prevOpenUser.current = openUser;
  }, [openUser]);

  const onSignOut = (e) => {
    signOut(dispatch);
    handleCloseUser(e);
    navigate("/login");
  };

  const routeLink = config.navBarRoutes.filter(
    (route) =>
      (currentUser?.rReport && route.permission === "rReport") ||
      (currentUser?.rUser && route.permission === "rUser") ||
      (currentUser?.rDeposit && route.permission === "rDeposit") ||
      (currentUser?.rWithdraw && route.permission === "rWithdraw") ||
      (currentUser?.rxReward && route.permission === "rxReward") ||
      (currentUser?.rAdmin && route.permission === "rAdmin") ||
      (currentUser?.lv === 0 && route.permission === "")
  );

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Container>
        <Toolbar style={{ padding: 0 }}>
          <RouterLink to="/">
            <Typography
              className={classes.textColor}
              variant="h2"
              style={{ marginRight: "1rem" }}
            >
              {config.logoName}
            </Typography>
          </RouterLink>
          <Hidden xsDown>
            {routeLink.map((route, index) => (
              <Button
                key={index.toString()}
                className={classes.textColor}
                href={route.href}
              >
                {route.title}
              </Button>
            ))}
          </Hidden>
          <Box flexGrow={1} />
          <Hidden smDown>
            {currentUser?.lv === 0 && (
              <Button
                ref={anchorRefSetting}
                aria-controls={openSetting ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggleSetting}
                style={{ marginRight: 20 }}
                color="inherit"
              >
                <SettingIcon />
                <Typography style={{ marginLeft: 5 }} variant="h5">
                  ตั้งค่า
                </Typography>
              </Button>
            )}
            <Popper
              open={openSetting}
              anchorEl={anchorRefSetting.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleCloseSetting}>
                      <MenuList
                        autoFocusItem={openSetting}
                        id="menu-list-grow"
                        onKeyDown={handleListSettingKeyDown}
                      >
                        <MenuItem
                          onClick={(e) => {
                            handleCloseSetting(e);
                            navigate("/admin/setting");
                          }}
                        >
                          ทั่วไป
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            handleCloseSetting(e);
                            navigate("/admin/setting/wheel");
                          }}
                        >
                          วงล้อ
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            handleCloseSetting(e);
                            navigate("/admin/setting/reward");
                          }}
                        >
                          ของรางวัล
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <Button
              ref={anchorRefUser}
              aria-controls={openUser ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={handleToggleUser}
              color="inherit"
            >
              <PersonIcon />
              <Typography style={{ marginLeft: 5 }} variant="h5">
                {currentUser?.name}
              </Typography>
            </Button>
            <Popper
              open={openUser}
              anchorEl={anchorRefUser.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleCloseUser}>
                      <MenuList
                        autoFocusItem={openUser}
                        id="menu-list-grow"
                        onKeyDown={handleListUserKeyDown}
                      >
                        <MenuItem onClick={onSignOut}>ออกจากระบบ</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Hidden>
          <Hidden mdUp>
            <IconButton color="inherit" onClick={onMobileNavOpen}>
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
