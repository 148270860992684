const initState = {
  settingDetail: null,
  rewardSettingList: null,
  wheelSettingList: null,
  loading: true,
};

const settingReducer = (state = initState, action) => {
  switch (action.type) {
    case "getSettingDetail":
      var setting = action.res.data.data;

      return {
        ...state,
        settingDetail: setting,
        loading: false,
      };
    case "getRewardSettingList":
      var rewardSettings = action.res.data.data;
      var rewardSettingData = [];

      rewardSettings?.sort(function (a, b) {
        return a.price - b.price;
      });

      rewardSettings?.forEach((rewardSetting) => {
        rewardSettingData.push(rewardSetting);
      });

      return {
        ...state,
        rewardSettingList: rewardSettings,
        loading: false,
      };
    case "getWheelSettingList":
      var wheelSettings = action.res.data.data;
      var wheelSettingData = [];

      wheelSettings?.forEach((wheelSetting) => {
        wheelSettingData.push(wheelSetting);
      });

      return {
        ...state,
        wheelSettingList: wheelSettings,
        loading: false,
      };
    default:
      return state;
  }
};
export default settingReducer;
