import React from "react";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Grid,
  Button,
} from "@material-ui/core";
import columnName from "./columnName";
import EditRewardSetting from "./EditRewardSetting";
import { useDispatch } from "react-redux";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { deleteRewardSetting } from "../../../store/actions/settingAction";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.black,
  },
  table: {
    "& .MuiTableSortLabel-root": {
      color: "white",
    },
    "& .MuiTableCell-root": {
      color: "white",
      borderWidth: 1,
      borderColor: theme.palette.background.tableLine,
      borderStyle: "solid",
      padding: 8,
    },
  },
  tableRowColor1: {
    backgroundColor: theme.palette.background.grey1,
  },
  tableRowColor2: {
    backgroundColor: theme.palette.background.grey2,
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const TableHeadCellComponent = ({ name }) => (
  <TableCell align="center">{name}</TableCell>
);

const TableBodyRowComponent = ({
  rewardSettingData,
  deleteBtn,
  classNameStyle,
}) => (
  <TableRow className={classNameStyle}>
    <TableCell align="center" width="18%">{rewardSettingData?.title}</TableCell>
    <TableCell align="center">
      {rewardSettingData?.categoryId === 1 ? "แลกเครดิต" : "แลกของ"}
    </TableCell>
    <TableCell align="center" width="18%">
      {rewardSettingData?.detail}
    </TableCell>
    <TableCell align="center">{rewardSettingData?.price}</TableCell>
    <TableCell align="center">{rewardSettingData?.amount}</TableCell>
    <TableCell align="center">
      {moment(rewardSettingData?.createdAt).format("DD/MM/YYYY H:mm")}
    </TableCell>
    <TableCell align="center">
      <Grid
        spacing={1}
        container
        direction="row"
        justify="space-around"
        alignItems="center"
      >
        <Grid item md={6} xs={12}>
          <EditRewardSetting rewardSettingData={rewardSettingData} />
        </Grid>
        <Grid item md={6} xs={12}>
          <Button
            variant="contained"
            style={{ backgroundColor: "#ff3547", color: "white" }}
            onClick={deleteBtn}
          >
            <DeleteIcon />
          </Button>
        </Grid>
      </Grid>
    </TableCell>
  </TableRow>
);

const RewardSettingList = ({
  className,
  rewardSettingDataList,
  search,
  pageNum,
  perPage,
  ...rest
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onDelete = (id) => () => {
    if (window.confirm("คุณต้องการลบหรือไม่")) {
      deleteRewardSetting(dispatch, id);
    }
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box style={{ backgroundColor: "#000" }} minWidth={800}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRowColor1}>
                {columnName.map((column, index) =>
                  column.sort ? (
                    <TableHeadCellComponent key={index} name={column.label} />
                  ) : (
                    <TableHeadCellComponent key={index} name={column.label} />
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {rewardSettingDataList
                .slice((pageNum - 1) * perPage, pageNum * perPage)
                .map((rewardSettingData, index) => (
                  <TableBodyRowComponent
                    classNameStyle={
                      index % 2
                        ? `${classes.tableRowColor1}`
                        : `${classes.tableRowColor2}`
                    }
                    key={index}
                    rewardSettingData={rewardSettingData}
                    deleteBtn={onDelete(rewardSettingData.id)}
                  />
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

RewardSettingList.propTypes = {
  className: PropTypes.string,
  rewardSettingDataList: PropTypes.array,
  search: PropTypes.string,
  pageNum: PropTypes.number,
  perPage: PropTypes.string,
};

export default RewardSettingList;
