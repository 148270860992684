import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogContent,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { editGeneralSetting } from '../../../store/actions/settingAction';
import { Edit as EditIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.black,
  },
  textColor: {
    color: 'white',
  },
  textFieldColor: {
    color: '#4285f4',
    backgroundColor: theme.palette.background.grey1,
  },
  mainBtn: {
    backgroundColor: theme.palette.background.mainBtn,
    color: 'white',
  },
}));

const EditGeneralSetting = ({ className, settingDetailData, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const EditGeneralSettingSchema = Yup.object().shape({
    news: Yup.string(),
  });

  const body = (
    <Formik
      validationSchema={EditGeneralSettingSchema} //กำหนด validation
      initialValues={{
        //กำหนด initialValues
        news: settingDetailData.news,
      }}
      onSubmit={(values) => {
        editGeneralSetting(dispatch, values, settingDetailData.id);
        handleClose();
      }}
    >
      {({ errors, touched, values, handleBlur, handleChange }) => (
        <Form>
          <Box mt={3} mb={3}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Typography className={classes.textColor} variant="h3">
                แก้ไขตั้งค่าทั่วไป
              </Typography>
            </Grid>
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              ประกาศหน้าเว็บไซต์
            </Typography>
            <TextField
              multiline
              rows={10}
              error={Boolean(touched.news && errors.news)}
              fullWidth
              size="small"
              helperText={touched.news && errors.news}
              margin="normal"
              name="news"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.news}
              variant="outlined"
              InputProps={{
                className: classes.textFieldColor,
              }}
              style={{ marginTop: 8 }}
            />
          </Box>

          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ marginBottom: 16 }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              size="large"
              style={{ backgroundColor: '#B23CFD', color: 'white' }}
            >
              ปิด
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              style={{ backgroundColor: '#4285f4', color: 'white' }}
            >
              บันทึก
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );

  return (
    <div>
      <Button
        size="large"
        variant="contained"
        className={classes.mainBtn}
        onClick={handleClickOpen}
      >
        <EditIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent className={classes.paper}>{body}</DialogContent>
      </Dialog>
    </div>
  );
};

EditGeneralSetting.propTypes = {
  className: PropTypes.string,
  settingDetailData: PropTypes.object,
};

export default EditGeneralSetting;
