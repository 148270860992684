const initState = {
  depositList: null,
  depositBankList: [],
  loading: true,
};

const depositReducer = (state = initState, action) => {
  switch (action.type) {
    case 'getDepositList':
      const depositData = action.res.data.data;

      // deposits?.sort(function (a, b) {
      //   return new Date(b.createdAt) - new Date(a.createdAt);
      // });

      // deposits?.forEach((deposit) => {
      //   depositData.push(deposit);
      // });

      return {
        ...state,
        depositList: depositData,
        loading: false,
      };
    case 'getDepositBankList':
      var depositBankData = action.res.data.data;

      return {
        ...state,
        depositBankList: depositBankData,
      };
    case 'onDepositLoading':
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
export default depositReducer;
