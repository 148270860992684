const columnName = [
  {
    label: "ลำดับ",
    field: "key",
    sort: "asc",
    width: 200,
  },
  {
    label: "Username",
    field: "username",
    sort: "asc",
    width: 100,
  },
  {
    label: "ทีม",
    field: "team",
    sort: "asc",
    width: 150,
  },
  {
    label: "ชื่อ",
    field: "name",
    sort: "asc",
    width: 200,
  },
  {
    label: "Link",
    field: "link",
    sort: "asc",
    width: 200,
  },
  {
    label: "สร้างเมื่อ",
    field: "date",
    sort: "asc",
    width: 200,
  },
  {
    label: "Actions",
    field: "actions",
    sort: "asc",
    width: 200,
  },
];
export default columnName;
