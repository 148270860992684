import Axios from '../../utils/axiosConfig';

export const getUserReportList = async (dispatch, timeStart, timeEnd) => {
  var body = { start: timeStart, end: timeEnd };

  await Axios.post('/secure/report/user', body).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getUserReportList', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getAllDepositReportList = async (dispatch, timeStart, timeEnd) => {
  var body = { start: timeStart, end: timeEnd };

  await Axios.post('/secure/report/list/deposit', body).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getDepositReportList', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getDepositReportList = async (dispatch, bankId, timeStart, timeEnd) => {
  var body = { start: timeStart, end: timeEnd };

  await Axios.post(`/secure/report/list/deposit/${bankId}`, body).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getDepositReportList', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getAllWithdrawReportList = async (dispatch, timeStart, timeEnd) => {
  var body = { start: timeStart, end: timeEnd };

  await Axios.post('/secure/report/list/withdraw', body).then((res) => {
    if (res.data.success) {
      console.log('getAllWithdrawReportList', res.data);
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getWithdrawReportList', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getWithdrawReportList = async (dispatch, bankId, timeStart, timeEnd) => {
  var body = { start: timeStart, end: timeEnd };

  await Axios.post(`/secure/report/list/withdraw/${bankId}`, body).then((res) => {
    if (res.data.success) {
      console.log('getWithdrawReportList', res.data);
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getWithdrawReportList', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getReportOldUserList = async (dispatch, dateTime) => {
  var body = { dateTime: dateTime };

  await Axios.post('/secure/report/old-user', body).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getReportOldUserList', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getUserDetailList = async (dispatch) => {
  await Axios.get('/secure/report/userdetail').then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getUserDetailList', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getReportBankList = async (dispatch, type) => {
  await Axios.get(`/secure/report/bank/${type}`).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getReportBankList', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};
