import React from "react";
import { CircularProgress, Grid, Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.grey1,
    marginBottom: 20,
  },
}));

function LoadingCircle() {
  const classes = useStyles();

  return (
    <Box
      borderRadius="borderRadius"
      boxShadow={3}
      p={2}
      className={classes.root}
    >
      <Grid
        spacing={0}
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ minHeight: "50vh" }}
      >
        <CircularProgress style={{ color: "white" }} />
      </Grid>
    </Box>
  );
}

export default LoadingCircle;
