import React from "react";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import wheelColumnName from "./wheelColumnName";

const useStyles = makeStyles((theme) => ({
  root: {},
  table: {
    "& .MuiTableSortLabel-root": {
      color: "white",
    },
    "& .MuiTableCell-root": {
      color: "white",
      borderWidth: 1,
      borderColor: theme.palette.background.tableLine,
      borderStyle: "solid",
      padding: 8,
    },
  },
  tableRowColor1: {
    backgroundColor: theme.palette.background.grey1,
  },
  tableRowColor2: {
    backgroundColor: theme.palette.background.grey2,
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const TableHeadCellWithSortComponent = ({ name }) => (
  <TableCell align="center">{name}</TableCell>
);

const TableHeadCellComponent = ({ name }) => (
  <TableCell align="center">{name}</TableCell>
);

const TableBodyRowComponent = ({ userWheelData, index, classNameStyle }) => (
  <TableRow className={classNameStyle}>
    <TableCell align="center">{index}</TableCell>
    <TableCell align="center">
      {moment(userWheelData?.createdAt).format("DD/MM/YYYY H:mm")}
    </TableCell>
    <TableCell style={{ color: "#28a745" }} align="center">
      {JSON.parse(userWheelData?.json).message}
    </TableCell>
  </TableRow>
);

const UserWheelList = ({
  className,
  userWheelList,
  pageNum,
  perPage,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box style={{ backgroundColor: "#000" }}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRowColor1}>
                {wheelColumnName.map((column, index) =>
                  column.sort ? (
                    <TableHeadCellWithSortComponent
                      key={index}
                      name={column.label}
                    />
                  ) : (
                    <TableHeadCellComponent key={index} name={column.label} />
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {userWheelList
                ?.slice((pageNum - 1) * perPage, pageNum * perPage)
                .map((userWheelData, index) => (
                  <TableBodyRowComponent
                    classNameStyle={
                      index % 2
                        ? `${classes.tableRowColor1}`
                        : `${classes.tableRowColor2}`
                    }
                    key={index}
                    index={(pageNum - 1) * perPage + index + 1}
                    userWheelData={userWheelData}
                  />
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

UserWheelList.propTypes = {
  className: PropTypes.string,
  userWheelList: PropTypes.array,
  pageNum: PropTypes.number,
  perPage: PropTypes.number,
};

export default UserWheelList;
