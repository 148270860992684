import Axios from '../../utils/axiosConfig';
import configs from '../../configs';
import moment from 'moment';
import { showErrorSwal, showSuccessSwal } from '../../utils/SwalAlert';

const date = moment(new Date()).format('YYYY-MM-DD');
const timeStart = '00:00';
const timeEnd = '23:59';

var dateTimeStart = moment(`${date} ${timeStart}`);
var dateTimeEnd = moment(`${date} ${timeEnd}`);

export const getWithdrawHistoryList = (dispatch) => {
  Axios.get('/secure/withdraw/history').then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getWithdrawHistoryList', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getWithdrawRequestList = (dispatch) => {
  Axios.get('/secure/withdraw/request').then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getWithdrawRequestList', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getScbWithdrawBalance1 = (dispatch) => {
  Axios.get(`/secure/withdraw/bank-balance/${configs.scbWithdraw1.bank}`).then(
    (res) => {
      if (res.data.success) {
        dispatch({ type: 'getScbWithdrawBalance1', res });
      }
    }
  );
};

export const getScbWithdrawBalance2 = (dispatch) => {
  Axios.get(`/secure/withdraw/bank-balance/${configs.scbWithdraw2.bank}`).then(
    (res) => {
      if (res.data.success) {
        dispatch({ type: 'getScbWithdrawBalance2', res });
      }
    }
  );
};

export const getSumWithdrawBank1 = async (dispatch) => {
  var body = {
    start: dateTimeStart,
    end: dateTimeEnd,
    status: configs.scbWithdraw1.id,
  };

  await Axios.post('/secure/report/withdraw/status', body).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'getSumWithdrawBank1', res });
    }
  });
};

export const getSumWithdrawBank2 = async (dispatch) => {
  var body = {
    start: dateTimeStart,
    end: dateTimeEnd,
    status: configs.scbWithdraw2.id,
  };

  await Axios.post('/secure/report/withdraw/status', body).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'getSumWithdrawBank2', res });
    }
  });
};

export const cancelWithRefundWithdraw = (dispatch, withdrawId) => {
  var body = { withdrawId: withdrawId };
  Axios.patch(`/secure/withdraw/cancel`, body).then((res) => {
    if (res.data.success) {
      getWithdrawRequestList(dispatch);
      getWithdrawHistoryList(dispatch);
      dispatch({ type: 'onFinish' });
      showSuccessSwal(res.data.message);
    } else {
      dispatch({ type: 'onFinish' });
      showErrorSwal(res.data.message);
    }
  });
};

export const cancelWithNoRefundWithdraw = (dispatch, withdrawId) => {
  var body = { withdrawId: withdrawId };
  Axios.patch(`/secure/withdraw/cancel-no-refund`, body).then((res) => {
    if (res.data.success) {
      getWithdrawRequestList(dispatch);
      getWithdrawHistoryList(dispatch);
      dispatch({ type: 'onFinish' });
      showSuccessSwal(res.data.message);
    } else {
      dispatch({ type: 'onFinish' });
      showErrorSwal(res.data.message);
    }
  });
};

export const confirmTransactionWithdraw = (dispatch, withdrawId) => {
  var body = { withdrawId: withdrawId };
  Axios.patch(`/secure/withdraw/confirm-transaction`, body).then((res) => {
    if (res.data.success) {
      getWithdrawRequestList(dispatch);
      getWithdrawHistoryList(dispatch);
      getScbWithdrawBalance1(dispatch);
      getScbWithdrawBalance2(dispatch);
      dispatch({ type: 'onFinish' });
      showSuccessSwal(res.data.message);
    } else {
      dispatch({ type: 'onFinish' });
      showErrorSwal(res.data.message);
    }
  });
};

export const addManualWithdraw = (dispatch, withdrawId) => {
  var body = { withdrawId: withdrawId };
  Axios.patch('/secure/withdraw/confirm', body).then((res) => {
    if (res.data.success) {
      getWithdrawRequestList(dispatch);
      getWithdrawHistoryList(dispatch);
      getScbWithdrawBalance1(dispatch);
      getScbWithdrawBalance2(dispatch);
      dispatch({ type: 'onFinish' });
      showSuccessSwal(res.data.message);
    } else {
      dispatch({ type: 'onFinish' });
      showErrorSwal(res.data.message);
    }
  });
};

export const withdrawScbBank = (dispatch, withdrawId, bankid) => {
  var body = { withdrawId: withdrawId, bankid: bankid };
  Axios.post('/secure/withdraw/scb', body).then((res) => {
    getWithdrawRequestList(dispatch);
    getWithdrawHistoryList(dispatch);
    getScbWithdrawBalance1(dispatch);
    getScbWithdrawBalance2(dispatch);
    dispatch({ type: 'onFinish' });
    if (res.data.success) {
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};

export const transferToWithdrawBank = (dispatch, amount) => {
  var body = { amount: amount };
  Axios.post('/secure/withdraw/scb/transfer', body).then((res) => {
    getWithdrawRequestList(dispatch);
    getWithdrawHistoryList(dispatch);
    getScbWithdrawBalance1(dispatch);
    getScbWithdrawBalance2(dispatch);
    dispatch({ type: 'onFinish' });
    if (res.data.success) {
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};

export const getWithdrawBankList = async (dispatch) => {
  await Axios.get(`/secure/report/bank/withdraw`).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getWithdrawBankList', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getWithdrawBankSumList = async (dispatch) => {
  var body = {
    start: dateTimeStart,
    end: dateTimeEnd
  };

  await Axios.post('/secure/report/withdraw/sum', body).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'getWithdrawBankSumList', res });
    }
  });
};

export const withdrawAutoBank = (dispatch, withdrawId, bankid) => {
  var body = { withdrawId: withdrawId, bankid: bankid };
  Axios.post('/secure/withdraw/auto', body).then((res) => {
    getWithdrawRequestList(dispatch);
    getWithdrawHistoryList(dispatch);
    getWithdrawBankList(dispatch);
    getWithdrawBankSumList(dispatch);
    dispatch({ type: 'onFinish' });
    if (res.data.success) {
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};