import Axios from "../../utils/axiosConfig";
// import md5 from "md5";

export const getCurrentUser = (dispatch) => {
  if (localStorage.token != null && localStorage.user != null) {
    dispatch({ type: "getCurrentUser" });
  } else {
    dispatch({ type: "noCurrentUser" });
  }
};

export const signIn = (dispatch, username, password, token) => {
  Axios.post("/secure/login", {
    username: username,
    password: password,
    token: token,
  }).then((res) => {
    if (res.data.token) {
      dispatch({ type: "LoginSuccess", res });
    } else {
      dispatch({ type: "LoginFail", res });
    }
  });
};

// export const signOut = (dispatch) => {
//   dispatch({ type: "Logout" });
// };
export const signOut = (dispatch) => {
  Axios.get("/secure/admin/logout")
    .then((res) => {
      dispatch({ type: "Logout" });
    })
    .catch(() => dispatch({ type: "Logout" }));
};
