import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Typography,
  Container,
  makeStyles,
  Box,
  Grid,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import Page from '../../../components/Page';
import {
  getUserDetail,
  getUserDepositList,
  getUserDepositListFromIMI,
  getUserWithdrawList,
  getUserWheelList,
  getUserDiagram,
  getSumUserDeposit,
  getSumUserWithdraw,
  getUserRewardList,
  // getUserWinLose,
} from '../../../store/actions/userAction';
import { useDispatch, useSelector } from 'react-redux';
import LoadingCircle from '../../../utils/LoadingCircle';
import { Navigate, useParams } from 'react-router';
import UserDepositList from './UserDepositList';
import UserWithdrawList from './UserWithdrawList';
import UserWheelList from './UserWheelList';
import UserRewardList from './UserRewardList';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.black,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      color: 'white',
    },
  },
  textColor: {
    color: 'white',
  },
  tableRowColor: {
    backgroundColor: theme.palette.background.grey1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: 'white',
    backgroundColor: theme.palette.background.grey1,
    height: 60,
  },
}));

// const usePrevious = (value) => {
//   const ref = useRef();
//   useEffect(() => {
//     ref.current = value;
//   });
//   return ref.current;
// };

function UserDetail() {
  const { username } = useParams();
  const classes = useStyles();
  const dispatch = useDispatch();
  const userLoading = useSelector((state) => state.user.loading);
  const userDetail = useSelector((state) => state.user.userDetail);
  const sumUserDeposit = useSelector((state) => state.user.sumUserDeposit);
  const sumUserWithdraw = useSelector((state) => state.user.sumUserWithdraw);
  const userDepositListFromIMI = useSelector(
    (state) => state.user.userDepositListFromIMI
  );
  const userRewardList = useSelector((state) => state.user.userRewardList);
  const userDepositList = useSelector((state) => state.user.userDepositList);
  const userWithdrawList = useSelector((state) => state.user.userWithdrawList);
  const userWheelList = useSelector((state) => state.user.userWheelList);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const authLoading = useSelector((state) => state.auth.loading);
  // const prevUserDetail = usePrevious(userDetail);

  const [depositPageNum, setDepositPageNum] = useState(1);
  const [withdrawPageNum, setWithdrawPageNum] = useState(1);
  const [wheelPageNum, setWheelPageNum] = useState(1);
  const [rewardPageNum, setRewardPageNum] = useState(1);

  const depositPerPage = 10;
  const withdrawPerPage = 10;
  const wheelPerPage = 10;
  const rewardPerPage = 10;

  // const sumDeposit = userDepositListFromIMI?.reduce((sum, number) => {
  //   return sum + number.Amount;
  // }, 0);
  // const sumWithdraw = userWithdrawList?.reduce((sum, number) => {
  //   return sum + number.Amount;
  // }, 0);
  const sumWheel = userWheelList?.reduce((sum, number) => {
    return sum + parseInt(JSON.parse(number?.json).message.split(' ')[1]);
  }, 0);
  const sumReward = userRewardList?.reduce((sum, number) => {
    return sum + parseInt(number.price);
  }, 0);

  useEffect(() => {
    // if (!equal(userDetail, prevUserDetail))
    if (userDetail?.uuid) {
      getUserDepositListFromIMI(dispatch, username);
      getUserWithdrawList(dispatch, username);
      getUserWheelList(dispatch, username);
      getUserDiagram(dispatch, username);
      // getUserWinLose(dispatch, username);
      getUserRewardList(dispatch, userDetail?.uuid);
      getUserDepositList(dispatch, userDetail?.uuid);
      getSumUserDeposit(dispatch, userDetail?.uuid);
      getSumUserWithdraw(dispatch, userDetail?.uuid);
    } else {
      getUserDetail(dispatch, username);
    }
  }, [dispatch, username, userDetail?.uuid]);

  const handleChangeDepositPage = (e, value) => {
    setDepositPageNum(value);
  };

  const handleChangeWithdrawPage = (e, value) => {
    setWithdrawPageNum(value);
  };

  const handleChangeWheelPage = (e, value) => {
    setWheelPageNum(value);
  };

  const handleChangeRewardPage = (e, value) => {
    setRewardPageNum(value);
  };

  const BoxComponent = ({ text }) => (
    <Box
      borderRadius="borderRadius"
      boxShadow={3}
      display="flex"
      alignItems="center"
      className={classes.paper}
    >
      {text}
    </Box>
  );

  if (!authLoading && !currentUser) return <Navigate to="/login" />;
  else
    return (
      <Page className={classes.root} title={`ผู้ใช้ : ${userDetail?.name}`}>
        <Container>
          {!userLoading &&
          userDepositListFromIMI &&
          userWithdrawList &&
          userWheelList ? (
            <>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
                style={{ marginBottom: 30 }}
              >
                <Typography className={classes.textColor} variant="h2">
                  {userDetail?.name}
                </Typography>
                <Typography className={classes.textColor} variant="h2">
                  เครดิต :{' '}
                  <span
                    style={{
                      color: '#28a745',
                    }}
                  >
                    {userDetail?.credit?.toLocaleString('en', {
                      minimumFractionDigits: 2,
                    })}
                  </span>{' '}
                  บาท
                </Typography>
                <Typography className={classes.textColor} variant="h2">
                  ฝาก - ถอน :{' '}
                  <span
                    style={{
                      color:
                        sumUserDeposit - sumUserWithdraw > 0
                          ? '#28a745'
                          : 'red',
                    }}
                  >
                    {(sumUserDeposit - sumUserWithdraw).toLocaleString('en', {
                      minimumFractionDigits: 2,
                    })}
                  </span>{' '}
                  บาท
                </Typography>
              </Grid>
              <Grid
                container
                justify="space-between"
                alignItems="flex-start"
                spacing={3}
                style={{ marginBottom: 16 }}
              >
                <Grid item xs={6}>
                  <BoxComponent
                    text={`รหัส : ${userDetail?.user_pass?.username}`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <BoxComponent
                    text={`ยูสเซอร์ไอดี : ${userDetail?.user_auth?.username}`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <BoxComponent text={`ผู้แนะนำ : ${userDetail?.inviter}`} />
                </Grid>
                {currentUser.lv === 0 && (
                  <Grid item xs={6}>
                    <BoxComponent text={`ไลน์ : ${userDetail?.line}`} />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <BoxComponent
                    text={`ธนาคาร : ${userDetail?.user_bank?.bankName}`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <BoxComponent
                    text={`เลขบัญชี : ${userDetail?.user_bank?.bankId}`}
                  />
                </Grid>
                {currentUser.lv === 0 && (
                  <Grid item xs={6}>
                    <BoxComponent
                      text={`เบอร์โทรศัพท์ : ${userDetail?.phone}`}
                    />
                  </Grid>
                )}
                <Grid item xs={6}>
                  <BoxComponent
                    text={`สมัครวันที่ : ${moment(userDetail?.createdAt).format(
                      'DD/MM/YYYY'
                    )}`}
                  />
                </Grid>
              </Grid>
              <Grid
                spacing={3}
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
              >
                <Grid item md={6} xs={12}>
                  <Box
                    borderRadius="borderRadius"
                    boxShadow={3}
                    p={2}
                    className={classes.tableRowColor}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="flex-start"
                    >
                      <Typography
                        className={classes.textColor}
                        style={{ marginBottom: 16 }}
                        variant="h4"
                      >
                        รายการฝาก (7 วันล่าสุด)
                      </Typography>
                      <Typography
                        className={classes.textColor}
                        style={{ marginBottom: 16, color: '#28a745' }}
                        variant="h4"
                      >
                        ฝากรวม :{' '}
                        {sumUserDeposit?.toLocaleString('en', {
                          minimumFractionDigits: 2,
                        })}
                      </Typography>
                    </Grid>
                    <Box>
                      <UserDepositList
                        userDepositListFromIMI={userDepositListFromIMI}
                        userDepositList={userDepositList}
                        pageNum={depositPageNum}
                        perPage={depositPerPage}
                      />
                    </Box>
                    <Box mt={3} display="flex" justifyContent="center">
                      <Pagination
                        color="secondary"
                        className={classes.pagination}
                        count={Math.ceil(
                          userDepositListFromIMI?.length / depositPerPage
                        )}
                        size="large"
                        onChange={handleChangeDepositPage}
                        variant="outlined"
                        shape="rounded"
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Box
                    borderRadius="borderRadius"
                    boxShadow={3}
                    p={2}
                    className={classes.tableRowColor}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="flex-start"
                    >
                      <Typography
                        className={classes.textColor}
                        style={{ marginBottom: 16 }}
                        variant="h4"
                      >
                        รายการถอน (7 วันล่าสุด)
                      </Typography>
                      <Typography
                        className={classes.textColor}
                        style={{ marginBottom: 16, color: 'red' }}
                        variant="h4"
                      >
                        ถอนรวม :{' '}
                        {sumUserWithdraw?.toLocaleString('en', {
                          minimumFractionDigits: 2,
                        })}
                      </Typography>
                    </Grid>
                    <Box>
                      <UserWithdrawList
                        userWithdrawList={userWithdrawList}
                        pageNum={withdrawPageNum}
                        perPage={withdrawPerPage}
                      />
                    </Box>
                    <Box mt={3} display="flex" justifyContent="center">
                      <Pagination
                        color="secondary"
                        className={classes.pagination}
                        count={Math.ceil(
                          userWithdrawList?.length / withdrawPerPage
                        )}
                        size="large"
                        onChange={handleChangeWithdrawPage}
                        variant="outlined"
                        shape="rounded"
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    borderRadius="borderRadius"
                    boxShadow={3}
                    p={2}
                    className={classes.tableRowColor}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="flex-start"
                    >
                      <Typography
                        className={classes.textColor}
                        style={{ marginBottom: 16 }}
                        variant="h4"
                      >
                        รายการหมุนวงล้อ
                      </Typography>
                      <Typography
                        className={classes.textColor}
                        style={{ marginBottom: 16, color: 'red' }}
                        variant="h4"
                      >
                        แต้มได้รวม : {sumWheel}
                      </Typography>
                    </Grid>
                    <Box>
                      <UserWheelList
                        userWheelList={userWheelList}
                        pageNum={wheelPageNum}
                        perPage={wheelPerPage}
                      />
                    </Box>
                    <Box mt={3} display="flex" justifyContent="center">
                      <Pagination
                        color="secondary"
                        className={classes.pagination}
                        count={Math.ceil(userWheelList?.length / wheelPerPage)}
                        size="large"
                        onChange={handleChangeWheelPage}
                        variant="outlined"
                        shape="rounded"
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    borderRadius="borderRadius"
                    boxShadow={3}
                    p={2}
                    className={classes.tableRowColor}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="flex-start"
                    >
                      <Typography
                        className={classes.textColor}
                        style={{ marginBottom: 16 }}
                        variant="h4"
                      >
                        รายการแลก (15 วันล่าสุด)
                      </Typography>
                      <Typography
                        className={classes.textColor}
                        style={{ marginBottom: 16, color: 'red' }}
                        variant="h4"
                      >
                        แต้มแลกรวม : {sumReward}
                      </Typography>
                    </Grid>
                    <Box>
                      <UserRewardList
                        userRewardList={userRewardList}
                        pageNum={rewardPageNum}
                        perPage={rewardPerPage}
                      />
                    </Box>
                    <Box mt={3} display="flex" justifyContent="center">
                      <Pagination
                        color="secondary"
                        className={classes.pagination}
                        count={Math.ceil(
                          userRewardList?.length / rewardPerPage
                        )}
                        size="large"
                        onChange={handleChangeRewardPage}
                        variant="outlined"
                        shape="rounded"
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </>
          ) : (
            <LoadingCircle />
          )}
        </Container>
      </Page>
    );
}

export default UserDetail;
