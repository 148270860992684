import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Button,
  Grid,
  Typography,
  Divider,
} from "@material-ui/core";
import PropTypes from "prop-types";
import QRCode from "qrcode.react";
import { useDispatch } from "react-redux";
import {
  get2FAGenerator,
  disable2FA,
} from "../../../store/actions/adminAction";
import { PhonelinkLock } from "@material-ui/icons";
// import { useNavigate } from "react-router-dom";
// import { signOut } from "../../../store/actions/authActions";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.black,
  },
  textColor: {
    color: "white",
  },
  textFieldColor: {
    color: "#4285f4",
    backgroundColor: theme.palette.background.grey1,
  },
}));

const Set2FA = ({ className, adminData, ...rest }) => {
  const classes = useStyles();
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const [qrcode, setQrcode] = useState("");
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const onSignOut = () => {
  //   signOut(dispatch);
  //   handleClose();
  //   navigate("/login");
  // };

  const get2FASecret = () => {
    get2FAGenerator(dispatch, adminData.uuid).then((data) => {
      setQrcode(data.data?.secret?.otpauth_url);
    });
  };
  const disable2FASecret = () => {
    disable2FA(dispatch, adminData.uuid).then((data) => {
      handleClose();
    });
  };
  const body = (
    <>
      <Box mb={3}>
        <Typography className={classes.textColor} variant="h2">
          ตั้งรหัส 2 ชั้น
        </Typography>
      </Box>
      <Divider style={{ backgroundColor: "white" }} />
      <Box mt={3} mb={3}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Typography className={classes.textColor} variant="h3">
            Username : {adminData?.username}
          </Typography>
        </Grid>
      </Box>

      <Box mb={2}>
        <Grid container direction="row" justify="center" alignItems="center">
          {qrcode && (
            <>
              <QRCode value={qrcode} />
              <Typography className={classes.textColor} variant="h3">
                ใช้ App Google Authenticator ในการแสกน
              </Typography>
            </>
          )}
        </Grid>
      </Box>

      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 16 }}
      >
        <Button
          onClick={handleClose}
          variant="contained"
          size="large"
          style={{ backgroundColor: "#B23CFD", color: "white" }}
        >
          ปิด
        </Button>
        {qrcode ? (
          <Button
            onClick={handleClose}
            variant="contained"
            size="large"
            style={{ backgroundColor: "#4285f4", color: "white" }}
          >
            สำเร็จ
          </Button>
        ) : (
          <>
            {adminData?.is2FA ? (
              <Button
                type="submit"
                variant="contained"
                size="large"
                style={{ backgroundColor: "#4285f4", color: "white" }}
                onClick={disable2FASecret}
              >
                ปิด 2 factor
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                size="large"
                style={{ backgroundColor: "#4285f4", color: "white" }}
                onClick={get2FASecret}
              >
                ขอ QRcode
              </Button>
            )}
          </>
        )}
      </Grid>
    </>
  );

  return (
    <div>
      <Button
        size="small"
        variant="contained"
        style={{
          backgroundColor: adminData?.is2FA ? "#000" : "rgb(255 113 30)",
          color: "white",
        }}
        onClick={handleClickOpen}
      >
        <PhonelinkLock />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent className={classes.paper}>{body}</DialogContent>
      </Dialog>
    </div>
  );
};

Set2FA.propTypes = {
  className: PropTypes.string,
  adminData: PropTypes.object,
};

export default Set2FA;
