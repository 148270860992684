import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  NativeSelect,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import BootstrapInput from "../../../components/BootstrapInput";
import { addAdmin } from "../../../store/actions/adminAction";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.black,
  },
  textColor: {
    color: "white",
  },
  textFieldColor: {
    color: "#4285f4",
    backgroundColor: theme.palette.background.grey1,
  },
  mainBtn: {
    backgroundColor: theme.palette.background.mainBtn,
    color: "white",
  },
}));

const AddAdmin = ({ className, adminData, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const AddAdminSchema = Yup.object().shape({
    username: Yup.string()
      .min(6, "กรุณากรอกตัวอักษรไม่น้อยกว่า 6 ตัวอักษร")
      .max(255)
      .required("กรุณากรอก Username"),
    password: Yup.string()
      .min(6, "กรุณากรอกตัวอักษรไม่น้อยกว่า 6 ตัวอักษร")
      .max(255)
      .required("กรุณากรอก Password"),
    name: Yup.string().max(255).required("กรุณากรอกชื่อ"),
  });

  const body = (
    <Formik
      validationSchema={AddAdminSchema} //กำหนด validation
      initialValues={{
        //กำหนด initialValues
        username: "",
        password: "",
        team: "",
        name: "",
        phone: "",
        lv: 10,
        rUser: true,
        wUser: false,
        rAdmin: true,
        wUserBankId: false,
        rDeposit: true,
        isDeposit: false,
        rWithdraw: true,
        isWithdraw: false,
        rReport: true,
        rxReward: false,
        wAdmin: false,
      }}
      onSubmit={(values) => {
        addAdmin(dispatch, values);
        handleClose();
      }}
    >
      {({ errors, touched, values, handleBlur, handleChange }) => (
        <Form>
          <Box mt={3} mb={3}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Typography className={classes.textColor} variant="h3">
                เพิ่มแอดมิน
              </Typography>
            </Grid>
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              Username
            </Typography>
            <TextField
              error={Boolean(touched.username && errors.username)}
              fullWidth
              size="small"
              helperText={touched.username && errors.username}
              margin="normal"
              name="username"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.username}
              variant="outlined"
              InputProps={{
                className: classes.textFieldColor,
              }}
              style={{ marginTop: 8 }}
            />
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              Password
            </Typography>
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              size="small"
              helperText={touched.password && errors.password}
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.password}
              variant="outlined"
              InputProps={{
                className: classes.textFieldColor,
              }}
              style={{ marginTop: 8 }}
            />
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              ชื่อ
            </Typography>
            <TextField
              error={Boolean(touched.name && errors.name)}
              fullWidth
              size="small"
              helperText={touched.name && errors.name}
              margin="normal"
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.name}
              variant="outlined"
              InputProps={{
                className: classes.textFieldColor,
              }}
              style={{ marginTop: 8 }}
            />
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              ทีม
            </Typography>
            <TextField
              error={Boolean(touched.team && errors.team)}
              fullWidth
              size="small"
              helperText={touched.team && errors.team}
              margin="normal"
              name="team"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.team}
              variant="outlined"
              InputProps={{
                className: classes.textFieldColor,
              }}
              style={{ marginTop: 8 }}
            />
          </Box>

          <Box mb={3}>
            <Typography className={classes.textColor} variant="h5">
              ตำแหน่ง
            </Typography>
            <NativeSelect
              fullWidth
              defaultValue={values.lv}
              id="lv"
              name="lv"
              onChange={handleChange}
              margin="dense"
              input={<BootstrapInput />}
              style={{ marginTop: 8 }}
            >
              <option value={10}>แอดมินทั่วไป</option>
              <option value={3}>การตลาด</option>
            </NativeSelect>
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              เบอร์โทร
            </Typography>
            <TextField
              error={Boolean(touched.phone && errors.phone)}
              fullWidth
              size="small"
              helperText={touched.phone && errors.phone}
              margin="normal"
              name="phone"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.phone}
              variant="outlined"
              InputProps={{
                className: classes.textFieldColor,
              }}
              style={{ marginTop: 8 }}
            />
          </Box>
          <hr />
          <br />
          <Box mb={2}>
            <Typography className={classes.textColor} variant="h4">
              กำหนดสิทธิ
            </Typography>
            <Grid
              spacing={2}
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              style={{ marginBottom: 16 }}
            >
              <Grid item md={6} xs={12}>
                <FormControl component="fieldset" className={classes.textColor}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          checked={values.rUser}
                          name="rUser"
                        />
                      }
                      label="ดูยูส"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          checked={values.wUser}
                          name="wUser"
                        />
                      }
                      label="แก้ไขยูส"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          checked={values.wUserBankId}
                          name="wUserBankId"
                        />
                      }
                      label="แก้ไขเลขบัญชี"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl component="fieldset" className={classes.textColor}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          checked={values.rAdmin}
                          name="rAdmin"
                        />
                      }
                      label="ดูยูสแอดมิน"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          checked={values.wAdmin}
                          name="wAdmin"
                        />
                      }
                      label="จัดการยูสแอดมิน"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl component="fieldset" className={classes.textColor}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          checked={values.rDeposit}
                          name="rDeposit"
                        />
                      }
                      label="ดูรายการฝาก"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          checked={values.isDeposit}
                          name="isDeposit"
                        />
                      }
                      label="เติมมือ"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl component="fieldset" className={classes.textColor}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          checked={values.rWithdraw}
                          name="rWithdraw"
                        />
                      }
                      label="ดูราการถอน"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          checked={values.isWithdraw}
                          name="isWithdraw"
                        />
                      }
                      label="ทำรายการถอน"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl component="fieldset" className={classes.textColor}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          checked={values.rxReward}
                          name="rxReward"
                        />
                      }
                      label="แลกของ"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControl component="fieldset" className={classes.textColor}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={handleChange}
                          checked={values.rReport}
                          name="rReport"
                        />
                      }
                      label="ดูรีพอร์ท"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Box>

          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ marginBottom: 16 }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              size="large"
              style={{ backgroundColor: "#B23CFD", color: "white" }}
            >
              ปิด
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              style={{ backgroundColor: "#4285f4", color: "white" }}
            >
              บันทึก
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );

  return (
    <div>
      <Button
        size="large"
        variant="contained"
        className={classes.mainBtn}
        onClick={handleClickOpen}
      >
        เพิ่มแอดมิน
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent className={classes.paper}>{body}</DialogContent>
      </Dialog>
    </div>
  );
};

AddAdmin.propTypes = {
  className: PropTypes.string,
};

export default AddAdmin;
