import navBarRoutes from "./navBarRoutes";

const WEB_URL = process.env.REACT_APP_WEB_URL;
const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const AGENT_ID = process.env.REACT_APP_AGENT_ID;
const TRANSFER_TO_WITHDRAW = process.env.REACT_APP_TRANSFER_TO_WITHDRAW;
const BAY_DEPOSIT = process.env.REACT_APP_BAY_DEPOSIT;
const KBANK_DEPOSIT = process.env.REACT_APP_KBANK_DEPOSIT;
const SCB_DEPOSIT = process.env.REACT_APP_SCB_DEPOSIT;
const SCB_WITHDRAW_1 = process.env.REACT_APP_SCB_WITHDRAW_1;
const SCB_WITHDRAW_2 = process.env.REACT_APP_SCB_WITHDRAW_2;
const EXTRA_DEPOSIT_1 = process.env.REACT_APP_EXTRA_DEPOSIT_1;
const EXTRA_DEPOSIT_1_BANK = process.env.REACT_APP_EXTRA_DEPOSIT_1_BANK;

const envToBoolean = (envVariable) => {
  return envVariable?.toUpperCase() === "TRUE" ? true : false;
};

const config = {
  webUrl: WEB_URL,
  actionUrl: SERVER_URL,
  logoName: CLIENT_ID,
  agentId: AGENT_ID,
  transferToWithdrawBank: envToBoolean(TRANSFER_TO_WITHDRAW),
  bayDeposit: {
    id: 1,
    bank: "BAY",
    active: envToBoolean(BAY_DEPOSIT),
  },
  kbankDeposit: {
    id: 2,
    bank: "KBANK",
    active: envToBoolean(KBANK_DEPOSIT),
  },
  scbDeposit: {
    id: 3,
    bank: "SCB",
    active: envToBoolean(SCB_DEPOSIT),
  },
  scbWithdraw1: {
    id: 4,
    bank: "scbWithdraw1",
    withdrawId: 1,
    active: envToBoolean(SCB_WITHDRAW_1),
  },
  scbWithdraw2: {
    id: 5,
    bank: "scbWithdraw2",
    withdrawId: 2,
    active: envToBoolean(SCB_WITHDRAW_2),
  },
  extraDeposit1: {
    id: 6,
    bank: EXTRA_DEPOSIT_1_BANK,
    active: envToBoolean(EXTRA_DEPOSIT_1),
    note: EXTRA_DEPOSIT_1_BANK + "2",
  },
  navBarRoutes,
};

export default config;
