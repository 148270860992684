import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-toastify/dist/ReactToastify.css';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import routes from './routes';
import { getCurrentUser } from './store/actions/authActions';
import { getSettingDetail } from './store/actions/settingAction';

const App = () => {
  const routing = useRoutes(routes);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const authLoading = useSelector((state) => state.auth.loading);

  useEffect(() => {
    getCurrentUser(dispatch);
    if (!authLoading && currentUser?.uuid) getSettingDetail(dispatch);
  }, [dispatch, currentUser?.uuid, authLoading]);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routing}
    </ThemeProvider>
  );
};

export default App;
