import moment from 'moment';

const initState = {
  reportUserList: null,
  reportDepositList: null,
  reportWithdrawList: null,
  reportOldUserList: null,
  reportUserDetailList: null,
  reportBankList: [],
  loading: false,
};

function getStatusDposit(status) {
  switch (status) {
    case 0:
      return 'ล้มเหลว';
    case 1:
      return 'สำเร็จ';
    case 3:
      return 'Not found';
    case 4:
      return 'Timeout';
    case 5:
      return 'สำเร็จ';
    case 6:
      return 'สำเร็จ';
    case 7:
      return 'สำเร็จ';
    case 8:
      return 'สำเร็จ';
    case 300:
      return 'ดึงกลับ';
    case 500:
      return 'ธนาคารแจ้งยกเลิก';
    default:
      return '';
  }
}

function getStatusWithdraw(status) {
  switch (status) {
    case 0:
      return 'ล้มเหลว';
    case 1:
      return 'สำเร็จ';
    case 2:
      return 'ยกเลิก';
    case 3:
      return 'สำเร็จ(ถอนมือ)';
    case 4:
      return 'สำเร็จ';
    case 5:
      return 'สำเร็จ';

    default:
      return '';
  }
}

const reportReducer = (state = initState, action) => {
  switch (action.type) {
    case 'getUserReportList':
      var users = action.res.data.data?.map((user) => {
        return {
          ...user,
          ...user.user_bank,
          ...user.user_credit,
          username: user?.user_auth?.username,
          imiId: user?.user_pass?.username,
          date: moment(user?.createdAt).format('DD-MM-YYYY HH:mm'),
          depositsLen: user.deposits?.length,
        };
      });

      return {
        ...state,
        reportUserList: users,
        // loading: false,
      };
    case 'getDepositReportList':
      var deposits = action.res.data.data?.map((deposit) => {
        var { user, bank, /*formBankId,*/ amount } = deposit;
        var username = user && user.user_pass.username,
          phone = user && user.phone;

        return {
          ...deposit,
          username,
          phone,
          amount,
          inviter: user?.inviter,
          statusWord: getStatusDposit(deposit.status),
          status: deposit.status,
          bankName: user?.user_bank?.bankName,
          bankId: user?.user_bank?.bankId,
          desBank: bank.bankName + ' ' + bank.bankId || '',
          transactionDate: moment(deposit.transactionDate).format(
            'DD-MM-YYYY HH:mm'
          ),
          // depositsLen: deposit.user?.deposits?.length,
          // depositsHistories: deposit.user?.deposits
          //   .map((el) => el.amount)
          //   .reduce((a, b) => a + b, 0)
          //   .toLocaleString("en"),
        };
      });

      return {
        ...state,
        reportDepositList: deposits,
        // loading: false,
      };
    case 'getWithdrawReportList':
      var withdraws = action.res.data.data?.map((withdraw) => {
        var { user, admin } = withdraw;
        return {
          ...withdraw,
          username: user?.user_pass?.username,
          name: admin?.name,
          phone: user?.phone,
          bankName: user?.user_bank?.bankName,
          bankId: user?.user_bank?.bankId,
          amount: withdraw?.amount,
          date: moment(withdraw.createdAt).format('DD-MM-YYYY HH:mm'),
          status: withdraw.status,
          statusWord: getStatusWithdraw(withdraw.status),
          // withdrawLen: withdraw?.user?.withdraw_histories?.length,
          // withdrawHistories: withdraw?.user?.withdraw_histories
          //   .map((el) => el.amount)
          //   .reduce((a, b) => a + b, 0)
          //   .toLocaleString("en"),
        };
      });

      return {
        ...state,
        reportWithdrawList: withdraws,
        // loading: false,
      };
    case 'getReportOldUserList':
      var oldUsers = action.res.data.data?.map((user) => {
        return {
          username: user?.username,
          name: user?.name,
          phone: user?.phone,
          createdAt: moment(user?.createdAt).format('DD/MM/YYYY H:mm'),
        };
      });

      return {
        ...state,
        reportOldUserList: oldUsers,
        // loading: false,
      };
    case 'getUserDetailList':
      var userDetailList = action.res.data.data?.map((user) => {
        return {
          username: user?.username,
          name: user?.name,
          phone: user?.phone,
          bankName: user?.bankName,
          bankId: user?.bankId,
        };
      });
      console.log(userDetailList);
      return {
        ...state,
        reportUserDetailList: userDetailList,
        // loading: false,
      };
    case 'getReportBankList':
      var reportBankListData = action.res.data.data;

      return {
        ...state,
        reportBankList: reportBankListData,
      };
    case 'onReportLoading':
      return {
        ...state,
        loading: true,
      };
    case 'onReportFinish':
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export default reportReducer;
