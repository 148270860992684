import { createMuiTheme, colors } from "@material-ui/core";
import shadows from "./shadows";
import typography from "./typography";

const theme = createMuiTheme({
  palette: {
    background: {
      dark: "#F4F6F8",
      black: "rgb(59, 57, 99)",
      grey1: "rgb(52, 49, 86)",
      grey2: "rgb(48, 45, 82)",
      // black: '#212121',
      // grey1: "#3e3e3e",
      // grey2: "#3b3b3b",
      default: colors.common.white,
      paper: colors.common.white,
      tableLine: "rgba(121, 118, 160, 0.6)",
      mainBtn: "rgb(69, 67, 120)",
    },
    primary: {
      main: "rgb(45, 41, 73)",
    },
    secondary: {
      main: colors.grey[800],
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
  },
  shadows,
  typography,
});

export default theme;
