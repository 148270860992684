import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Divider,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { Edit as EditIcon } from "@material-ui/icons";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  updateUserDetail,
  deleteUser,
} from "../../../store/actions/userAction";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.black,
  },
  textColor: {
    color: "white",
  },
  textFieldColor: {
    color: "#4285f4",
    backgroundColor: theme.palette.background.grey1,
  },
}));

const EditUserDetail = ({ className, userData, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDelete = () => {
    if (window.confirm("ยืนยันลบผู้ใช้หรือไม่")) {
      deleteUser(dispatch, userData.uuid);
      setOpen(false);
    }
  };

  const EditUserDetailSchema = Yup.object().shape({
    name: Yup.string().required("กรุณากรอกชื่อ"),
    phone: Yup.string().required("กรุณากรอกเบอร์โทร"),
    line: Yup.string().required("กรุณากรอกชื่อ"),
  });

  const body = (
    <Formik
      validationSchema={EditUserDetailSchema} //กำหนด validation
      initialValues={{
        //กำหนด initialValues
        name: userData.name,
        phone: userData.phone,
        line: userData.line,
        inviter: userData.inviter,
      }}
      onSubmit={(values) => {
        updateUserDetail(dispatch, values, userData.uuid);
        handleClose();
      }}
    >
      {({ errors, touched, values, handleBlur, handleChange }) => (
        <Form>
          <Box mb={3}>
            <Typography className={classes.textColor} variant="h2">
              แก้ไขข้อมูลผู้ใช้
            </Typography>
          </Box>
          <Divider style={{ backgroundColor: "white" }} />
          <Box mt={3} mb={3}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Typography className={classes.textColor} variant="h3">
                {userData.name}
              </Typography>
            </Grid>
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              ชื่อ : {userData.name}
            </Typography>
            <TextField
              error={Boolean(touched.name && errors.name)}
              fullWidth
              size="small"
              helperText={touched.name && errors.name}
              margin="normal"
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.name}
              variant="outlined"
              InputProps={{
                className: classes.textFieldColor,
              }}
              style={{ marginTop: 8 }}
            />
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              เบอร์โทร : {userData.phone}
            </Typography>
            <TextField
              error={Boolean(touched.phone && errors.phone)}
              fullWidth
              size="small"
              helperText={touched.phone && errors.phone}
              margin="normal"
              name="phone"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.phone}
              variant="outlined"
              InputProps={{
                className: classes.textFieldColor,
              }}
              style={{ marginTop: 8 }}
            />
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              LINE : {userData.line}
            </Typography>
            <TextField
              error={Boolean(touched.line && errors.line)}
              fullWidth
              size="small"
              helperText={touched.line && errors.line}
              margin="normal"
              name="line"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.line}
              variant="outlined"
              InputProps={{
                className: classes.textFieldColor,
              }}
              style={{ marginTop: 8 }}
            />
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              ผู้แนะนำ : {userData.inviter}
            </Typography>
            <TextField
              error={Boolean(touched.inviter && errors.inviter)}
              fullWidth
              size="small"
              helperText={touched.inviter && errors.inviter}
              margin="normal"
              name="inviter"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.inviter}
              variant="outlined"
              InputProps={{
                className: classes.textFieldColor,
              }}
              style={{ marginTop: 8 }}
            />
          </Box>

          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ marginBottom: 16 }}
          >
            {currentUser.lv === 0 && (
              <Button
                onClick={handleDelete}
                variant="contained"
                size="large"
                style={{ backgroundColor: "red", color: "white" }}
              >
                ลบผู้ใช้
              </Button>
            )}
            <Button
              onClick={handleClose}
              variant="contained"
              size="large"
              style={{ backgroundColor: "#B23CFD", color: "white" }}
            >
              ปิด
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              style={{ backgroundColor: "#4285f4", color: "white" }}
            >
              บันทึก
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );

  return (
    <div>
      <Button
        variant="contained"
        style={{ backgroundColor: "#4384f3", color: "white" }}
        onClick={handleClickOpen}
      >
        <EditIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent className={classes.paper}>{body}</DialogContent>
      </Dialog>
    </div>
  );
};

EditUserDetail.propTypes = {
  className: PropTypes.string,
  userData: PropTypes.object,
};

export default EditUserDetail;
