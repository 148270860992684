import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogContent,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  Divider,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { VpnKey as ChangePasswordIcon } from "@material-ui/icons";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { changeUserPassword } from "../../../store/actions/userAction";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.black,
  },
  textColor: {
    color: "white",
  },
  textFieldColor: {
    color: "#4285f4",
    backgroundColor: theme.palette.background.grey1,
  },
}));

const ChangeUserPassword = ({ className, userData, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ChangeUserPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "กรุณากรอกตัวอักษรไม่น้อยกว่า 6 ตัวอักษร")
      .max(255)
      .required("กรุณากรอก Password"),
  });

  const body = (
    <Formik
      validationSchema={ChangeUserPasswordSchema} //กำหนด validation
      initialValues={{
        //กำหนด initialValues
        password: "",
      }}
      onSubmit={(values) => {
        changeUserPassword(dispatch, values, userData.uuid);
        handleClose();
      }}
    >
      {({ errors, touched, values, handleBlur, handleChange }) => (
        <Form>
          <Box mb={3}>
            <Typography className={classes.textColor} variant="h2">
              เปลี่ยนรหัสผ่านผู้ใช้
            </Typography>
          </Box>
          <Divider style={{ backgroundColor: "white" }} />
          <Box mt={3} mb={3}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Typography className={classes.textColor} variant="h3">
                {userData.name}
              </Typography>
            </Grid>
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              รหัสผ่าน
            </Typography>
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              size="small"
              helperText={touched.password && errors.password}
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.password}
              variant="outlined"
              InputProps={{
                className: classes.textFieldColor,
              }}
              style={{ marginTop: 8 }}
            />
          </Box>

          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ marginBottom: 16 }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              size="large"
              style={{ backgroundColor: "#B23CFD", color: "white" }}
            >
              ปิด
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              style={{ backgroundColor: "#4285f4", color: "white" }}
            >
              บันทึก
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );

  return (
    <div>
      <Button
        variant="contained"
        style={{ backgroundColor: "#ff3547", color: "white" }}
        onClick={handleClickOpen}
      >
        <ChangePasswordIcon />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent className={classes.paper}>{body}</DialogContent>
      </Dialog>
    </div>
  );
};

ChangeUserPassword.propTypes = {
  className: PropTypes.string,
  userData: PropTypes.object,
};

export default ChangeUserPassword;
