import React from 'react';
import PropTypes from 'prop-types';
import EditBonusDetailSetting1 from './EditBonusDetailSetting1';
import EditBonusDetailSetting2 from './EditBonusDetailSetting2';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.black,
  },
  table: {
    '& .MuiTableSortLabel-root': {
      color: 'white',
    },
    '& .MuiTableCell-root': {
      color: 'white',
      borderWidth: 1,
      borderColor: theme.palette.background.tableLine,
      borderStyle: 'solid',
      padding: 8,
    },
  },
  tableRowColor1: {
    backgroundColor: theme.palette.background.grey1,
  },
  tableRowColor2: {
    backgroundColor: theme.palette.background.grey2,
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

const BonusDetailSetting = ({ settingDetail }) => {
  const classes = useStyles();

  return (
    <>
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableRowColor1}>
            <TableCell align="left">
              <Typography
                style={{ padding: 5 }}
                className={classes.textColor}
                variant="h4"
              >
                ตั้งค่าโบนัสช่วงที่ 1
              </Typography>
            </TableCell>
            <TableCell align="right">
              <EditBonusDetailSetting1 settingDetailData={settingDetail} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.tableRowColor2}>
            <TableCell width="30%">
              เลขลงท้ายรับโบนัสช่วงที่ 1 (เลขสตางค์)
            </TableCell>
            <TableCell>{settingDetail?.endNumberBonus}</TableCell>
          </TableRow>
          <TableRow className={classes.tableRowColor1}>
            <TableCell width="30%">โบนัสช่วงที่ 1 (% ของยอดฝาก)</TableCell>
            <TableCell>{settingDetail?.bonusRate}</TableCell>
          </TableRow>
          <TableRow className={classes.tableRowColor2}>
            <TableCell width="30%">เวลาเริ่มโบนัส (ช่วงที่ 1)</TableCell>
            <TableCell>{settingDetail.bonusTimeStart}</TableCell>
          </TableRow>
          <TableRow className={classes.tableRowColor1}>
            <TableCell width="30%">เวลาสิ้นสุดโบนัส (ช่วงที่ 1)</TableCell>
            <TableCell>{settingDetail.bonusTimeEnd}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <div className={classes.tableRowColor1} style={{ paddingTop: 50 }} />
      <Table className={classes.table}>
        <TableHead>
          <TableRow className={classes.tableRowColor1}>
            <TableCell align="left">
              <Typography
                style={{ padding: 5 }}
                className={classes.textColor}
                variant="h4"
              >
                ตั้งค่าโบนัสช่วงที่ 2
              </Typography>
            </TableCell>
            <TableCell align="right">
              <EditBonusDetailSetting2 settingDetailData={settingDetail} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow className={classes.tableRowColor2}>
            <TableCell width="30%">
              เลขลงท้ายรับโบนัสช่วงที่ 2 (เลขสตางค์)
            </TableCell>
            <TableCell>{settingDetail?.endNumberBonus2}</TableCell>
          </TableRow>
          <TableRow className={classes.tableRowColor1}>
            <TableCell width="30%">โบนัสช่วงที่ 2 (% ของยอดฝาก)</TableCell>
            <TableCell>{settingDetail?.bonusRate2}</TableCell>
          </TableRow>
          <TableRow className={classes.tableRowColor2}>
            <TableCell width="30%">เวลาเริ่มโบนัส (ช่วงที่ 2)</TableCell>
            <TableCell>{settingDetail.bonusTimeStart2}</TableCell>
          </TableRow>
          <TableRow className={classes.tableRowColor1}>
            <TableCell width="30%">เวลาสิ้นสุดโบนัส (ช่วงที่ 2)</TableCell>
            <TableCell>{settingDetail.bonusTimeEnd2}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

BonusDetailSetting.propTypes = {
  className: PropTypes.string,
  settingDetail: PropTypes.object,
};

export default BonusDetailSetting;
