import React, { useEffect, useState } from 'react';
// import moment from "moment";
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Grid,
  NativeSelect,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../../components/Page';
import LoadingCircle from '../../../utils/LoadingCircle';
import WheelSettingList from './WheelSettingList';
import { getWheelSettingList } from '../../../store/actions/settingAction';
import { Navigate } from 'react-router';
import BootstrapInput from '../../../components/BootstrapInput';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.black,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      color: 'white',
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
  },
  textColor: {
    color: 'white',
  },
  tableRowColor: {
    backgroundColor: theme.palette.background.grey1,
  },
}));

function WheelSetting() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const settingLoading = useSelector((state) => state.setting.loading);
  const wheelSettingList = useSelector(
    (state) => state.setting.wheelSettingList
  );
  const currentUser = useSelector((state) => state.auth.currentUser);
  const authLoading = useSelector((state) => state.auth.loading);
  const errorMessage = useSelector((state) => state.other.errorMessage);
  const [pageNum, setPageNum] = useState(1);
  const [perPage, setPerPage] = React.useState('20');

  useEffect(() => {
    getWheelSettingList(dispatch);
  }, [dispatch]);

  const handleChangePerPage = (event) => {
    setPerPage(event.target.value);
  };

  const handleChangePage = (e, value) => {
    setPageNum(value);
  };

  if (!authLoading && !currentUser) return <Navigate to="/login" />;
  else
    return (
      <Page className={classes.root} title="ตั้งค่าวงล้อ">
        <Container>
          {!errorMessage ? (
            !settingLoading ? (
              <>
                <Typography
                  className={classes.textColor}
                  style={{ marginBottom: 16 }}
                  variant="h2"
                >
                  Wheel Setting
                </Typography>
                {wheelSettingList?.length >= 1 ? (
                  <Box
                    borderRadius="borderRadius"
                    boxShadow={3}
                    p={2}
                    className={classes.tableRowColor}
                  >
                    <Grid
                      container
                      direction="row"
                      justify="flex-end"
                      alignItems="center"
                      style={{ marginBottom: 16 }}
                    >
                      <NativeSelect
                        id="demo-customized-select-native"
                        value={perPage}
                        margin="dense"
                        onChange={handleChangePerPage}
                        input={<BootstrapInput />}
                      >
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </NativeSelect>
                    </Grid>
                    <Box>
                      <WheelSettingList
                        wheelSettingDataList={wheelSettingList}
                        pageNum={pageNum}
                        perPage={perPage}
                      />
                    </Box>
                    <Box mt={3} display="flex" justifyContent="center">
                      <Pagination
                        color="secondary"
                        className={classes.pagination}
                        count={Math.ceil(wheelSettingList?.length / perPage)}
                        size="large"
                        onChange={handleChangePage}
                        variant="outlined"
                        shape="rounded"
                      />
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Box p={4} className={classes.tableRowColor}>
                      <Grid
                        container
                        direction="row"
                        justify="center"
                        alignItems="center"
                      >
                        <Typography className={classes.textColor} variant="h2">
                          ไม่พบข้อมูล Wheel Setting
                        </Typography>
                      </Grid>
                    </Box>
                  </>
                )}
              </>
            ) : (
              <LoadingCircle />
            )
          ) : (
            <>
              <Box p={4} className={classes.tableRowColor}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Typography className={classes.textColor} variant="h2">
                    {errorMessage}
                  </Typography>
                </Grid>
              </Box>
            </>
          )}
        </Container>
      </Page>
    );
}

export default WheelSetting;
