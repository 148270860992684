import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogContent,
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  NativeSelect,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { addManualDeposit } from '../../../store/actions/depositAction';
import BootstrapInput from '../../../components/BootstrapInput';
import config from '../../../configs';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.background.black,
  },
  textColor: {
    color: 'white',
  },
  textFieldColor: {
    color: '#4285f4',
    backgroundColor: theme.palette.background.grey1,
  },
  mainBtn: {
    backgroundColor: theme.palette.background.mainBtn,
    color: 'white',
  },
}));

const AddManualDeposit = ({ className, adminData, endData, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const depositBankList = useSelector((state) => state.deposit.depositBankList);
  const [open, setOpen] = React.useState(false);
  var dateNow = new Date();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const AddManualDepositSchema = Yup.object().shape({
    amount: Yup.number()
      .min(1, 'กรุณากรอกจำนวนเงินให้ถูกต้อง')
      .required('กรุณากรอกจำนวนเงิน'),
    username: Yup.string()
      .min(4, 'กรุณากรอกข้อมูลรหัสให้ถูกต้อง')
      .max(20)
      .required('กรุณากรอกรหัส'),
    day: Yup.number()
      .min(1, 'กรุณากรอกวันที่ให้ถูกต้อง')
      .required('กรุณากรอกจำนวนเงิน'),
    month: Yup.number()
      .min(1, 'กรุณากรอกเดือนให้ถูกต้อง')
      .required('กรุณากรอกจำนวนเงิน'),
    year: Yup.number()
      .min(1, 'กรุณากรอกปีให้ถูกต้อง')
      .required('กรุณากรอกจำนวนเงิน'),
    hour: Yup.number()
      .min(0, 'กรุณากรอกชั่วโมงให้ถูกต้อง')
      .required('กรุณากรอกจำนวนเงิน'),
    minute: Yup.number()
      .min(0, 'กรุณากรอกนาทีให้ถูกต้อง')
      .required('กรุณากรอกจำนวนเงิน'),
    accountNo: Yup.string().max(13).required('เลขบัญชีที่โอนเข้า'),
    note: Yup.string().max(255).required('กรุณากรอกหมายเหตุ'),
  });

  const body = (
    <Formik
      validationSchema={AddManualDepositSchema} //กำหนด validation
      initialValues={{
        //กำหนด initialValues
        amount: 0,
        username: config.agentId,
        day: dateNow.getDate(),
        month: dateNow.getMonth() + 1,
        year: dateNow.getFullYear(),
        hour: dateNow.getHours(),
        minute: dateNow.getMinutes(),
        note: '',
        accountNo: '',
      }}
      onSubmit={(values) => {
        addManualDeposit(dispatch, values, endData);
        handleClose();
      }}
    >
      {({ errors, touched, values, handleBlur, handleChange }) => (
        <Form>
          <Box mt={3} mb={3}>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Typography className={classes.textColor} variant="h3">
                เติมมือ
              </Typography>
            </Grid>
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              จำนวนเงิน (บาท)
            </Typography>
            <TextField
              error={Boolean(touched.amount && errors.amount)}
              fullWidth
              size="small"
              helperText={touched.amount && errors.amount}
              margin="normal"
              name="amount"
              onBlur={handleBlur}
              onChange={handleChange}
              type="number"
              value={values.amount}
              variant="outlined"
              InputProps={{
                className: classes.textFieldColor,
              }}
              style={{ marginTop: 8 }}
            />
          </Box>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              รหัส
            </Typography>
            <TextField
              error={Boolean(touched.username && errors.username)}
              fullWidth
              size="small"
              helperText={touched.username && errors.username}
              margin="normal"
              name="username"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.username}
              variant="outlined"
              InputProps={{
                className: classes.textFieldColor,
              }}
              style={{ marginTop: 8 }}
            />
          </Box>

          <Typography
            style={{ marginBottom: 16, color: '#fb3' }}
            className={classes.textColor}
            variant="h5"
          >
            ***กรอกวันเวลาตามสลิป
          </Typography>
          <Grid
            spacing={2}
            container
            direction="row"
            justify="space-between"
            alignItems="center"
          >
            <Grid item md={4} xs={12}>
              <Box mb={2}>
                <Typography className={classes.textColor} variant="h5">
                  วัน
                </Typography>
                <TextField
                  error={Boolean(touched.day && errors.day)}
                  fullWidth
                  size="small"
                  helperText={touched.day && errors.day}
                  margin="normal"
                  name="day"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="number"
                  value={values.day}
                  variant="outlined"
                  InputProps={{
                    className: classes.textFieldColor,
                  }}
                  style={{ marginTop: 8 }}
                />
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box mb={2}>
                <Typography className={classes.textColor} variant="h5">
                  เดือน
                </Typography>
                <TextField
                  error={Boolean(touched.month && errors.month)}
                  fullWidth
                  size="small"
                  helperText={touched.month && errors.month}
                  margin="normal"
                  name="month"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="number"
                  value={values.month}
                  variant="outlined"
                  InputProps={{
                    className: classes.textFieldColor,
                  }}
                  style={{ marginTop: 8 }}
                />
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box mb={2}>
                <Typography className={classes.textColor} variant="h5">
                  ปี
                </Typography>
                <TextField
                  error={Boolean(touched.year && errors.year)}
                  fullWidth
                  size="small"
                  helperText={touched.year && errors.year}
                  margin="normal"
                  name="year"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="number"
                  value={values.year}
                  variant="outlined"
                  InputProps={{
                    className: classes.textFieldColor,
                  }}
                  style={{ marginTop: 8 }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid
            spacing={2}
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ marginBottom: 16 }}
          >
            <Grid item md={6} xs={12}>
              <Box mb={2}>
                <Typography className={classes.textColor} variant="h5">
                  ชั่วโมง
                </Typography>
                <TextField
                  error={Boolean(touched.hour && errors.hour)}
                  fullWidth
                  size="small"
                  helperText={touched.hour && errors.hour}
                  margin="normal"
                  name="hour"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="number"
                  value={values.hour}
                  variant="outlined"
                  InputProps={{
                    className: classes.textFieldColor,
                  }}
                  style={{ marginTop: 8 }}
                />
              </Box>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box mb={2}>
                <Typography className={classes.textColor} variant="h5">
                  นาที
                </Typography>
                <TextField
                  error={Boolean(touched.minute && errors.minute)}
                  fullWidth
                  size="small"
                  helperText={touched.minute && errors.minute}
                  margin="normal"
                  name="minute"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="number"
                  value={values.minute}
                  variant="outlined"
                  InputProps={{
                    className: classes.textFieldColor,
                  }}
                  style={{ marginTop: 8 }}
                />
              </Box>
            </Grid>
          </Grid>

          <Box mb={2}>
            <Typography className={classes.textColor} variant="h5">
              หมายเหตุ
            </Typography>
            <TextField
              error={Boolean(touched.note && errors.note)}
              fullWidth
              size="small"
              helperText={touched.note && errors.note}
              margin="normal"
              name="note"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={values.note}
              variant="outlined"
              InputProps={{
                className: classes.textFieldColor,
              }}
              style={{ marginTop: 8 }}
            />
          </Box>

          <Box mb={3}>
            <Typography className={classes.textColor} variant="h5">
              เลขบัญชีที่โอนเข้า
            </Typography>
            <NativeSelect
              fullWidth
              defaultValue={values.accountNo}
              id="accountNo"
              name="accountNo"
              onChange={handleChange}
              margin="dense"
              input={<BootstrapInput />}
              style={{ marginTop: 8 }}
            >
              {depositBankList.map((bankData) => (
                <option
                  key={bankData.id}
                  value={bankData.bankId}
                >{`${bankData.bankName} || ${bankData.bankId} || ${bankData.bank}`}</option>
              ))}
            </NativeSelect>
          </Box>

          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ marginBottom: 16 }}
          >
            <Button
              onClick={handleClose}
              variant="contained"
              size="large"
              style={{ backgroundColor: '#B23CFD', color: 'white' }}
            >
              ปิด
            </Button>
            <Button
              type="submit"
              variant="contained"
              size="large"
              style={{ backgroundColor: '#4285f4', color: 'white' }}
            >
              บันทึก
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  );

  return (
    <div>
      <Button
        size="large"
        variant="contained"
        className={classes.mainBtn}
        onClick={handleClickOpen}
      >
        เติมมือ
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent className={classes.paper}>{body}</DialogContent>
      </Dialog>
    </div>
  );
};

AddManualDeposit.propTypes = {
  className: PropTypes.string,
  endData: PropTypes.number,
};

export default AddManualDeposit;
