import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  makeStyles,
} from '@material-ui/core';
import GeneralSetting from './GeneralSetting';
import DepositBonusSetting from './DepositBonusSetting';
import BonusSetting from './BonusSetting';
import BonusDetailSetting from './BonusDetailSetting';

const useStyles = makeStyles((theme) => ({
  root: {
    // backgroundColor: theme.palette.background.black,
  },
  table: {
    '& .MuiTableSortLabel-root': {
      color: 'white',
    },
    '& .MuiTableCell-root': {
      color: 'white',
      borderWidth: 1,
      borderColor: theme.palette.background.tableLine,
      borderStyle: 'solid',
      padding: 8,
    },
  },
  tableRowColor1: {
    backgroundColor: theme.palette.background.grey1,
  },
  tableRowColor2: {
    backgroundColor: theme.palette.background.grey2,
  },
  actions: {
    justifyContent: 'flex-end',
  },
}));

const SettingDetail = ({ className, settingDetail, ...rest }) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box style={{ backgroundColor: '#000' }} minWidth={800}>
          <GeneralSetting settingDetail={settingDetail} />
          <div className={classes.tableRowColor1} style={{ paddingTop: 50 }} />
          <DepositBonusSetting settingDetail={settingDetail} />
          <div className={classes.tableRowColor1} style={{ paddingTop: 50 }} />
          <BonusSetting settingDetail={settingDetail} />
          <div className={classes.tableRowColor1} style={{ paddingTop: 50 }} />
          <BonusDetailSetting settingDetail={settingDetail} />
        </Box>
      </PerfectScrollbar>
    </Box>
  );
};

SettingDetail.propTypes = {
  className: PropTypes.string,
  settingDetail: PropTypes.object,
};

export default SettingDetail;
