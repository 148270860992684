const initState = {
  sumBayDeposit_Shift1: 0,
  sumBayDeposit_Shift2: 0,
  sumExtraDeposit1_Shift1: 0,
  sumExtraDeposit1_Shift2: 0,
  sumKbankDeposit_Shift1: 0,
  sumKbankDeposit_Shift2: 0,
  sumScbDeposit_Shift1: 0,
  sumScbDeposit_Shift2: 0,
  sumScbWithdraw1_Shift1: 0,
  sumScbWithdraw1_Shift2: 0,
  sumScbWithdraw2_Shift1: 0,
  sumScbWithdraw2_Shift2: 0,
  sumManualWithdraw_Shift1: 0,
  sumManualWithdraw_Shift2: 0,
  sumChart_Shift1: [],
  sumChart_Shift2: [],
  loading: true,
};

function compare(a, b) {
  if (a.bankType < b.bankType) {
    return -1;
  }
  if (a.bankType > b.bankType) {
    return 1;
  }
  return 0;
}

const dashboardReducer = (state = initState, action) => {
  switch (action.type) {
    case 'getSumBayDeposit_Shift1':
      return {
        ...state,
        sumBayDeposit_Shift1: action.res.data.data,
        loading: false,
      };
    case 'getSumBayDeposit_Shift2':
      return {
        ...state,
        sumBayDeposit_Shift2: action.res.data.data,
        loading: false,
      };
    case 'getSumExtraDeposit1_Shift1':
      return {
        ...state,
        sumExtraDeposit1_Shift1: action.res.data.data,
        loading: false,
      };
    case 'getSumExtraDeposit1_Shift2':
      return {
        ...state,
        sumExtraDeposit1_Shift2: action.res.data.data,
        loading: false,
      };
    case 'getSumKbankDeposit_Shift1':
      return {
        ...state,
        sumKbankDeposit_Shift1: action.res.data.data,
        loading: false,
      };
    case 'getSumKbankDeposit_Shift2':
      return {
        ...state,
        sumKbankDeposit_Shift2: action.res.data.data,
        loading: false,
      };
    case 'getSumScbDeposit_Shift1':
      return {
        ...state,
        sumScbDeposit_Shift1: action.res.data.data,
        loading: false,
      };
    case 'getSumScbDeposit_Shift2':
      return {
        ...state,
        sumScbDeposit_Shift2: action.res.data.data,
        loading: false,
      };
    case 'getSumScbWithdraw1_Shift1':
      return {
        ...state,
        sumScbWithdraw1_Shift1: action.res.data.data,
        loading: false,
      };
    case 'getSumScbWithdraw1_Shift2':
      return {
        ...state,
        sumScbWithdraw1_Shift2: action.res.data.data,
        loading: false,
      };
    case 'getSumScbWithdraw2_Shift1':
      return {
        ...state,
        sumScbWithdraw2_Shift1: action.res.data.data,
        loading: false,
      };
    case 'getSumScbWithdraw2_Shift2':
      return {
        ...state,
        sumScbWithdraw2_Shift2: action.res.data.data,
        loading: false,
      };
    case 'getSumManualWithdraw_Shift1':
      return {
        ...state,
        sumManualWithdraw_Shift1: action.res.data.data,
        loading: false,
      };
    case 'getSumManualWithdraw_Shift2':
      return {
        ...state,
        sumManualWithdraw_Shift2: action.res.data.data,
        loading: false,
      };
    case 'getSumChart_Shift1':
      return {
        ...state,
        sumChart_Shift1: action.res.data.data.sort(compare),
        loading: false,
      };
    case 'getSumChart_Shift2':
      return {
        ...state,
        sumChart_Shift2: action.res.data.data.sort(compare),
        loading: false,
      };
    default:
      return state;
  }
};
export default dashboardReducer;
