import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Box,
  Container,
  makeStyles,
  TextField,
  Typography,
  Grid,
  NativeSelect,
  Button,
  Tab,
  Tabs,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../../../components/Page';
import LoadingCircle from '../../../utils/LoadingCircle';
import DepositList from './DepositList';
import { getDepositBankList, getDepositList } from '../../../store/actions/depositAction';
import { Navigate } from 'react-router';
import BootstrapInput from '../../../components/BootstrapInput';
import AddManualDeposit from './AddManualDeposit';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.black,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  pagination: {
    '& .MuiPaginationItem-root': {
      color: 'white',
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
    '& label.Mui-focused': {
      color: theme.palette.background.tableLine,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.background.tableLine,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.background.tableLine,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.background.tableLine,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.background.tableLine,
      },
    },
  },
  textColor: {
    color: 'white',
  },
  tableRowColor: {
    backgroundColor: theme.palette.background.grey1,
  },
}));

function Deposit() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const depositLoading = useSelector((state) => state.deposit.loading);
  const depositList = useSelector((state) => state.deposit.depositList);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const authLoading = useSelector((state) => state.auth.loading);
  const errorMessage = useSelector((state) => state.other.errorMessage);
  // const [startData, setStartData] = useState(1);
  const [endData, setEndData] = useState(1000);
  const [pageNum, setPageNum] = useState(1);
  const [perPage, setPerPage] = useState('10');
  const [search, setSearch] = useState('');

  const [lastRefreshTime, setLastRefreshTime] = useState('');

  const [tab, setTab] = React.useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
    setPageNum(1);
    // setSearch('');
  };

  useEffect(() => {
    getDepositList(dispatch, 0, endData);
    getDepositBankList(dispatch);

    setLastRefreshTime(moment(Date.now()).format('H:mm:ss'));

    const interval = setInterval(() => {
      getDepositList(dispatch, 0, endData);

      setLastRefreshTime(moment(Date.now()).format('H:mm:ss'));
    }, 60000);

    return () => clearInterval(interval);
  }, [dispatch, endData]);

  const handleChangePerPage = (event) => {
    setPerPage(event.target.value);
  };

  const handleChangePage = (e, value) => {
    setPageNum(value);
  };

  const onSearch = (e) => {
    setSearch(e.target.value);
    setPageNum(1);
  };

  const handleClickShowMore = () => {
    setEndData(10000);
    dispatch({ type: 'onDepositLoading' });
    // getDepositList(dispatch, 0, 10000);
  };

  const handleClickShowLess = () => {
    setEndData(1000);
    dispatch({ type: 'onDepositLoading' });
    // getDepositList(dispatch, 0, 1000);
  };

  const searchFilter = (searchStr) => {
    if (searchStr) {
      return (
        searchStr
          .toString()
          .toLowerCase()
          .indexOf(search.toString().toLowerCase()) !== -1
      );
    }
  };

  const failStatus = [0, 3, 4, 300, 500];

  var depositDataListFilter = depositList
    ?.filter((deposit) =>
      tab === 0
        ? failStatus.includes(deposit.status)
        : !failStatus.includes(deposit.status)
    )
    ?.filter((depositData) => {
      return (
        searchFilter(
          moment(depositData?.createdAt).format('DD/MM/YYYY H:mm')
        ) ||
        searchFilter(depositData?.user?.inviter) ||
        searchFilter(depositData?.user?.user_pass?.username) ||
        searchFilter(depositData?.formBankId) ||
        searchFilter(depositData?.amount) ||
        searchFilter(depositData?.bank?.bankId) ||
        searchFilter(depositData?.note)
      );
    });

  if (!authLoading && !currentUser) return <Navigate to="/login" />;
  else
    return (
      <Page className={classes.root} title="ฝาก">
        <Container>
          {!errorMessage ? (
            !depositLoading ? (
              <>
                <Typography
                  className={classes.textColor}
                  style={{ marginBottom: 16 }}
                  variant="h2"
                >
                  รายการฝากเงิน
                </Typography>
                <Box
                  borderRadius="borderRadius"
                  boxShadow={3}
                  p={2}
                  className={classes.tableRowColor}
                >
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    style={{ marginBottom: 20 }}
                  >
                    <Typography className={classes.textColor} variant="h3">
                      เวลาอัพเดทล่าสุด :{' '}
                      <span style={{ color: 'blueviolet' }}>
                        {lastRefreshTime}
                      </span>
                    </Typography>
                    <AddManualDeposit endData={endData} />
                  </Grid>
                  {depositList?.length >= 1 ? (
                    <>
                      <Grid
                        container
                        direction="row"
                        justify="space-between"
                        alignItems="center"
                        style={{ marginBottom: 16 }}
                      >
                        <Grid item>
                          <TextField
                            label="ค้นหาในตาราง"
                            onChange={(e) => onSearch(e)}
                            className={classes.textField}
                            margin="dense"
                            variant="outlined"
                            InputProps={{
                              className: classes.textColor,
                            }}
                            style={{
                              margin: 0,
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Grid
                            spacing={2}
                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                          >
                            <Grid item>
                              {endData !== 10000 ? (
                                <Button
                                  variant="contained"
                                  style={{
                                    backgroundColor: '#33b5e5',
                                    color: 'white',
                                  }}
                                  onClick={handleClickShowMore}
                                >
                                  ดูเพิ่มเติม
                                </Button>
                              ) : (
                                <Button
                                  variant="contained"
                                  style={{
                                    backgroundColor: '#ff3547',
                                    color: 'white',
                                  }}
                                  onClick={handleClickShowLess}
                                >
                                  ดูน้อยลง
                                </Button>
                              )}
                            </Grid>
                            <Grid item>
                              <NativeSelect
                                id="demo-customized-select-native"
                                value={perPage}
                                margin="dense"
                                onChange={handleChangePerPage}
                                input={<BootstrapInput />}
                              >
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                              </NativeSelect>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>

                      <Box
                        style={{
                          marginBottom: '20px',
                          width: '100%',
                        }}
                      >
                        <Tabs
                          value={tab}
                          TabIndicatorProps={{
                            style: { display: 'none' },
                          }}
                          onChange={handleChange}
                          centered
                        >
                          <Tab
                            style={{
                              borderTopLeftRadius: 10,
                              borderBottomLeftRadius: 10,
                              width: '100%',
                              color: 'red',
                              fontSize: '30px',
                              backgroundColor:
                                tab === 0
                                  ? 'rgb(69, 67, 120)'
                                  : 'rgb(48, 45, 82)',
                            }}
                            label="ไม่สำเร็จ"
                            key={0}
                          />
                          <Tab
                            style={{
                              borderTopRightRadius: 10,
                              borderBottomRightRadius: 10,
                              width: '100%',
                              color: '#0BDA51',
                              fontSize: '30px',
                              backgroundColor:
                                tab === 1
                                  ? 'rgb(69, 67, 120)'
                                  : 'rgb(48, 45, 82)',
                            }}
                            label="สำเร็จ"
                            key={1}
                          />
                        </Tabs>
                      </Box>

                      <DepositList
                        depositDataList={depositDataListFilter}
                        search={search}
                        pageNum={pageNum}
                        perPage={perPage}
                        endData={endData}
                      />
                      <Box mt={3} display="flex" justifyContent="center">
                        <Pagination
                          color="secondary"
                          className={classes.pagination}
                          count={Math.ceil(
                            depositDataListFilter?.length / perPage
                          )}
                          size="large"
                          onChange={handleChangePage}
                          variant="outlined"
                          shape="rounded"
                        />
                      </Box>
                    </>
                  ) : (
                    <>
                      <Box p={4} className={classes.tableRowColor}>
                        <Grid
                          container
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          <Typography
                            className={classes.textColor}
                            variant="h2"
                          >
                            ไม่พบข้อมูลรายการฝากเงิน
                          </Typography>
                        </Grid>
                      </Box>
                    </>
                  )}
                </Box>
              </>
            ) : (
              <LoadingCircle />
            )
          ) : (
            <>
              <Box p={4} className={classes.tableRowColor}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Typography className={classes.textColor} variant="h2">
                    {errorMessage}
                  </Typography>
                </Grid>
              </Box>
            </>
          )}
        </Container>
      </Page>
    );
}

export default Deposit;
