const rewardColumnName = [
  {
    label: "#",
    field: "id",
    sort: "asc",
    width: 200,
  },
  {
    label: "วันที่แลก",
    field: "date",
    sort: "asc",
    width: 100,
  },
  {
    label: "ของที่แลก",
    field: "title",
    sort: "asc",
    width: 150,
  },
  {
    label: "พอยท์ที่ใช้",
    field: "amount",
    sort: "asc",
    width: 150,
  },
  {
    label: "สถานะ",
    field: "action",
    sort: "asc",
    width: 200,
  },
];
export default rewardColumnName;
