import React from 'react';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Bar } from 'react-chartjs-2';
import { Box, Card, Typography, makeStyles, Divider } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
  getSumChart_Shift2,
  getSumManualWithdraw_Shift2,
} from '../../../store/actions/dashboardAction';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.black,
  },
  boxColor: {
    backgroundColor: theme.palette.background.mainBtn,
  },
  textColor: {
    color: 'white',
  },
}));

const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const bankColor = new Map([
  ['KBANK', '#00a950'],
  ['SCB', '#462379'],
  ['BAY', '#ffd400'],
  ['KTB', '#00A4E4'],
]);

const Graph2 = ({ className, timeStart, timeEnd, ...rest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const sumChart_Shift2 = useSelector(
    (state) => state.dashboard.sumChart_Shift2
  );
  const sumManualWithdraw_Shift2 = useSelector(
    (state) => state.dashboard.sumManualWithdraw_Shift2
  );

  const reportRawData = sumChart_Shift2?.map((itemData) => {
    const accType = itemData.bankType === 'deposit' ? 'ฝาก' : 'ถอน';
    const bankData = {
      label: `${accType} ${itemData.bankName} ${itemData.bankId}`,
      backgroundColor: bankColor.get(itemData.bankName),
      borderColor: bankColor.get(itemData.bankName),
      borderWidth: 1,
      data:
        itemData.bankType === 'deposit'
          ? [itemData.amount, 0]
          : [0, itemData.amount],
    };
    return bankData;
  });

  const manualWithdrawData = {
    label: 'ถอนมือ',
    backgroundColor: 'grey',
    borderColor: 'grey',
    borderWidth: 1,
    data: [0, (sumManualWithdraw_Shift2 || 0)?.toFixed(0)],
  };

  useEffect(() => {
    getSumChart_Shift2(dispatch, timeStart, timeEnd);
    getSumManualWithdraw_Shift2(dispatch, timeStart, timeEnd);

    // eslint-disable-next-line
  }, [dispatch]);

  const labels = ['ฝาก', 'ถอน'];
  const reportData = {
    labels,
    datasets: [...reportRawData, manualWithdrawData],
  };

  return (
    <>
      <Card className={clsx(classes.root, className)} {...rest}>
        <PerfectScrollbar>
          <Box
            borderRadius="borderRadius"
            boxShadow={3}
            p={2}
            className={classes.boxColor}
          >
            <Bar
              data={reportData}
              options={{
                title: {
                  display: true,
                  text: `${moment(timeStart).format(
                    'DD/MM/YYYY H:mm'
                  )} - ${moment(timeEnd).format('DD/MM/YYYY H:mm')}`,
                  fontSize: 20,
                  fontColor: 'white',
                },
                legend: {
                  display: true,
                  position: 'right',
                  labels: {
                    fontColor: 'white',
                  },
                },
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        fontColor: 'white',
                        beginAtZero: true,
                      },
                    },
                  ],
                  xAxes: [
                    {
                      ticks: {
                        fontColor: 'white',
                        beginAtZero: true,
                      },
                    },
                  ],
                },
              }}
            />
            <Divider
              style={{
                backgroundColor: 'rgb(121, 118, 160)',
                marginTop: 16,
                marginBottom: 16,
              }}
            />
            <Typography
              className={classes.textColor}
              style={{ marginBottom: 16 }}
              variant="h3"
            >
              ยอดฝาก :{' '}
              {numberWithCommas(
                sumChart_Shift2
                  .filter((bankData) => bankData.bankType === 'deposit')
                  .reduce((sum, bankData) => {
                    return sum + bankData.amount;
                  }, 0)
                  .toFixed(0)
              )}
            </Typography>
            <Typography
              className={classes.textColor}
              style={{ marginBottom: 16 }}
              variant="h3"
            >
              ยอดถอน :{' '}
              {numberWithCommas(
                (
                  sumChart_Shift2
                    .filter((bankData) => bankData.bankType === 'withdraw')
                    .reduce((sum, bankData) => {
                      return sum + bankData.amount;
                    }, 0) + sumManualWithdraw_Shift2
                )?.toFixed(0)
              )}
            </Typography>
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  );
};

Graph2.propTypes = {
  className: PropTypes.string,
  timeStart: PropTypes.any,
  timeEnd: PropTypes.any,
};
export default Graph2;
