const initState = {
  userList: null,
  userDetail: null,
  sumUserDeposit: 0,
  sumUserWithdraw: 0,
  userRewardList: null,
  userDepositList: null,
  userDepositListFromIMI: null,
  userWithdrawList: null,
  userWheelrawList: null,
  loading: true,
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case 'getUserList':
      var users = action.res.data.data;
      var userData = [];

      users?.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      users.forEach((user) => {
        userData.push(user);
      });

      return {
        ...state,
        userList: userData,
        loading: false,
      };
    case 'getUserListByOther':
      return {
        ...state,
        userList: action.allQuery,
        loading: false,
      };
    case 'getUserDetail':
      var userDetail = action.res.data.data[0];
      userDetail = { ...userDetail, credit: action.res.data?.credit };

      return {
        ...state,
        userDetail: userDetail,
        loading: false,
      };
    case 'getSumUserDeposit':
      var sumUserDeposit = action.res.data.data;

      return {
        ...state,
        sumUserDeposit: sumUserDeposit,
        loading: false,
      };
    case 'getSumUserWithdraw':
      var sumUserWithdraw = action.res.data.data;

      return {
        ...state,
        sumUserWithdraw: sumUserWithdraw,
        loading: false,
      };
    case 'getUserRewardList':
      var userRewardList = action.res.data.data;

      return {
        ...state,
        userRewardList: userRewardList,
        loading: false,
      };
    case 'getUserDepositList':
      var userDeposit = action.res.data.data;
      var userDepositData = [];

      userDeposit?.sort(function (a, b) {
        return new Date(b.CreationTime) - new Date(a.CreationTime);
      });

      userDeposit?.forEach((userDepost) => {
        userDepositData.push(userDepost);
      });

      return {
        ...state,
        userDepositList: userDepositData,
        loading: false,
      };
    case 'getUserDepositListFromIMI':
      var userDepositFromIMI = action?.res?.data?.data?.Result?.Records;
      var userDepositFromIMIData = [];

      userDeposit?.sort(function (a, b) {
        return new Date(b.CreationTime) - new Date(a.CreationTime);
      });

      userDepositFromIMI?.forEach((userDepost) => {
        userDepositFromIMIData.push(userDepost);
      });

      return {
        ...state,
        userDepositListFromIMI: userDepositFromIMIData,
        loading: false,
      };
    case 'getUserWithdrawList':
      var userWithdraw = action?.res?.data?.data?.Result?.Records;
      var userWithdrawData = [];

      userWithdraw?.sort(function (a, b) {
        return new Date(b.CreationTime) - new Date(a.CreationTime);
      });

      userWithdraw?.forEach((userDepost) => {
        userWithdrawData.push(userDepost);
      });

      return {
        ...state,
        userWithdrawList: userWithdrawData,
        loading: false,
      };
    case 'getUserWheelList':
      var userWheel = action.res.data.data;
      var userWheelData = [];

      userWheel?.sort(function (a, b) {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      userWheel?.forEach((userDepost) => {
        userWheelData.push(userDepost);
      });

      return {
        ...state,
        userWheelList: userWheelData,
        loading: false,
      };
    case 'onUserLoading':
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};
export default userReducer;
