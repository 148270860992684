import Axios from '../../utils/axiosConfig';
import configs from '../../configs';

export const getDepositReportList1 = (dispatch, timeStart, timeEnd) => {
  var body = { start: timeStart, end: timeEnd };

  Axios.post('/secure/report/list/deposit', body).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getDepositReportList1', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getDepositReportList2 = (dispatch, timeStart, timeEnd) => {
  var body = { start: timeStart, end: timeEnd };

  Axios.post('/secure/report/list/deposit', body).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getDepositReportList2', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getSumBayDeposit_Shift1 = (dispatch, timeStart, timeEnd) => {
  var body = { start: timeStart, end: timeEnd, bankid: configs.bayDeposit.id };

  Axios.post('/secure/report/deposit', body).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getSumBayDeposit_Shift1', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getSumBayDeposit_Shift2 = (dispatch, timeStart, timeEnd) => {
  var body = { start: timeStart, end: timeEnd, bankid: configs.bayDeposit.id };

  Axios.post('/secure/report/deposit', body).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getSumBayDeposit_Shift2', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getSumExtraDeposit1_Shift1 = (dispatch, timeStart, timeEnd) => {
  var body = {
    start: timeStart,
    end: timeEnd,
    bankid: configs.extraDeposit1.id,
  };

  Axios.post('/secure/report/deposit', body).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getSumExtraDeposit1_Shift1', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getSumExtraDeposit1_Shift2 = (dispatch, timeStart, timeEnd) => {
  var body = {
    start: timeStart,
    end: timeEnd,
    bankid: configs.extraDeposit1.id,
  };

  Axios.post('/secure/report/deposit', body).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getSumExtraDeposit1_Shift2', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getSumKbankDeposit_Shift1 = (dispatch, timeStart, timeEnd) => {
  var body = {
    start: timeStart,
    end: timeEnd,
    bankid: configs.kbankDeposit.id,
  };

  Axios.post('/secure/report/deposit', body).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getSumKbankDeposit_Shift1', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getSumKbankDeposit_Shift2 = (dispatch, timeStart, timeEnd) => {
  var body = {
    start: timeStart,
    end: timeEnd,
    bankid: configs.kbankDeposit.id,
  };

  Axios.post('/secure/report/deposit', body).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getSumKbankDeposit_Shift2', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getSumScbDeposit_Shift1 = (dispatch, timeStart, timeEnd) => {
  var body = {
    start: timeStart,
    end: timeEnd,
    bankid: configs.scbDeposit.id,
  };

  Axios.post('/secure/report/deposit', body).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getSumScbDeposit_Shift1', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getSumScbDeposit_Shift2 = (dispatch, timeStart, timeEnd) => {
  var body = {
    start: timeStart,
    end: timeEnd,
    bankid: configs.scbDeposit.id,
  };

  Axios.post('/secure/report/deposit', body).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getSumScbDeposit_Shift2', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getSumScbWithdraw1_Shift1 = (dispatch, timeStart, timeEnd) => {
  var body = {
    start: timeStart,
    end: timeEnd,
    status: configs.scbWithdraw1.id,
  };

  Axios.post('/secure/report/withdraw/status', body).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getSumScbWithdraw1_Shift1', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getSumScbWithdraw1_Shift2 = (dispatch, timeStart, timeEnd) => {
  var body = {
    start: timeStart,
    end: timeEnd,
    status: configs.scbWithdraw1.id,
  };

  Axios.post('/secure/report/withdraw/status', body).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getSumScbWithdraw1_Shift2', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getSumScbWithdraw2_Shift1 = (dispatch, timeStart, timeEnd) => {
  var body = {
    start: timeStart,
    end: timeEnd,
    status: configs.scbWithdraw2.id,
  };

  Axios.post('/secure/report/withdraw/status', body).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getSumScbWithdraw2_Shift1', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getSumScbWithdraw2_Shift2 = (dispatch, timeStart, timeEnd) => {
  var body = {
    start: timeStart,
    end: timeEnd,
    status: configs.scbWithdraw2.id,
  };

  Axios.post('/secure/report/withdraw/status', body).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getSumScbWithdraw2_Shift2', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getSumManualWithdraw_Shift1 = (dispatch, timeStart, timeEnd) => {
  var body = {
    start: timeStart,
    end: timeEnd,
    status: 3,
  };

  Axios.post('/secure/report/withdraw/status', body).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getSumManualWithdraw_Shift1', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getSumWithdraw_Shift1 = (dispatch, timeStart, timeEnd) => {
  var body = {
    start: timeStart,
    end: timeEnd,
  };

  Axios.post('/secure/report/withdraw/sum', body).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getSumWithdraw_Shift1', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getSumWithdraw_Shift2 = (dispatch, timeStart, timeEnd) => {
  var body = {
    start: timeStart,
    end: timeEnd
  };

  Axios.post('/secure/report/withdraw/sum', body).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getSumWithdraw_Shift2', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getSumManualWithdraw_Shift2 = (dispatch, timeStart, timeEnd) => {
  var body = {
    start: timeStart,
    end: timeEnd,
    status: 3,
  };

  Axios.post('/secure/report/withdraw/status', body).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getSumManualWithdraw_Shift2', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getSumChart_Shift1 = (dispatch, timeStart, timeEnd) => {
  var body = { start: timeStart, end: timeEnd };

  Axios.post(`/secure/report/chart/`, body).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getSumChart_Shift1', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const getSumChart_Shift2 = (dispatch, timeStart, timeEnd) => {
  var body = { start: timeStart, end: timeEnd };

  Axios.post(`/secure/report/chart/`, body).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getSumChart_Shift2', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};
