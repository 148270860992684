const withdrawColumnName = [
  {
    label: "#",
    field: "id",
    sort: "asc",
    width: 200,
  },
  {
    label: "วันเวลา",
    field: "createAt",
    sort: "asc",
    width: 100,
  },
  {
    label: "จำนวน",
    field: "amount",
    sort: "asc",
    width: 150,
  },
];
export default withdrawColumnName;
