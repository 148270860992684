import React from "react";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core";
import "react-json-pretty/themes/monikai.css";
import JSONPretty from "react-json-pretty";
import columnName from "./columnName";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.black,
  },
  table: {
    "& .MuiTableSortLabel-root": {
      color: "white",
    },
    "& .MuiTableCell-root": {
      color: "white",
      borderWidth: 1,
      borderColor: theme.palette.background.tableLine,
      borderStyle: "solid",
      padding: 8,
    },
  },
  tableRowColor1: {
    backgroundColor: theme.palette.background.grey1,
  },
  tableRowColor2: {
    backgroundColor: theme.palette.background.grey2,
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const TableHeadCellComponent = ({ name }) => (
  <TableCell align="center">{name}</TableCell>
);

const TableBodyRowComponent = ({ logWithAdminData, classNameStyle }) => (
  <TableRow className={classNameStyle}>
    <TableCell align="center">
      {moment(logWithAdminData?.createdAt).format("DD/MM/YYYY H:mm")}
    </TableCell>
    <TableCell align="center">{logWithAdminData?.action}</TableCell>
    <TableCell align="center">{logWithAdminData?.note}</TableCell>
    <TableCell>
      <JSONPretty
        id="json-pretty"
        style={{ width: 300 }}
        data={logWithAdminData?.before}
      ></JSONPretty>
    </TableCell>
    <TableCell width="30%">
      <JSONPretty
        id="json-pretty"
        style={{ width: 300 }}
        data={logWithAdminData?.after}
      ></JSONPretty>
    </TableCell>
  </TableRow>
);

const LogsWithAdminList = ({
  className,
  logWithAdminDataList,
  pageNum,
  perPage,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box style={{ backgroundColor: "#000" }} minWidth={800}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRowColor1}>
                {columnName.map((column, index) =>
                  column.sort ? (
                    <TableHeadCellComponent key={index} name={column.label} />
                  ) : (
                    <TableHeadCellComponent key={index} name={column.label} />
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {logWithAdminDataList
                .slice((pageNum - 1) * perPage, pageNum * perPage)
                .map((logWithAdminData, index) => (
                  <TableBodyRowComponent
                    classNameStyle={
                      index % 2
                        ? `${classes.tableRowColor1}`
                        : `${classes.tableRowColor2}`
                    }
                    key={index}
                    logWithAdminData={logWithAdminData}
                  />
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

LogsWithAdminList.propTypes = {
  className: PropTypes.string,
  logWithAdminDataList: PropTypes.array,
  pageNum: PropTypes.number,
  perPage: PropTypes.string,
};

export default LogsWithAdminList;
