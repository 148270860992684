import React from "react";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Grid,
  IconButton,
} from "@material-ui/core";
import columnName from "./columnName";
// import { Search as SearchIcon } from "@material-ui/icons";
import EditAdmin from "./EditAdmin";
import ChangePasswordAdmin from "./ChangePasswordAdmin";
import Set2FA from "./Set2FA";
import { useSelector } from "react-redux";
import configs from "../../../configs";
import { useClipboard } from "use-clipboard-copy";
import { ToastContainer, toast } from "react-toastify";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.black,
  },
  table: {
    "& .MuiTableSortLabel-root": {
      color: "white",
    },
    "& .MuiTableCell-root": {
      color: "white",
      borderWidth: 1,
      borderColor: theme.palette.background.tableLine,
      borderStyle: "solid",
      padding: 8,
    },
  },
  tableRowColor1: {
    backgroundColor: theme.palette.background.grey1,
  },
  tableRowColor2: {
    backgroundColor: theme.palette.background.grey2,
  },
  actions: {
    justifyContent: "flex-end",
  },
}));

const TableHeadCellWithSortComponent = ({ name }) => (
  <TableCell align="center">{name}</TableCell>
);

const TableHeadCellComponent = ({ name }) => (
  <TableCell align="center">{name}</TableCell>
);

const TableBodyRowComponent = ({
  adminData,
  currentUser,
  index,
  clipboard,
  classNameStyle,
}) => (
  <TableRow className={classNameStyle}>
    <TableCell align="center">{index}</TableCell>
    <TableCell align="center">{adminData.username}</TableCell>
    <TableCell align="center">{adminData.team}</TableCell>
    <TableCell align="center">{adminData.name}</TableCell>
    <TableCell align="center">
      {adminData.lv === 3 && (
        <IconButton
          aria-label="copy"
          style={{ color: "white" }}
          onClick={() => {
            toast.success("คัดลอกแล้ว", {
              position: "bottom-center",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
            });
            clipboard.copy(
              `${configs.webUrl}/regis/${adminData.team}/${adminData.uuid}`
            );
          }}
        >
          <FileCopyIcon />
        </IconButton>
      )}
    </TableCell>
    <TableCell align="center">
      {moment(adminData.createdAt).format("DD/MM/YYYY H:mm")}
    </TableCell>
    <TableCell align="center">
      {currentUser.wAdmin && (
        <Grid
          spacing={1}
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item md={4} xs={12}>
            <EditAdmin adminData={adminData} />
          </Grid>
          <Grid item md={4} xs={12}>
            <Set2FA adminData={adminData} />
          </Grid>
          <Grid item md={4} xs={12}>
            <ChangePasswordAdmin adminData={adminData} />
          </Grid>
        </Grid>
      )}
    </TableCell>
  </TableRow>
);

const AdminList = ({
  className,
  adminDataList,
  search,
  pageNum,
  perPage,
  ...rest
}) => {
  const classes = useStyles();
  const currentUser = useSelector((state) => state.auth.currentUser);
  const clipboard = useClipboard();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <ToastContainer />
      <PerfectScrollbar>
        <Box style={{ backgroundColor: "#000" }} minWidth={800}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow className={classes.tableRowColor1}>
                {columnName.map((column, index) =>
                  column.sort ? (
                    <TableHeadCellWithSortComponent
                      key={index}
                      name={column.label}
                    />
                  ) : (
                    <TableHeadCellComponent key={index} name={column.label} />
                  )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {adminDataList
                .slice((pageNum - 1) * perPage, pageNum * perPage)
                .map((adminData, index) => (
                  <TableBodyRowComponent
                    classNameStyle={
                      index % 2
                        ? `${classes.tableRowColor1}`
                        : `${classes.tableRowColor2}`
                    }
                    key={index}
                    index={(pageNum - 1) * perPage + index + 1}
                    adminData={adminData}
                    currentUser={currentUser}
                    clipboard={clipboard}
                  />
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

AdminList.propTypes = {
  className: PropTypes.string,
  adminDataList: PropTypes.array,
  search: PropTypes.string,
  pageNum: PropTypes.number,
  perPage: PropTypes.string,
};

export default AdminList;
