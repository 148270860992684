import Axios from '../../utils/axiosConfig';
import moment from 'moment';
import { showErrorSwal, showSuccessSwal } from '../../utils/SwalAlert';

export const getDepositList = (dispatch, start, end) => {
  Axios.get(`/secure/deposit/${start}/${end}`).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getDepositList', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};

export const pullDeposit = (dispatch, id, endData) => {
  var body = { id: id };
  Axios.post('/secure/deposit/pull', body).then((res) => {
    getDepositList(dispatch, 0, endData);
    dispatch({ type: 'onFinish' });
    if (res.data.success) {
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};

export const addManualDeposit = (dispatch, values, endData) => {
  var transactionDate = moment(
    `${values.year}-${values.month}-${values.day} ${values.hour}:${values.minute}`
  ).format();

  var body = {
    amount: values.amount,
    username: values.username,
    transactionDate: transactionDate,
    note: values.note,
    accountNo: values.accountNo,
  };

  Axios.post('/secure/deposit/addmanual', body).then((res) => {
    getDepositList(dispatch, 0, endData);
    dispatch({ type: 'onFinish' });
    if (res.data.success) {
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};

export const addNotFoundDeposit = (dispatch, values, id, endData) => {
  var body = { ...values, id: id };
  Axios.post('/secure/deposit/notfound', body).then((res) => {
    getDepositList(dispatch, 0, endData);
    dispatch({ type: 'onFinish' });
    if (res.data.success) {
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};

export const addFailDeposit = (dispatch, id, endData) => {
  var body = { id: id };
  Axios.patch('/secure/deposit/', body).then((res) => {
    getDepositList(dispatch, 0, endData);
    dispatch({ type: 'onFinish' });
    if (res.data.success) {
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};

export const addTimeoutDeposit = (dispatch, id, endData) => {
  var body = { id: id };
  Axios.patch('/secure/deposit/timeout', body).then((res) => {
    getDepositList(dispatch, 0, endData);
    dispatch({ type: 'onFinish' });
    if (res.data.success) {
      showSuccessSwal(res.data.message);
    } else {
      showErrorSwal(res.data.message);
    }
  });
};

export const getDepositBankList = async (dispatch) => {
  await Axios.get(`/secure/report/bank/deposit`).then((res) => {
    if (res.data.success) {
      dispatch({ type: 'clearError' });
      dispatch({ type: 'getDepositBankList', res });
    } else {
      dispatch({ type: 'onError', res });
    }
  });
};
